<template>
  <div class="card">
    <div class="card-header"></div>
    <div class="card-body">
      <div class="tab-panel fade show active">
        <div class="d-flex justify-content-between mb-3">
          <div class="d-flex">
            <input
              id="searchChallenge"
              class="form-control me-2"
              type="search"
              @keyup="searchTable('searchChallenge', 'admin-table-comunidad')"
              placeholder="Buscar"
              aria-label="Search"
              v-model="searchChallengeText"
            />
          </div>
        </div>
        <div class="col-12 table-responsive">
          <table
            id="admin-table-comunidad"
            class="table table-bordered admin-table"
          >
            <thead>
              <tr class="">
                <th><small>Nombre</small></th>
                <th><small>Acciones</small></th>
              </tr>
            </thead>
            <tbody>
              <tr :key="item" v-for="item in comunidades">
                <ComunidadRow :comunidad="item"></ComunidadRow>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarSettings from "../../components/Layout/NavbarSettings.vue";
import ComunidadRow from "./ComunidadRow.vue";

import ComunidadService from "@/services/comunidad.service.js";

export default {
  name: "Settings",
  components: {
    NavbarSettings: NavbarSettings,
    ComunidadRow: ComunidadRow,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      comunidades: [],
    };
  },
  methods: {
    async getComunidades() {
      this.comunidades = await ComunidadService.getAllComunidades();
      this.comunidades = this.comunidades.data;
      // console.log(this.comunidades)
    },
  },
  created() {
    this.getComunidades();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
