import api from "./api";

class PasswordResetService {
    async resetPasswordfunction(data) {
        return await api
            .post("password/reset/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async resetPasswordValidateToken(data) {
        return await api
            .post("password/reset/validate_token/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async resetPasswordConfirm(data) {
        return await api
            .post("password/reset/confirm/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }
}

export default new PasswordResetService();
