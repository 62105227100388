<template>
  <div>
    <div class="card shadow-sm border-0">
      <div class="card-header bg-white">
        <div class="d-flex justify-content-between align-items-center">
          <h5>Subcategorías</h5>
          <AddSubCategory />
        </div>
      </div>
      <div class="card-body">
        <div v-if="!loading" class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="bg-dark text-white">
              <tr class="">
                <th>Subcategoría</th>
                <th>Categoría</th>
                <th>Temática</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="align-middle"
                :key="item"
                v-for="item in subcategories"
              >
                <SubCategoryRow
                  :subcategory="item"
                  :topics="topics"
                ></SubCategoryRow>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center py-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div>Cargando información</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddSubCategory from "@/components/Blog/elements/SubCategories/AddSubCategory.vue";
import SubCategoryRow from "@/components/Blog/elements/SubCategories/SubCategoryRow.vue";

import SubCategoryService from "@/services/subcategory.service.js";
import TopicService from "@/services/topic.service";

export default {
  name: "SubCategories",
  components: {
    AddSubCategory: AddSubCategory,
    SubCategoryRow: SubCategoryRow,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      subcategories: [],
      topics: [],
      loading: true,
    };
  },
  methods: {
    async start() {
      this.loading = true;
      await this.getTopics();
      await this.getSubCategories();
      this.loading = false;
    },

    async getSubCategories() {
      var response = await SubCategoryService.getAllSubCategories();
      this.subcategories = response.data;
      console.log(this.subcategories, "subcategories");
    },
    async getTopics() {
      var response = await TopicService.getAllTopics();
      this.topics = response.data;
      // console.log(this.topics)
    },
  },
  created() {
    this.start();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
