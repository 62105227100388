<template>
  <div class="container">
    <div class="card shadow-sm border-0 mt-5 mb-3">
      <div class="card-header bg-white">
        <div class="d-flex justify-content-between">
          <h5>{{ course.title }}</h5>
        </div>
      </div>
      <div class="card-body">
        <div v-if="!course.isOnline" class="row" style="margin-top: 45px">
          <div class="col-md-4 d-flex flex-column align-items-center">
            <i class="course-icon fa fa-calendar"></i>
            <p class="course-data text-center">
              <small>{{ course.date }}</small>
            </p>
          </div>
          <div class="col-md-4 d-flex flex-column align-items-center">
            <i class="course-icon fa fa-clock"></i>
            <p class="course-data text-center">
              <small>{{ course.schedule }}</small>
            </p>
          </div>
          <div class="col-md-4 d-flex flex-column align-items-center">
            <i class="fa course-icon fa-map-pin"></i>
            <p class="course-data text-center">
              <small>{{ course.location }}</small>
            </p>
          </div>
        </div>
        <form @submit="update">
          <div class="">
            <div class="form-group mb-3">
              <label class="form-label">Título</label>
              <input
                type="text"
                class="form-control"
                v-model="updateCourse.title"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Subtítulo</label>
              <input
                type="text"
                class="form-control"
                v-model="updateCourse.subtitle"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Sumario</label>
              <QuillEditor
                ref="quillEditor"
                theme="snow"
                v-model:content="updateCourse.summary"
                contentType="html"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Imagen de portada</label>
              <input
                type="file"
                class="form-control"
                @change="uploadCoverImage"
                accept="image/*"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Fecha de lanzamiento</label>
              <input
                type="date"
                class="form-control"
                v-model="updateCourse.releaseDate"
              />
            </div>
            <div class="row">
              <div class="col-md-4 form-group mb-3">
                <label class="form-label">Horas totales</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="updateCourse.totalHours"
                />
              </div>
              <div class="col-md-4 form-group mb-3">
                <label class="form-label">Sumario</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="updateCourse.totalMinutes"
                />
              </div>
              <div class="col-md-4 form-group mb-3">
                <label class="form-label">Vídeos totales</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="updateCourse.totalVideos"
                />
              </div>
            </div>
            <h6 class="mt-4">Profesor</h6>
            <div class="form-group mb-3">
              <label class="form-label">Imagen</label>
              <input
                type="file"
                class="form-control"
                @change="uploadTeacherPicture"
                accept="image/png, image/jpg, image/jpeg"
              />
            </div>
            <div class="row">
              <div class="col-md-6 form-group mb-3">
                <label class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updateCourse.teacherName"
                />
              </div>
              <div class="col-md-6 form-group mb-3">
                <label class="form-label">Apellido</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updateCourse.teacherLastName"
                />
              </div>
              <div class="col-md-6 form-group mb-3">
                <label class="form-label">Descripción</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updateCourse.teacherDescription"
                />
              </div>
              <div class="col-md-6 form-group mb-3">
                <label class="form-label">Rol</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updateCourse.teacherRole"
                />
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="updateCourse.isOnline"
                />
                <span class="form-check-label">Es online</span>
              </label>
            </div>
            <div class="row" v-if="updateCourse.isOnline == false">
              <div class="col-md-4 form-group mb-3">
                <label class="form-label">Horario</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updateCourse.schedule"
                />
              </div>
              <div class="col-md-4 form-group mb-3">
                <label class="form-label">Localización</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updateCourse.location"
                />
              </div>
              <div class="col-md-4 form-group mb-3">
                <label class="form-label">Fecha</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="updateCourse.date"
                />
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="updateCourse.enabled"
                />
                <span class="form-check-label">Activado</span>
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button :id="'submitBtn'" type="submit" class="btn btn-primary">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="course.isOnline" class="card shadow-sm border-0 mb-3">
      <div class="card-header bg-white">
        <div class="d-flex justify-content-between">
          <h5>Lecciones</h5>
          <AddLesson :ccourse="course" />
        </div><!--../../components/Lessons/LessonRow.vue-->
      </div><!--../../components/Courses/inscriptions/InscriptionRow.vue-->
      <div class="card-body"><!--../../components/Courses/DownloadInscriptions.vue-->
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr class="">
                <th>Orden</th>
                <th>Nombre</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr class="" :key="item" v-for="item in course.lessons">
                <LessonRow :lesson="item"></LessonRow>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="!course.isOnline" class="card shadow-sm border-0 mb-3">
      <div class="card-header bg-white">
        <div class="d-flex justify-content-between">
          <h5>Inscripciones</h5>
        </div>
      </div>
      <div class="card-body">
        <div v-if="!loading">
          <div class="d-flex">
            <DownloadInscriptionsCSV :course="course" />
            <DownloadInscriptions :course="course" />
          </div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr class="">
                  <th>Asistentes</th>
                  <th>Persona con discapacidad</th>
                  <th>Fecha de inscripción</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="align-middle"
                  :key="item"
                  v-for="item in inscriptions"
                >
                  <InscriptionRow :inscription="item"></InscriptionRow>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="text-center py-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div>Cargando información</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.course-icon {
  font-size: 25px;
  color: #ff9b04;
  margin-bottom: 15px;
}
.course-data {
  font-size: 18px;
  color: #000;
}
</style>

<script>
import AddLesson from "@/components/Lessons/AddLesson.vue";
import LessonRow from "@/components/Lessons/LessonRow.vue";
import InscriptionRow from "@/components/Courses/inscriptions/InscriptionRow.vue";
import DownloadInscriptions from "@/components/Courses/DownloadInscriptions.vue";
import DownloadInscriptionsCSV from "@/components/Courses/DownloadInscriptionsCSV.vue";

import CourseService from "@/services/course.service.js";
import InscriptionService from "@/services/inscription.service.js";

import Swal from "sweetalert2";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "SingleCourseView",
  components: {
    QuillEditor,
    AddLesson: AddLesson,
    LessonRow: LessonRow,
    InscriptionRow: InscriptionRow,
    DownloadInscriptions: DownloadInscriptions,
    DownloadInscriptionsCSV: DownloadInscriptionsCSV,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    editor() {
      return this.$refs.quillEditor;
    },
  },
  data() {
    return {
      courseUUID: this.$route.params.courseUUID,
      course: [],
      lessons: [],
      updateCourse: {
        title: "",
        subtitle: "",
        summary: "",
        coverImage: null,
        releaseDate: "",
        totalHours: 0,
        totalMinutes: 0,
        totalVideos: 0,
        teacherName: "",
        teacherLastName: "",
        teacherPicture: null,
        teacherDescription: "",
        teacherRole: "",
        isOnline: true,
        schedule: null,
        location: null,
        date: null,
        enabled: true,
      },
      coverImage: "",
      teacherPicture: "",
      inscriptions: [],
      loading: true,
    };
  },
  methods: {
    async start() {
      this.loading = true;
      await this.getCourse();
      await this.getInscriptions();
      this.loading = false;
    },
    uploadCoverImage(event) {
      this.coverImage = event.target.files[0];
    },
    uploadTeacherPicture(event) {
      this.teacherPicture = event.target.files[0];
    },
    async getCourse() {
      var response = await CourseService.getCourse(this.courseUUID);
      this.course = response.data;
      this.updateCourse.title = this.course.title;
      this.updateCourse.subtitle = this.course.subtitle;
      this.editor.setHTML(this.course.summary);

      console.log(this.editor);
      this.updateCourse.coverImage = this.course.coverImage;
      this.updateCourse.releaseDate = this.course.releaseDate;
      this.updateCourse.totalHours = this.course.totalHours;
      this.updateCourse.totalMinutes = this.course.totalMinutes;
      this.updateCourse.totalVideos = this.course.totalVideos;
      this.updateCourse.teacherName = this.course.teacherName;
      this.updateCourse.teacherLastName = this.course.teacherLastName;
      this.updateCourse.teacherPicture = this.course.teacherPicture;
      this.updateCourse.teacherDescription = this.course.teacherDescription;
      this.updateCourse.teacherRole = this.course.teacherRole;
      this.updateCourse.isOnline = this.course.isOnline;
      this.updateCourse.schedule = this.course.schedule;
      this.updateCourse.location = this.course.location;
      this.updateCourse.date = this.course.date;
      this.updateCourse.enabled = this.course.enabled;
    },

    async getInscriptions() {
      var response = await InscriptionService.getCourseInscriptions(
        this.courseUUID
      );
      this.inscriptions = response.data;
      console.log(this.inscriptions, "this.inscriptions");
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    async update(evt) {
      evt.preventDefault();
      window.$("#submitBtn").empty();
      window
        .$("#submitBtn")
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      const fd = new FormData();
      if (this.coverImage != "") {
        fd.append("coverImage", this.coverImage);
      }
      if (this.teacherPicture != "") {
        fd.append("teacherPicture", this.teacherPicture);
      }
      fd.append("title", this.updateCourse.title);
      fd.append("subtitle", this.updateCourse.subtitle);
      fd.append("summary", this.updateCourse.summary);

      this.course.lessons.forEach((lesson) => {
        fd.append("lessons", lesson.UUID);
      });

      fd.append("releaseDate", this.formatDate(this.updateCourse.releaseDate));
      fd.append("totalHours", this.updateCourse.totalHours);
      fd.append("totalMinutes", this.updateCourse.totalMinutes);
      fd.append("totalVideos", this.updateCourse.totalVideos);
      fd.append("teacherName", this.updateCourse.teacherName);
      fd.append("teacherLastName", this.updateCourse.teacherLastName);
      fd.append("teacherDescription", this.updateCourse.teacherDescription);
      fd.append("teacherRole", this.updateCourse.teacherRole);
      console.log(this.updateCourse.isOnline);
      fd.append("isOnline", this.updateCourse.isOnline);
      fd.append("schedule", this.updateCourse.schedule);
      fd.append("date", this.formatDate(this.updateCourse.date));
      fd.append("location", this.updateCourse.location);
      fd.append("enabled", this.updateCourse.enabled);

      evt.preventDefault();

      let response = await CourseService.updateCourse(this.course.UUID, fd);

      window.$("#submitBtn").empty();
      window.$("#submitBtn").append("Guardar");
      if (response.status == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
        this.$router.go();
      }
    },
  },
  created() {
    this.start();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
