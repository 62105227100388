<template>
	<div
		class="h-100 bg-white"
		style="
			min-height: 100vh;
			background: linear-gradient(270deg, #ff9b04, #ff5f00);
			background-image: url(https://fundacionbertinosborne.org/wp-content/uploads/2022/03/FBO-Home-Slider-OK6.jpg);
			background-position: center;
			min-height: 100vh;
		"
	>
		<div class="container">
			<div class="row">
				<div class="col-lg-7 col-md-6"></div>
				<div class="col-lg-5 col-md-6">
					<div
						class="row h-100 align-items-center justify-content-center align-content-center"
						style="min-height: 100vh"
					>
						<div class="card w-100 py-3 bg-white">
							<div class="card-body">
								<div class="mb-5 text-center">
									<h4>Iniciar sesión</h4>
									<p class="text-muted">
										Socios Fundación Bertín Osborne
									</p>
								</div>
								<Form
									@submit="handleLogin"
									:validation-schema="schema"
									class="mb-6"
								>
									<div class="mb-3">
										<label class="form-label"
											>Usuario</label
										>
										<Field
											name="username"
											type="text"
											class="form-control form-control-lg form-control-solid"
										/>
										<div class="mt-2">
											<ErrorMessage
												name="username"
												class="error-feedback text-danger"
											/>
										</div>
									</div>
									<div class="mb-3">
										<label class="form-label"
											>Contraseña</label
										>
										<Field
											name="password"
											type="password"
											class="form-control form-control-lg form-control-solid"
											autocomplete
										/>
										<div class="mt-2">
											<ErrorMessage
												name="password"
												class="error-feedback text-danger mt-2"
											/>
										</div>
									</div>

									<div class="text-end mt-2">
										<router-link
											to="/forgot-password"
											class="text-sm text-muted"
											><small
												>¿Has olvidado tu
												contraseña?</small
											></router-link
										>
									</div>

									<div class="form-group mt-4 text-center">
										<button
											class="btn btn-fbo px-5 py-3"
											:disabled="loading"
										>
											<span
												v-show="loading"
												class="spinner-border spinner-border-sm me-2"
											></span>
											<span>Iniciar sesión</span>
										</button>
									</div>
									<div class="form-group mt-3">
										<div
											v-if="message"
											class="alert alert-danger border-0 shadow-sm"
											role="alert"
										>
											{{ message }}
										</div>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
	name: "Login",
	components: {
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		const schema = yup.object().shape({
			username: yup.string().required("El campo usuario es obligatorio."),
			password: yup
				.string()
				.required("El campo contraseña es obligatorio."),
		});

		return {
			loading: false,
			message: "",
			schema,
		};
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
	},
	created() {
		if (this.loggedIn) {
			this.$router.push("/");
		}
	},
	methods: {
		handleLogin(user) {
			this.loading = true;

			this.$store.dispatch("auth/login", user).then(
				async () => {
					await this.$store.dispatch("user/login");
					this.$router.push("/");
				},
				(error) => {
					this.loading = false;
					//   console.log("STATUS CODE: " + error.data);
					this.message = "El usuario o contraseña son incorrectos.";
				}
			);
		},
	},
};
</script>
