<template>
  <div>
    <div class="container-fluid">
      <div class="card-body p-4">
        <div class="table-responsive w-100 mt-2">
          <h3 style="display: inline; margin-right: 15px">Artículos</h3>
          <AddArticle :tags="tags" :categories="categories" />
          <div class="card-body">
            <table class="table table-bordered settings-table mt-3">
              <thead>
                <tr class="">
                  <th>Título</th>
                  <th>Categoría</th>
                  <th>Temáticas</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" :key="item" v-for="item in articles">
                  <!-- <ArticleRow :article="item" :tags="tags" :categories="categories"></ArticleRow>           -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddArticle from "@/components/Blog/elements/Articles/AddArticle.vue";
import ArticleRow from "@/components/Blog/elements/Articles/ArticleRow.vue";

import ArticleService from "@/services/article.service.js";
import CategoryService from "@/services/category.service";

export default {
  name: "Conversations",
  components: {
    AddArticle: AddArticle,
    ArticleRow: ArticleRow,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      articles: [],
      tags: [],
      categories: [],
    };
  },
  methods: {
    async getArticles() {
      this.articles = await ArticleService.getAllArticles();
      this.articles = this.articles.data;
    },
    async getCategories() {
      this.categories = await CategoryService.getAllCategories();
      this.categories = this.categories.data;
    },
  },
  created() {
    this.getArticles();
    this.getCategories();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
