<template>
	<div
		class="h-100 bg-white"
		style="
			min-height: 100vh;
			background: linear-gradient(270deg, #ff9b04, #ff5f00);
			background-image: url(https://fundacionbertinosborne.org/wp-content/uploads/2022/03/FBO-Home-Slider-OK6.jpg);
			background-position: center;
			min-height: 100vh;
		"
	>
		<div class="container">
			<div
				class="row h-100 align-items-center justify-content-center align-content-center"
				style="min-height: 100vh"
			>
				<div class="card w-100 py-3 col-md-6 bg-white">
					<div class="card-body">
						<div class="mb-5 text-center">
							<h4>¿Olvidaste tu contraseña?</h4>
							<p class="text-muted">
								Socios Fundación Bertín Osborne
							</p>
						</div>
						<form @submit.prevent.stop="handleForgotPassword">
							<div>
								<label class="form-label"
									>Introduce tu email</label
								>
								<input
									type="email"
									class="form-control"
									v-model="email"
								/>
							</div>

							<div class="mt-2">
								<ErrorMessage
									name="email"
									class="error-feedback text-danger"
								/>
							</div>

							<div
								v-if="message"
								class="alert alert-danger"
								role="alert"
							>
								{{ message }}
							</div>

							<div class="form-group mt-4 text-center">
								<button
									class="btn btn-fbo px-5 py-3"
									:disabled="loading"
								>
									<span
										v-show="loading"
										class="spinner-border spinner-border-sm me-2"
									></span>
									<span>Enviar</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ErrorMessage } from "vee-validate";
import ResetPasswordService from "@/services/reset-password.service";

// import Swal from "sweetalert2";

export default {
	name: "ForgotPassword",
	components: {
		ErrorMessage,
	},
	data() {
		return {
			email: "",
			loading: false,
			message: "",
		};
	},
	methods: {
		log: function (e) {
			console.log(e);
			console.log(this.email);
		},
		async handleForgotPassword() {
			console.log("handleForgotPassword called");
			this.loading = true;
			this.message = "";

			var response = await ResetPasswordService.resetPasswordfunction({
				email: this.email,
			});

			if (response.status == 200) {
				this.$router.push("/validate-token");
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error: " + response.data.message,
					confirmButtonColor: "#0091ff",
				});
			}
			this.loading = false;
		},
	},
};
</script>