<template>
  <div class="container py-5 text-black">
    <h1 class="text-uppercase mb-4">Aviso Legal</h1>
    <p>
      Para cumplir con los requisitos de la Ley 34/2002, de 11 de julio, de
      Servicios de la Sociedad de la Información y de Comercio Electrónico y, en
      particular, su artículo 10, se pone a disposición de los usuarios el
      siguiente aviso legal (en adelante, el "Aviso Legal").
    </p>
    <p>
      El acceso, navegación y utilización del sitio web o de la aplicación móvil
      (en adelante, la "Plataforma") implica la aceptación tácita y sin reservas
      de todas las estipulaciones del presente Aviso Legal, teniendo la misma
      validez y eficacia que cualquier contrato celebrado por escrito y firmado.
    </p>
    <p class="mb-4">
      Su observancia y cumplimiento serán exigibles respecto de cualquier
      persona que acceda, navegue o utilice la Plataforma (en adelante, el
      "Usuario" o los "Usuarios"). Si usted no está de acuerdo con las
      condiciones expuestas, no acceda, navegue o utilicela misma.
    </p>
    <ol class="list-group list-group- list-group-flush">
      <li class="list-group-item">
        <h4>IDENTIFICACIÓN DEL RESPONSABLE</h4>
        <ul>
          <li class="m-2">
            Titular: Fundación Bertín Osborne (en adelante, la "Fundación")
          </li>
          <li class="m-2">
            Domicilio social: Carretera de Torreblanca a Mairena del Alcor,
            Hacienda de San José, Alcalá de Guadaira, C.P. 41500 Sevilla
          </li>
          <li class="m-2">N.I.F.: G-91838888</li>
          <li class="m-2">Registro Público: […]</li>
          <li class="m-2">E-mail: info@fundacionbertinosborne.org</li>
          <li class="m-2">Teléfono: […]</li>
        </ul>
      </li>
      <li class="list-group-item">
        <h4>OBJETO</h4>
        <p>
          El presente Aviso Legal regula el acceso, navegación y utilización de
          la Plataforma, por medio del cual informamos a los Usuarios acerca de
          sus derechos y obligaciones en relación con los contenidos mostrados
          enla misma, los logotipos y marcas utilizadas, así como de las
          responsabilidades que pueden derivarse de su uso.
        </p>
        <p>
          El acceso a la Plataforma es gratuito salvo en lo relativo al coste de
          la conexión a través de la red de telecomunicaciones suministrada por
          el proveedor de acceso contratado por los Usuarios.
        </p>
        <p>
          Sin perjuicio de lo anterior, la Fundación se reserva el derecho a
          modificar la presentación, configuración y contenido de la Plataforma,
          así como el presente Aviso Legal por lo que se recomienda a los
          Usuarios revisarlo de forma constante. El acceso y/o utilización de la
          Plataforma tras la entrada en vigor de sus modificaciones o cambios
          suponen la aceptación de la misma.
        </p>
        <p>
          A este respecto, los servicios que presta la Fundación a través de la
          Plataforma consisten en conseguir donantes y socios recurrentes que
          ayuden a financiar los servicios que ofrece la Fundación desde dos (2)
          euros al mes.
        </p>
      </li>
      <li class="list-group-item">
        <h4>DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h4>

        <p>
          La Fundación es titular o, en su caso, cuenta con las licencias
          correspondientes sobre los derechos de propiedad intelectual,
          industrial, de imagen o cualesquiera otros análogos sobre la
          Plataforma, así como de todos los contenidos ofrecidos enla misma,
          incluyendo, a título enunciativo, pero no limitativo, fotografías,
          ilustraciones, logos, marcas, grafismos, diseños, interfaces, o
          cualquier otra información o contenido incluidos enla misma.
        </p>
        <p>
          Los Usuarios únicamente disponen de un derecho de uso no exclusivo,
          dentro de un ámbito estrictamente personal y con la única finalidad de
          poder navegar por la Plataforma de acuerdo con el presente Aviso
          Legal.
        </p>
      </li>
      <li class="list-group-item">
        <h4>REGLAS DE USO DEL SITIO WEB</h4>
        <p>
          No está permitido y, por tanto, sus consecuencias serán
          responsabilidad exclusiva de los Usuarios, el acceso o la utilización
          de la Plataforma con fines ilegales o no autorizados, con o sin
          finalidad económica. En concreto, y sin carácter limitativo a la
          presente lista, queda prohibido:
        </p>
        <ol>
          <li class="m-2">
            Usar la Plataforma en forma alguna que pueda provocar daños,
            interrupciones, ineficiencias o defectos en el funcionamiento de
            éstos o en dispositivos de terceros;
          </li>
          <li class="m-2">
            Usar la Plataforma para la transmisión, instalación o publicación de
            cualquier virus, troyano, gusano, código malicioso u otros programas
            o archivos perjudiciales o con intenciones ilícitas;
          </li>
          <li class="m-2">
            Usar la Plataforma para transmitir material con fines publicitarios
            o de promoción, incluidos spam, correos electrónicos en cadena o
            similares, sin la previa autorización expresa y por escrito de a
            Fundación;
          </li>
          <li class="m-2">
            Usar la Plataforma de manera que constituya una vulneración de
            cualesquiera derechos de la Fundación o de terceros;
          </li>
          <li class="m-2">
            Usar la Plataforma para transmitir o publicar cualquier material de
            carácter difamatorio, ofensivo, racista, vulgar, denigrante,
            pornográfico, o de naturaleza obscena o amenazadora o que pueda
            molestar, perjudicar o meramente afectar a cualquier persona bajo el
            criterio de a Fundación;
          </li>
          <li class="m-2">
            Usar la Plataforma para recoger datos de carácter personal de otros
            Usuarios;
          </li>
          <li class="m-2">
            Usar la Plataforma de forma ilegal, en contra de la buena fe, la
            moral y/o el orden público;
          </li>
          <li class="m-2">
            Acceder sin autorización a cualquier sección de la Plataforma, a
            otros sistemas o redes conectados a la misma, a cualquier servidor
            de la Fundación o a los servicios ofrecidos a través de la
            Plataforma por medio de pirateo o falsificación, extracción de
            contraseñas o cualquier otro medio ilegítimo o establecido en el
            presente Aviso Legal;
          </li>
          <li class="m-2">
            Llevar a cabo alguna acción que provoque una saturación
            desproporcionada o innecesaria en la infraestructura de la
            Plataforma o en los sistemas o redes utilizados o titularidad de a
            Fundación, así como en los sistemas y redes conectados al
            Plataforma.
          </li>
        </ol>
        <p>
          El incumplimiento de cualquiera de las anteriores obligaciones por
          parte de los Usuarios de la Plataforma podrá llevar a la adopción por
          parte de la Fundación de las medidas oportunas amparadas en derecho y
          en el ejercicio de sus legítimos intereses.
        </p>
        <p>
          Toda la información que facilite el Usuario a través de los
          formularios de la Plataforma a los efectos anteriores o cualesquiera
          otros deberá ser veraz. A estos efectos, el Usuario garantiza la
          autenticidad de todos aquellos datos que comunique y mantendrá la
          información facilitada a la Fundación perfectamente actualizada de
          forma que responda, en todo momento, a la situación real del Usuario.
          En todo caso será el Usuario el único responsable de las
          manifestaciones falsas o inexactas que realice y de los perjuicios que
          cause a la Fundación o a terceros por la información que facilite.
        </p>
      </li>
      <li class="list-group-item">
        <h4>RESPONSABILIDADES Y GARANTÍAS</h4>
        <p>
          La Fundación no puede garantizar la fiabilidad, utilidad o veracidad
          de absolutamente toda la información contenida en la Plataforma, ni
          tampoco la utilidad o veracidad de los contenidos puestos a
          disposición de los Usuarios a través de la misma. En consecuencia, la
          Fundación no garantiza ni se hace responsable de:
        </p>
        <ol>
          <li class="m-2">
            La continuidad de los contenidos de la Plataforma;
          </li>
          <li class="m-2">La ausencia de errores en dichos contenidos;</li>
          <li class="m-2">
            La ausencia de virus, troyanos, gusanos y/o demás componentes
            maliciosos o tecnológicamente dañinos en la Plataforma o en el
            servidor que lo suministra;
          </li>
          <li class="m-2">
            La invulnerabilidad de la Plataforma y/o la imposibilidad de
            vulnerar las medidas de seguridad que se adopten enla misma;
          </li>
          <li class="m-2">
            La falta de utilidad o rendimiento de los contenidos de la
            Plataforma;
          </li>
          <li class="m-2">
            Los fallos de la Plataforma ocasionados por cualquier tipo de ataque
            a sus servidores o a los de terceros proveedores de servicios de [a
            Fundación, así como los fallos técnicos o de seguridad del sistema
            de cualquiera de dichos proveedores que impidan el correcto
            funcionamiento de la Plataforma;
          </li>
          <li class="m-2">
            Cualquier fallo técnico, de cualquier tipo, que dificulte, retrase o
            impida el correcto funcionamiento de la Plataforma;
          </li>
          <li class="m-2">
            Los daños o perjuicios que cause, a sí mismo o a un tercero,
            cualquier persona que infringiera las condiciones, normas e
            instrucciones que la Fundación establece en la Plataforma o a través
            de la vulneración de los sistemas de seguridad.
          </li>
        </ol>
        <p>
          La Fundación declara que ha adoptado todas las medidas necesarias,
          dentro de sus posibilidades y del estado de la técnica, para
          garantizar el funcionamiento de la Plataforma y reducir al mínimo los
          errores del sistema, tanto desde el punto de vista técnico como de los
          contenidos publicados en la Plataforma.
        </p>
        <p>
          La Fundación no será responsable de la veracidad, integridad o
          actualización de las informaciones publicadas en la Plataforma
          provenientes de fuentes ajenas ala misma, así como tampoco de las
          contenidas en otras plataformas a las que se enlace desde la
          Plataforma.
        </p>
        <p>
          La Fundación se reserva el derecho a suspender, modificar, restringir
          o interrumpir, ya sea temporal o permanentemente, el acceso,
          navegación, uso, alojamiento y/o descarga del contenido y/o uso de
          servicios de la Plataforma, con o sin previa notificación, a los
          Usuarios que contravengan cualquier de las disposiciones detalladas en
          el presente Aviso Legal, sin que medie la posibilidad de exigir
          indemnización por esta causa.
        </p>
      </li>
      <li class="list-group-item">
        <h4>FUERZA MAYOR</h4>
        <p>
          Sin perjuicio de lo anterior, la Fundación no será responsable de los
          retrasos o fallos que se produjeran en el acceso, funcionamiento y
          operatividad de la Plataforma, sus contenidos y/o servicios, así como
          tampoco de las interrupciones, suspensiones o el mal funcionamiento de
          la misma, cuando tuvieran su origen en averías producidas por
          cualquier situación de fuerza mayor o causa fortuita.
        </p>
        <p>
          La Fundación no será responsable por ningún incumplimiento o retraso
          en la realización de cualquier actuación cuando el mismo se deba a
          acontecimientos que estén fuera de su control razonable ("Causa de
          Fuerza Mayor").
        </p>
        <p>
          Las Causas de Fuerza Mayor incluirán cualquier acto, acontecimiento,
          falta de ejercicio, omisión o accidente que esté fuera del control
          razonable de la Fundación y, entre otros, los siguientes:
        </p>
        <ol>
          <li class="m-2">
            Huelgas, cierres patronales u otras medidas reivindicativas;
          </li>
          <li class="m-2">
            Conmoción civil, revuelta, invasión, amenaza o ataque terrorista,
            guerra (declarada o no) o amenaza o preparativos de guerra;
          </li>
          <li class="m-2">
            Incendio, explosión, tormenta, inundación, terremoto, hundimiento,
            epidemia o cualquier otro desastre natural;
          </li>
          <li class="m-2">
            Imposibilidad de uso de trenes, barcos, aviones, transportes de
            motor u otros medios de transporte, públicos o privados;
          </li>
          <li class="m-2">
            Imposibilidad de utilizar sistemas públicos o privados de
            telecomunicaciones;
          </li>
          <li class="m-2">
            Actos, decretos, legislación, normativa o restricciones de cualquier
            gobierno o autoridad pública.
          </li>
        </ol>
        <p>
          Se entenderá que las mencionadas actuaciones quedarán suspendidas
          durante el periodo en que la Causa de Fuerza Mayor continúe y la
          Fundación dispondrá de una ampliación en el plazo para cumplir conla
          misma por un periodo de tiempo igual al que dure la Causa de Fuerza
          Mayor. La Fundación pondrá todos los medios razonables para que
          finalice la Causa de Fuerza Mayor o para encontrar una solución que
          permita cumplir con sus obligaciones a pesar de la Causa de Fuerza
          Mayor.
        </p>
      </li>
      <li class="list-group-item">
        <h4>ATENCIÓN AL USUARIO</h4>
        <p>
          Los Usuarios tienen a su disposición el servicio de atención al
          Usuario por el que la Fundación atenderá las consultas, quejas y
          sugerencias planteadas por ellos.
        </p>
        <p>
          Los Usuarios podrán acceder al servicio de atención al cliente a
          través del siguiente e-mail de contacto:
          info@fundacionbertinosborne.org
        </p>
        <p>
          La Fundación dará respuesta a las reclamaciones o consultas en el
          plazo más breve posible y, en cualquier caso, en el plazo máximo de un
          (1) mes.
        </p>
      </li>
      <li class="list-group-item">
        <h4>CONFIDENCIALIDAD Y PROTECCIÓN DE DATOS.</h4>
        <p>
          De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 del
          Parlamento Euiropeo y del Consejo de 27 de abril de 2016 relativo a la
          protección de las personas físicas en lo que respecta al tratamiento
          de datos personales y a la libre circulación de estos datos y por el
          que se deroga la Directiva 95/46/CE, así como en la Ley Orgánica
          3/2018, de 5 de diciembre, de Protección de Datos Personales y
          garantía de los derechos digitales, todos los datos de carácter
          personal facilitados durante la utilización de la Plataforma serán
          tratados de conformidad con lo dispuesto en la Política de Privacidad
          que los Usuarios deben leer y, en su caso, aceptar y/o consentir.
        </p>
      </li>
      <li class="list-group-item">
        <h4>CLÁUSULA DE SALVAGUARDA</h4>
        <p>
          Todas las cláusulas o extremos del presente Aviso Legal deben ser
          interpretadas de forma independiente, no viéndose afectadas el resto
          de las cláusulas en caso de que una de ellas haya sido declarada nula
          por sentencia judicial o resolución arbitral firme. Se sustituirá la
          cláusula o cláusulas afectadas por otra u otras que preserven los
          efectos perseguidos por este Aviso Legal.
        </p>
      </li>
      <li class="list-group-item">
        <h4>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h4>
        <p>
          El acceso y utilización de la Plataforma se regirá e interpretará de
          conformidad con la ley común española y, salvo que se establezca otra
          cosa en la normativa aplicable (incluida la normativa de consumidores
          y usuarios), cualquier conflicto se someterá por las partes por los
          jueces y tribunales de Madrid capital.
        </p>
      </li>
    </ol>
  </div>
</template>

<style>
.list-group-item {
  background: transparent !important;
}
</style>

<script>
export default {
  name: "LegalWarning",
};
</script>
