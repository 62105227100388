<template>
  <iframe
    title="titulo"
    sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms"
    :src="'https://api.paycomet.com/gateway/ifr-bankstore?MERCHANT_MERCHANTCODE=wwcdsy2w&OPERATION=9&MERCHANT_TERMINAL=58901&LANGUAGE=ES&MERCHANT_MERCHANTSIGNATURE=f179b2a3892515837a1ac48eca7c35d407412a66dd61365c94d471565e49286f773d36d453f7da18f93fb6fa85e00e6bd77075ebdd6670fbf92b076f8e4f7487&MERCHANT_ORDER=1&MERCHANT_AMOUNT=275&MERCHANT_CURRENCY=EUR&SUBSCRIPTION_STARTDATE=20230501&SUBSCRIPTION_ENDDATE=20250501&SUBSCRIPTION_PERIODICITY=30'"
    width="100%"
    height="525"
    frameborder="0"
    marginheight="0"
    marginwidth="0"
    scrolling="no"
    style="border: 0px solid #000000; padding: 0px; margin: 0px"
  ></iframe>

  <div
    class="container-fluid d-flex"
    style="
      width: 100vw;
      height: 100vh;
      justify-content: center;
      align-items: center;
    "
  >
    <div>
      <button class="btn btn-primary" @click="confirmPayment()">
        Confirmar método de pago
      </button>
    </div>
  </div>
</template>

<script>
import MembershipService from "@/services/membership.service.js";

import Swal from "sweetalert2";

export default {
  name: "ConfirmMemberPaymentView",
  components: {},
  data() {
    return {
      memberUUID: this.$route.params.memberUUID,
      loading: false,
    };
  },
  computed: {},
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
    },
    async confirmPayment() {
      var response = await MembershipService.confirmPayment(this.memberUUID);

      if (response.status == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha añadido correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });

        this.$router.push("/payment/member/success");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ha ocurrido un error: " + response.data.message,
          confirmButtonColor: "#0091ff",
        });

        //this.$router.push('/payment/member/success');
      }
    },
  },
  mounted() {
    //this.logOut();
  },
};
</script>
