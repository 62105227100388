<template>
  <div class="card shadow-sm border-0">
    <div class="card-header bg-white">
      <div class="d-flex justify-content-between align-items-center">
        <h3 style="display: inline; margin-right: 15px">Topics</h3>
        <AddTopic />
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive w-100">
        <table class="table table-bordered settings-table">
          <thead>
            <tr class="">
              <th>Nombre</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr class="" :key="item" v-for="item in topics">
              <TopicRow :topic="item"></TopicRow>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AddTopic from "@/components/Blog/elements/Topics/AddTopic.vue";
import TopicRow from "@/components/Blog/elements/Topics/TopicRow.vue";

import TopicService from "@/services/topic.service.js";

export default {
  name: "topics",
  components: {
    AddTopic: AddTopic,
    TopicRow: TopicRow,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      topics: [],
    };
  },
  methods: {
    async getTopics() {
      this.topics = await TopicService.getAllTopics();
      this.topics = this.topics.data;
    },
  },
  created() {
    this.getTopics();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
