import api from "./api";

class UserService {
    /**************************************
     *  LOCAL STORAGE
     **************************************/

    getLocalUser() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user;
    }

    async updateLocalUser(id) {
        let user = await this.getCurrentUser(id);
        this.removeLocalUser();
        this.setLocalUser(user);
        return user;
    }

    setLocalUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    removeLocalUser() {
        localStorage.removeItem("user");
    }

    async setCurrentLocalUser() {
        let user = await this.getCurrentUser();
        this.setLocalUser(user);
    }

    async getAllUsers() {
        return await api
            .get("/user/list")
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    /**************************************
     *  API FBO
     **************************************/

    async getCurrentUser() {
        return await api
            .get("/user/me")
            .then((response) => {
                if (response.data.UUID) {
                    // Comprobamos si el usuario tiene role=admin
                    if (
                        response.data.role != "superadmin" &&
                        response.data.role != "admin" &&
                        response.data.role != "teamManager" &&
                        response.data.role != "membership"
                    ) {
                        localStorage.clear();
                        throw { data: "Acceso denegado", status: 400 };
                    }
                    // Guardamos la info del usuario en variable local "user"
                    return response.data;
                }
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async updateUser(pk, data) {
        return await api
            .post("/user/update/" + pk + "/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async updatePassword(pk, data) {
        return await api
            .put("/password/update/" + pk + "/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }
}

export default new UserService();
