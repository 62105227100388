import UserService from "../services/user.service";

const userRoles = {
  data() {
    return { user_role: "" };
  },
  methods: {
    async getCurrentUser() {
      var response = await UserService.getCurrentUser();
      this.user_role = response.role;
      // console.log( this.user_role, "user_role")
    },
  },
  created() {
    this.getCurrentUser();
  },
};

export default userRoles;
