<template>
  <button
    class="btn btn-dark"
    data-bs-toggle="modal"
    data-bs-target="#modalAddSubCategory"
  >
    <i class="fas fa-plus"></i> Añadir
  </button>

  <!-- Modal Add Category -->
  <div
    class="modal fade"
    id="modalAddSubCategory"
    tabindex="-1"
    aria-labelledby="modalAddSubCategory"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down">
      <div class="modal-content">
        <form @submit="add">
          <div v-if="!loading">
            <div class="modal-header">
              <h5 class="modal-title">Añadir subcategoría</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              <div class="form-group mb-3">
                <label class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addSubCategory.name"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Temática</label>
                <select
                  class="form-select form-control"
                  v-model="addSubCategory.topic"
                  @change="getCategories()"
                >
                  <option :key="t" v-for="t in topics" :value="t.UUID">
                    {{ t.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Categoría</label>
                <select
                  class="form-select form-control"
                  v-model="addSubCategory.category"
                >
                  <option :key="t" v-for="t in categories" :value="t.UUID">
                    {{ t.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
              <button
                id="submitAddSubCategory"
                type="submit"
                class="btn btn-primary"
              >
                Guardar
              </button>
            </div>
          </div>
          <div v-else class="text-center py-5">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div>Cargando información</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryService from "@/services/category.service";
import SubCategoryService from "@/services/subcategory.service";
import TopicService from "@/services/topic.service";

import Swal from "sweetalert2";

export default {
  name: "AddCategory",
  data() {
    return {
      addSubCategory: {
        name: "",
        topic: "",
        category: "",
      },
      topics: [],
      categories: [],
      loading: true,
    };
  },
  methods: {
    async getTopics() {
      this.topics = await TopicService.getAllTopics();
      this.topics = this.topics.data;
    },

    async getCategories() {
      this.loading = true;
      this.categories = [];
      if (
        this.addSubCategory.topic != null &&
        this.addSubCategory.topic != ""
      ) {
        var response = await CategoryService.getAllCategories({
          topic: this.addSubCategory.topic,
        });
        this.categories = response.data;
      }
      this.loading = false;
      // console.log(this.categories, "Categories")
    },
    async add(evt) {
      evt.preventDefault();
      window.$("#submitAddSubCategory").empty();
      window
        .$("#submitAddSubCategory")
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      let response = await SubCategoryService.addSubCategory({
        name: this.addSubCategory.name,
        topic: this.addSubCategory.topic,
        category: this.addSubCategory.category,
      });

      window.$("#submitAddSubCategory").empty();
      window.$("#submitAddSubCategory").append("Guardar");

      if (response.status == 201) {
        this.name = null;
        // window.$('#modalAddCategory').modal('hide');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });

        this.$router.go();
      }
    },
  },
  created() {
    this.getTopics();
    this.getCategories();
  },
};
</script>
