<template>
	<div
		class="h-100 bg-white"
		style="
			min-height: 100vh;
			background: linear-gradient(270deg, #ff9b04, #ff5f00);
			background-image: url(https://fundacionbertinosborne.org/wp-content/uploads/2022/03/FBO-Home-Slider-OK6.jpg);
			background-position: center;
			min-height: 100vh;
		"
	>
		<div class="container">
			<div
				class="row h-100 align-items-center justify-content-center align-content-center"
				style="min-height: 100vh"
			>
				<div class="card w-100 py-3 col-md-6 bg-white">
					<div class="card-body">
						<div class="mb-5 text-center">
							<h4>
								Introduce el token que te ha llegado al correo
								electrónico
							</h4>
							<p class="text-muted">
								Socios Fundación Bertín Osborne
							</p>
						</div>
						<form @submit.prevent.stop="handleValidateToken">
							<div>
								<label class="form-label">Token</label>
								<input
									type="text"
									class="form-control"
									v-model="token"
								/>
							</div>

							<div class="form-group mt-4 text-center">
								<button
									class="btn btn-fbo px-5 py-3"
									:disabled="loading"
								>
									<span
										v-show="loading"
										class="spinner-border spinner-border-sm me-2"
									></span>
									<span>Verificar</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ResetPasswordService from "@/services/reset-password.service";

export default {
	name: "ValidateToken",
	data() {
		return {
			token: "",
		};
	},
	methods: {
		async handleValidateToken() {
			var response =
				await ResetPasswordService.resetPasswordValidateToken({
					token: this.token,
				});

			if (response.status == 200) {
				this.$router.push({
					path: "/reset-password",
					query: { token: this.token },
				});
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error: " + response.data.message,
					confirmButtonColor: "#0091ff",
				});
			}
		},
	},
};
</script>