<template>
  <td>
    <div>{{ article.title }}</div>
  </td>
  <td>
    <div v-if="article.topic != null">{{ article.topic.name }}</div>
  </td>
  <td>
    <div v-if="article.category != null">{{ article.category.name }}</div>
  </td>
  <td>
    <div v-if="article.subcategory != null">{{ article.subcategory.name }}</div>
  </td>
  <td>
    <div v-if="article.enabled">
      <span class="badge badge-success">Activado</span>
    </div>
    <div v-else><span class="badge badge-danger">Desactivado</span></div>
  </td>
  <td>
    {{ formatDate(article.createdAt) }}
  </td>
  <td class="text-center">
    <button
      class="btn btn-outline-dark m-1"
      data-bs-toggle="modal"
      :data-bs-target="'#modalEditArticle' + id"
    >
      <i class="fas fa-edit"></i></button
    ><br />
    <button class="btn btn-outline-danger m-1" @click="deleteArticle()">
      <i class="fas fa-trash"></i>
    </button>
  </td>

  <!-- Modal Edit Article -->
  <div
    class="modal fade"
    :id="'modalEditArticle' + id"
    tabindex="-1"
    aria-labelledby="modalEditArticle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-lg-down"
    >
      <div class="modal-content">
        <form @submit="update">
          <div class="modal-header">
            <h5 class="modal-title">Editar artículo</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div v-if="!loading">
              <div class="form-group mb-3">
                <label class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updateArticle.title"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Contenido</label>
                <QuillEditor
                  theme="snow"
                  v-model:content="updateArticle.content"
                  contentType="html"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Imagen de portada</label>
                <img :src="article.coverImage" />
                <input
                  type="file"
                  class="form-control"
                  @change="uploadCoverImage"
                  accept="image/*"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Temática</label>
                <select
                  class="form-select form-control"
                  v-model="updateArticle.topic"
                  @change="getCategories()"
                >
                  <option :key="c" v-for="c in topics" :value="c.UUID">
                    {{ c.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Categoría</label>
                <select
                  class="form-select form-control"
                  v-model="updateArticle.category"
                  @change="getSubCategories()"
                >
                  <option :key="c" v-for="c in categories" :value="c.UUID">
                    {{ c.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Subir documento</label>
                <div>
                  <a :href="article.file">{{ article.file }}</a>
                </div>
                <input type="file" class="form-control" @change="uploadFile" />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Subcategoría</label>
                <select
                  class="form-select form-control"
                  v-model="updateArticle.subcategory"
                >
                  <option :key="c" v-for="c in subcategories" :value="c.UUID">
                    {{ c.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Enlace de video</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updateArticle.videoURL"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Etiquetas</label>
                <div class="pills-checks d-flex flex-wrap">
                  <div :key="t" v-for="t in tags">
                    <label
                      v-if="articleTags.includes(t.UUID)"
                      class="form-check pills-element checked"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="articleTags"
                        :value="t.UUID"
                      />
                      <span class="form-check-label">{{ t.name }}</span>
                    </label>
                    <label v-else class="form-check pills-element">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="articleTags"
                        :value="t.UUID"
                      />
                      <span class="form-check-label">{{ t.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="updateArticle.enabled"
                  />
                  <span class="form-check-label">Activado</span>
                </label>
              </div>
            </div>
            <div v-else class="text-center py-5">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div>Cargando información</div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              :id="'submitBtn' + id"
              type="submit"
              class="btn btn-primary"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleService from "@/services/article.service";
import CategoryService from "@/services/category.service";
import SubCategoryService from "@/services/subcategory.service";

import Swal from "sweetalert2";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "ArticleRow",
  components: {
    QuillEditor,
  },
  props: {
    article: Object,
    tags: Object,
    topics: Array,
  },
  data() {
    return {
      id: this.article.UUID,
      updateArticle: {
        title: this.article.title,
        content: this.article.content,
        topic: "",
        tags: this.article.tags,
        category: "",
        subcategory: "",
        videoURL: this.article.videoURL,
        enabled: this.article.enabled,
      },
      articleTags: [],
      coverImage: "",
      file: "",
      categories: [],
      subcategories: [],
      loading: true,
    };
  },
  methods: {
    async start() {
      this.loading = true;
      if (this.article.topic != null) {
        this.updateArticle.topic = this.article.topic.UUID;
      }

      if (this.article.category != null) {
        this.updateArticle.category = this.article.category.UUID;
      }

      if (this.article.subcategory != null) {
        this.updateArticle.subcategory = this.article.subcategory.UUID;
      }

      await this.getTags();
      await this.getCategories();
      await this.getSubCategories();

      this.loading = false;
    },

    uploadCoverImage(event) {
      this.coverImage = event.target.files[0];
    },
    uploadFile(event) {
      this.file = event.target.files[0];
    },
    async getTags() {
      for (var i = 0; i < this.article.tags.length; i++) {
        this.articleTags.push(this.article.tags[i].UUID);
      }
    },

    async getCategories() {
      this.categories = [];
      if (this.updateArticle.topic != null && this.updateArticle.topic != "") {
        var response = await CategoryService.getAllCategories({
          topic: this.updateArticle.topic,
        });
        this.categories = response.data;
      }

      // console.log(this.categories, "Categories")
    },

    async getSubCategories() {
      this.subcategories = [];
      if (
        this.updateArticle.topic != null &&
        this.updateArticle.topic != "" &&
        this.updateArticle.category != null &&
        this.updateArticle.category != ""
      ) {
        var response = await SubCategoryService.getAllSubCategories({
          topic: this.updateArticle.topic,
          category: this.updateArticle.category,
        });
        this.subcategories = response.data;
      }
    },

    async update(evt) {
      // console.log(this.articleTags)
      evt.preventDefault();
      window.$("#submitBtn").empty();
      window
        .$("#submitBtn")
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      const fd = new FormData();
      if (this.coverImage != "") {
        fd.append("coverImage", this.coverImage);
      }
      if (this.file != "") {
        fd.append("file", this.file);
      }
      console.log(this.updateArticle.videoURL);
      if (this.updateArticle.videoURL != null) {
        fd.append("videoURL", this.updateArticle.videoURL);
      }
      fd.append("title", this.updateArticle.title);
      fd.append("content", this.updateArticle.content);

      this.articleTags.forEach((tag) => {
        fd.append("tags", tag);
      });

      fd.append("topic", this.updateArticle.topic);
      fd.append("category", this.updateArticle.category);
      fd.append("subcategory", this.updateArticle.subcategory);
      fd.append("enabled", this.updateArticle.enabled);

      evt.preventDefault();

      let response = await ArticleService.updateArticle(this.article.UUID, fd);

      window.$("#submitBtn").empty();
      window.$("#submitBtn").append("Guardar");
      if (response.status == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
        this.$router.go();
      }
    },
    async deleteArticle() {
      let response = await ArticleService.deleteArticle(this.article.UUID);
      if (response.status == 200 || response.status == 204) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha borrado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
      }
      this.$router.go();
    },

    formatDate(date) {
      // Create a date object from a date string
      var date = new Date(date);

      // Get year, month, and day part from the date
      var year = date.toLocaleString("default", { year: "numeric" });
      var month = date.toLocaleString("default", { month: "2-digit" });
      var day = date.toLocaleString("default", { day: "2-digit" });
      var hour = date.toLocaleString("default", { hour: "2-digit" });
      var minute = date.toLocaleString("default", { minute: "2-digit" });
      // Generate yyyy-mm-dd date string
      var formattedDate =
        day + "/" + month + "/" + year + " - " + hour + ":" + minute;

      return formattedDate;
    },
  },
  created() {
    this.start();
  },
};
</script>
