<template>
  <div>
    <div class="card" id="admin-menu">
      <div class="card-header">
        <div class="card-title mb-0">Menú</div>
      </div>
      <div class="card-body">
        <div class="col-12 item-link-group">
          <button
            class="btn-link-menu nav-link"
            @click="$emit('selectOption', 'comunidades')"
          >
            <i class="fa fa-flag"></i>
            <div class="d-inline" style="margin-left: 10px">Comunidades</div>
          </button>
          <!-- <router-link id="menu-admin-communities" class="nav-link" to="/settings/comunidades">
                        <div><i class="fas fa-flag"></i><span>Comunidades</span></div>
                    </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarSettings",
  emits: ["selectOption"],
  methods: {
    submit() {
      this.$emit("selectOption");
    },
  },
};
</script>
