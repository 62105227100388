<template>
  <td>
    <div>{{ lesson.priority }}</div>
  </td>
  <td>
    <div>{{ lesson.title }}</div>
  </td>
  <td class="center">
    <button
      class="btn btn-sm btn-outline-dark m-1"
      data-bs-toggle="modal"
      :data-bs-target="'#modalEditLesson' + id"
    >
      <i class="fas fa-edit"></i>
    </button>
    <button class="btn btn-sm btn-outline-danger m-1" @click="deleteLesson()">
      <i class="fas fa-trash"></i>
    </button>
  </td>

  <!-- Modal Edit Lesson -->
  <div
    class="modal fade"
    :id="'modalEditLesson' + id"
    tabindex="-1"
    aria-labelledby="modalEditLesson"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-lg-down"
    >
      <div class="modal-content">
        <form @submit="update">
          <div class="modal-header">
            <h5 class="modal-title">Editar lección</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="form-group mb-3">
              <label class="form-label">Título</label>
              <input
                type="text"
                class="form-control"
                v-model="updateLesson.title"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Subtítulo</label>
              <input
                type="text"
                class="form-control"
                v-model="updateLesson.subtitle"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Descripción</label>
              <QuillEditor
                theme="snow"
                v-model:content="updateLesson.description"
                contentType="html"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Archivo</label>
              <input type="file" class="form-control" @change="uploadFile" />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Enlace de video</label>
              <input
                type="text"
                class="form-control"
                v-model="updateLesson.videoURL"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Prioridad</label>
              <input
                type="number"
                class="form-control"
                v-model="updateLesson.priority"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="updateLesson.enabled"
                />
                <span class="form-check-label">Activado</span>
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              :id="'submitBtn' + id"
              type="submit"
              class="btn btn-primary"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LessonService from "../../services/lesson.service";

import Swal from "sweetalert2";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "LessonRow",
  components: {
    QuillEditor,
  },
  props: {
    lesson: Object,
  },
  data() {
    return {
      id: this.lesson.UUID,
      updateLesson: {
        title: this.lesson.title,
        subtitle: this.lesson.subtitle,
        description: this.lesson.description,
        videoURL: this.lesson.videoURL,
        priority: this.lesson.priority,
        enabled: this.lesson.enabled,
      },
      file: "",
    };
  },
  methods: {
    uploadFile(event) {
      this.file = event.target.files[0];
    },

    async update(evt) {
      evt.preventDefault();
      window.$("#submitBtn").empty();
      window
        .$("#submitBtn")
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      const fd = new FormData();

      if (this.file != "") {
        fd.append("file", this.file);
      }
   
      fd.append("title", this.updateLesson.title);
      fd.append("subtitle", this.updateLesson.subtitle);
      fd.append("description", this.updateLesson.description);
      fd.append("videoURL", this.updateLesson.videoURL);
      fd.append("priority", this.updateLesson.priority);
      fd.append("enabled", this.updateLesson.enabled);
      fd.append("course", this.lesson.course);

      evt.preventDefault();

      let response = await LessonService.updateLesson(this.lesson.UUID, fd);

      console.log(this.updateLesson.file)
      
      window.$("#submitBtn").empty();
      window.$("#submitBtn").append("Guardar");
      if (response.status == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
        //this.$router.go();
      }
    },
    async deleteLesson() {
      let response = await LessonService.deleteLesson(this.lesson.UUID);
      if (response.status == 200 || response.status == 204) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha borrado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
      }
      this.$router.go();
    },
  },
};
</script>
