import api from "./api";

class InscriptionService {
  /**************************************
   *  API FBO
   **************************************/

  async getCourseInscriptions(id) {
    return await api
      .get("/courses/inscriptions/list/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getCourseInscriptionsPDF(id) {
    return api
      .get("/courses/inscriptions/pdf/" + id + "/", {
        responseType: "blob",
      })
      .then((response) => {
        var date = new Date();
        var filename =
          "inscripciones_curso_" +
          date.getFullYear() +
          date.getMonth() +
          date.getDate() +
          "_" +
          date.getHours() +
          date.getMinutes() +
          date.getSeconds();
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename + ".pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        return { status: response.status, data: link.href };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getCourseInscriptionsCSV(id) {
    return api
      .get("/courses/inscriptions/csv/" + id + "/", {
        responseType: "blob",
      })
      .then((response) => {
        var date = new Date();
        var filename =
          "inscripciones_curso_" +
          date.getFullYear() +
          date.getMonth() +
          date.getDate() +
          "_" +
          date.getHours() +
          date.getMinutes() +
          date.getSeconds();
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename + ".csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
        return { status: response.status, data: link.href };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async deleteInscription(id) {
    return await api
      .delete("/courses/inscriptions/delete/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }
}

export default new InscriptionService();
