import api from "./api";

class RegionService {
  async getAllRegions() {
    return await api
      .get("/regions/list")
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }
}

export default new RegionService();
