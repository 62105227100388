<template>
	<div class="container-fluid pt-3 pt-md-5">
		<div class="row">
			<div class="col-lg-3 col-md-4">
				<NavbarMembership />
			</div>
			<div class="col-lg-9 col-md-8">
				<div v-if="!loading">
					<!-- Page Heading -->
					<div
						class="d-sm-flex align-items-center justify-content-between mb-4"
					>
						<h1 class="h3 mb-0 text-gray-800 mt-3 mt-md-0">
							Equipo: {{ team.name }}
						</h1>
						<div>
							<button
								v-if="user_role == 'admin'"
								class="d-none d-sm-inline-block btn btn-fbo shadow-sm me-2"
								data-bs-toggle="modal"
								data-bs-target="#modalAddTeamManager"
							>
								<i class="fa-regular fa-plus"></i> Añadir Jefe
								de equipo
							</button>
							<button
								class="d-sm-inline-block btn btn-fbo shadow-sm mt-3 mt-md-0"
								data-bs-toggle="modal"
								data-bs-target="#modalAddMember"
							>
								<i class="fa-regular fa-plus"></i> Añadir socio
							</button>
						</div>
					</div>

					<!-- Content Row -->
					<div class="row">
						<!-- Earnings (Monthly) Card Example -->
						<div class="col-xl-4 col-md-6 mb-4">
							<div
								class="card border-left-primary shadow h-100 py-2"
							>
								<div class="card-body">
									<div
										class="row no-gutters align-items-center"
									>
										<div class="col mr-2">
											<div
												class="text-xs font-weight-bold text-primary text-uppercase mb-1"
											>
												Donaciones (Mes Actual)
											</div>
											<div
												class="h5 mb-0 font-weight-bold text-gray-800"
											>
												{{
													formatCurrency(
														team.amount_current_month /
															100
													)
												}}
											</div>
										</div>
										<div class="col-auto">
											<i
												class="fas fa-euro-sign fa-2x text-gray-300"
											></i>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- Earnings (Monthly) Card Example -->
						<div class="col-xl-4 col-md-6 mb-4">
							<div
								class="card border-left-success shadow h-100 py-2"
							>
								<div class="card-body">
									<div
										class="row no-gutters align-items-center"
									>
										<div class="col mr-2">
											<div
												class="text-xs font-weight-bold text-success text-uppercase mb-1"
											>
												Donaciones (Año actual)
											</div>
											<div
												class="h5 mb-0 font-weight-bold text-gray-800"
											>
												{{
													formatCurrency(
														team.amount_current_year /
															100
													)
												}}
											</div>
										</div>
										<div class="col-auto">
											<i
												class="fas fa-euro-sign fa-2x text-gray-300"
											></i>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- Pending Requests Card Example -->
						<div class="col-xl-4 col-md-6 mb-4">
							<div
								class="card border-left-warning shadow h-100 py-2"
							>
								<div class="card-body">
									<div
										class="row no-gutters align-items-center"
									>
										<div class="col mr-2">
											<div
												class="text-xs font-weight-bold text-warning text-uppercase mb-1"
											>
												Número de socios
											</div>
											<div
												class="h5 mb-0 font-weight-bold text-gray-800"
											>
												{{ team.num_members }}
											</div>
										</div>
										<div class="col-auto">
											<i
												class="fas fa-users fa-2x text-gray-300"
											></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Content Row -->
					<div class="row">
						<!-- Content Column -->
						<div class="col-lg-12 mb-4">
							<!-- Project Card Example -->
							<div class="card mb-4">
								<div class="card-body">
									<h6 class="font-weight-bold">
										Jefes de equipo
									</h6>
									<div class="table-responsive">
										<table class="table table-hovered mb-0">
											<thead>
												<tr>
													<td
														class="fw-bold text-dark"
													>
														<small> Nombre</small>
													</td>
													<td
														class="fw-bold text-dark"
													>
														<small> Usuario</small>
													</td>
													<td
														class="fw-bold text-dark"
													>
														<small>
															Nº de socios</small
														>
													</td>
													<td
														class="fw-bold text-dark"
													>
														<small>
															Estado
															suscripcón</small
														>
													</td>
												</tr>
											</thead>
											<tbody>
												<tr
													:key="item"
													v-for="item in managers"
												>
													<td>
														{{
															item.first_name +
															" " +
															item.last_name
														}}
													</td>
													<td>{{ item.username }}</td>
													<td class="text-center">
														{{ item.num_members }}
													</td>
													<td>
														<span
															v-if="
																item.subscription_status
															"
															class="badge badge-light-success"
															>Activo</span
														>
														<span
															v-else
															class="badge badge-light-danger"
															>Inactivo</span
														>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Modal Añadir Manager-->
					<div
						class="modal fade"
						id="modalAddTeamManager"
						tabindex="-1"
						aria-labelledby="exampleModalLabel"
						aria-hidden="true"
					>
						<div class="modal-dialog modal-lg">
							<div class="modal-content">
								<div class="modal-header">
									<h5
										class="modal-title"
										id="exampleModalLabel"
									>
										Añadir nuevo jefe de equipo
									</h5>
									<button
										type="button"
										class="btn-close"
										data-bs-dismiss="modal"
										aria-label="Close"
									></button>
								</div>
								<div class="modal-body">
									<div class="row mb-3">
										<div class="col-md-6">
											<label for="" class="form-label"
												>Nombre</label
											>
											<input
												type="text"
												class="form-control"
												v-model="newManager.first_name"
											/>
										</div>
										<div class="col-md-6">
											<label for="" class="form-label"
												>Apellidos</label
											>
											<input
												type="text"
												class="form-control"
												v-model="newManager.last_name"
											/>
										</div>
									</div>
									<div class="row mb-3">
										<div class="col-md-6">
											<label for="" class="form-label"
												>Email</label
											>
											<input
												type="email"
												class="form-control"
												v-model="newManager.email"
											/>
										</div>
										<div class="col-md-6">
											<label for="" class="form-label"
												>Teléfono</label
											>
											<input
												type="text"
												class="form-control"
												v-model="newManager.telephone"
											/>
										</div>
									</div>
									<div class="row mb-3">
										<div class="col-md-6">
											<label for="" class="form-label"
												>Comunidad autónoma</label
											>
											<select
												required
												name="provincia"
												class="form-control"
												v-model="newManager.comunidad"
											>
												<option
													:key="item"
													v-for="item in comunidades"
													:value="item.UUID"
												>
													{{ item.name }}
												</option>
											</select>
										</div>
									</div>
								</div>
								<div class="modal-footer">
									<button
										type="button"
										class="btn btn-link"
										data-bs-dismiss="modal"
									>
										Cerrar
									</button>
									<button
										type="button"
										class="btn btn-primary"
										@click.stop.prevent="addManager()"
									>
										Anadir
									</button>
								</div>
							</div>
						</div>
					</div>

					<!-- Modal Añadir Member-->
					<div
						class="modal fade"
						id="modalAddMember"
						tabindex="-1"
						aria-labelledby="exampleModalLabel"
						aria-hidden="true"
					>
						<div class="modal-dialog modal-lg">
							<div class="modal-content">
								<div class="modal-header border-0">
									<button
										type="button"
										class="btn-close"
										data-bs-dismiss="modal"
										aria-label="Close"
									></button>
								</div>
								<div
									class="modal-body px-md-5 pb-md-5 p-2 pt-0"
								>
									<div class="text-center">
										<h3 class="mb-5">Invitar socios</h3>
									</div>

									<div
										v-for="item in user.referrals"
										:key="item"
									>
										<div v-if="item.team_UUID == team.UUID">
											<h6 class="text-gray-800">
												Comparte tu
												<i>referral link</i> con tus
												amigos
											</h6>
											<ClipBoard
												:text="
													'https://socios.fbofamily.app/member/add/referral/' +
													item.referral
												"
												class="mb-5"
											/>
											<div class="text-center">
												<a
													:href="
														'https://socios.fbofamily.app/member/add/referral/' +
														item.referral
													"
													target="_blank"
													class="btn btn-fbo"
												>
													Añadir socio manualmente
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="text-center py-5">
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
					<div>Cargando información</div>
				</div>
			</div>
		</div>
		<!-- HTML -->
		<div id="chartdiv"></div>
	</div>
</template>

<style>
#chartdiv {
	width: 100%;
	height: 550px;
}
</style>


<script>
// import mixin
import userRoles from "@/mixins/user-roles.js";
import ClipBoard from "@/components/Global/ClipBoard.vue";

import NavbarMembership from "@/components/Layout/NavbarMembership.vue";

import MembershipService from "@/services/membership.service.js";
import ComunidadService from "@/services/comunidad.service.js";
import RegionService from "@/services/region.service.js";
import CountryService from "@/services/country.service.js";

import Swal from "sweetalert2";

export default {
	name: "SingleTeamView",
	mixins: [userRoles],
	components: {
		NavbarMembership,
		ClipBoard,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	data() {
		return {
			teamUUID: this.$route.params.teamUUID,
			user: this.$store.state.user.data,
			loading: true,
			team: null,
			comunidades: null,
			regions: null,
			countries: null,
			managers: null,
			commercials: null,
			members: null,
			newManager: {},
			newCommercial: {},
			newMember: {
				country: 66,
				subscription: "monthly",
				quantity: 2.75,
			},
			maxLevels: 3,
			maxNodes: 3,
			maxValue: 100,
			tree: null,
			tree_data: {
				name: null,
				children: [],
			},
		};
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getSingleTeam();
			await this.getComunidades();
			await this.getCountries();
			await this.getRegions();
			await this.getTeamManagers();
			this.loading = false;

			// Create root element
			// https://www.amcharts.com/docs/v5/getting-started/#Root_element
			var root = am5.Root.new("chartdiv");

			// Set themes
			// https://www.amcharts.com/docs/v5/concepts/themes/
			root.setThemes([am5themes_Animated.new(root)]);

			// Create wrapper container
			var container = root.container.children.push(
				am5.Container.new(root, {
					width: am5.percent(100),
					height: am5.percent(100),
					layout: root.verticalLayout,
				})
			);

			// Create series
			// https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
			var series = container.children.push(
				am5hierarchy.Tree.new(root, {
					singleBranchOnly: false,
					downDepth: 1,
					initialDepth: 10,
					valueField: "value",
					categoryField: "name",
					childDataField: "children",
				})
			);

			// Generate and set data
			// https://www.amcharts.com/docs/v5/charts/hierarchy/#Setting_data

			this.tree_data.name = this.team.name;
			this.tree_data.value = this.tree[this.team.name]["children"].length;
			this.tree_data.children = this.generateLevel(
				this.tree[this.team.name]["children"]
			);

			series.data.setAll([this.tree_data]);
			series.set("selectedDataItem", series.dataItems[0]);

			// Make stuff animate on load
			series.appear(1000, 100);
		},

		generateLevel(data) {
			var list_children = [];

			for (var i = 0; i < data.length; i++) {
				var node = data[i];
				var nodeName;
				if (typeof node === "object") {
					nodeName = Object.keys(node)[0];
				} else {
					nodeName = node;
				}

				var child;

				child = {
					name: nodeName,
				};

				child.children = [];
				child.value = 0;
				if (typeof node === "object") {
					console.log(node, "node");

					child.value = node[nodeName]["children"].length;
					child.children = this.generateLevel(
						node[nodeName]["children"]
					);
				}

				list_children.push(child);
				console.log("child", child);
			}

			return list_children;
		},

		async getSingleTeam() {
			var response = await MembershipService.getSingleTeam(this.teamUUID);
			this.team = response.data;
			console.log(this.team, "Team");
		},

		async getComunidades() {
			var response = await ComunidadService.getAllComunidades();
			this.comunidades = response.data;
			// console.log(this.comunidades, "Comunidades")
		},

		async getCountries() {
			var response = await CountryService.getAllCountries();
			this.countries = response.data;
			// console.log(this.countries, "Countries")
		},

		async getRegions() {
			var response = await RegionService.getAllRegions();
			this.regions = response.data;
			// console.log(this.regions, "Regions")
		},

		async addManager() {
			this.newManager.team = this.teamUUID;
			var response = await MembershipService.addManager(this.newManager);

			if (response.status == 201) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: "Se ha añadido correctamente.",
					showConfirmButton: false,
					timer: 1500,
					backdrop: false,
					toast: true,
				});

				this.$router.go();
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error: " + response.data.message,
					confirmButtonColor: "#0091ff",
				});
			}
		},

		async addMember() {
			this.newMember.team = this.teamUUID;
			var response = await MembershipService.addMember(this.newMember);

			if (response.status == 201) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: "Se ha añadido correctamente.",
					showConfirmButton: false,
					timer: 1500,
					backdrop: false,
					toast: true,
				});

				this.$router.go();
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error: " + response.data.message,
					confirmButtonColor: "#0091ff",
				});
			}
		},

		async getTeamManagers() {
			var response = await MembershipService.getTeamManagers(
				this.teamUUID
			);
			this.managers = response.data.managers;
			this.tree = response.data.tree;
			console.log(this.managers, "Managers");
			console.log(this.tree, "Tree");
		},

		formatCurrency(num) {
			// Create our number formatter.
			const formatter = new Intl.NumberFormat("es-ES", {
				style: "currency",
				currency: "EUR",

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});
			return formatter.format(num);
		},
	},
	created() {
		this.start();
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>


