import api from "./api";

class SubCategoryService {
  /**************************************
   *  API FBO
   **************************************/
  async addSubCategory(params) {
    return await api
      .post("/blog/subcategories/create/", params)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async updateSubCategory(id, params) {
    return await api
      .put("/blog/subcategories/update/" + id + "/", params)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getAllSubCategories(params) {
    return await api
      .get("/blog/subcategories/list", { params })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getSubCategory(id) {
    return await api
      .get("/blog/subcategories/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async deleteSubCategory(id) {
    return await api
      .delete("/blog/subcategories/delete/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }
}

export default new SubCategoryService();
