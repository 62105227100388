<template>
  <div>
    <button
      class="btn btn-dark"
      data-bs-toggle="modal"
      data-bs-target="#modalAddArticle"
    >
      <i class="fas fa-plus"></i> Añadir nuevo
    </button>

    <!-- Modal Add Article -->
    <div
      class="modal fade"
      id="modalAddArticle"
      tabindex="-1"
      aria-labelledby="modalAddArticle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-lg-down"
      >
        <div class="modal-content">
          <form @submit="add">
            <div class="modal-header">
              <h5 class="modal-title">Añadir artículo</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              <div class="form-group mb-3">
                <label class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addArticle.title"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Contenido</label>
                <QuillEditor
                  theme="snow"
                  v-model:content="addArticle.content"
                  contentType="html"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Imagen de portada</label>
                <input
                  type="file"
                  class="form-control"
                  @change="uploadCoverImage"
                  accept="image/*"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Temática</label>
                <select
                  class="form-select form-control"
                  v-model="addArticle.topic"
                  @change="applyFilters('topic')"
                >
                  <option :key="c" v-for="c in topics" :value="c.UUID">
                    {{ c.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Categoría</label>
                <select
                  class="form-select form-control"
                  v-model="addArticle.category"
                  @change="applyFilters('category')"
                >
                  <option :key="c" v-for="c in categories" :value="c.UUID">
                    {{ c.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Subcategoría</label>
                <select
                  class="form-select form-control"
                  v-model="addArticle.subcategory"
                >
                  <option :key="c" v-for="c in subcategories" :value="c.UUID">
                    {{ c.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Enlace de video</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addArticle.videoURL"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Etiquetas</label>
                <div class="pills-checks d-flex flex-wrap">
                  <div :key="t" v-for="t in tags">
                    <label
                      v-if="addArticle.tags.includes(t.UUID)"
                      class="form-check pills-element checked"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="addArticle.tags"
                        :value="t.UUID"
                      />
                      <span class="form-check-label">{{ t.name }}</span>
                    </label>
                    <label v-else class="form-check pills-element">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="addArticle.tags"
                        :value="t.UUID"
                      />
                      <span class="form-check-label">{{ t.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
              <button
                id="submitAddArticle"
                type="submit"
                class="btn btn-primary"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleService from "@/services/article.service";
import UserService from "@/services/user.service";
import CategoryService from "@/services/category.service";
import SubCategoryService from "@/services/subcategory.service";
import TopicService from "@/services/topic.service";

import Swal from "sweetalert2";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "AddArticle",
  props: {
    tags: Object,
  },
  components: {
    QuillEditor,
  },
  data() {
    return {
      loading: true,
      article: [],
      addArticle: {
        title: "",
        content: "",
        tags: [],
        category: [],
        videoURL: "",
      },
      addTags: [],
      coverImage: "",
      user: [],
      topics: [],
      categories: [],
      subcategories: [],
    };
  },
  methods: {
    async start() {
      this.loading = true;
      await this.getTopics();
      this.loading = false;
    },
    async getCurrentUser() {
      this.user = await UserService.getLocalUser();
    },
    uploadCoverImage(event) {
      this.coverImage = event.target.files[0];
    },
    async getCategories(params) {
      var response = await CategoryService.getAllCategories(params);
      this.categories = [];
      this.categories = response.data;
    },
    async getSubCategories(params) {
      var response = await SubCategoryService.getAllSubCategories(params);
      this.subcategories = [];
      this.subcategories = response.data;
    },
    async getTopics() {
      var response = await TopicService.getAllTopics();
      this.topics = response.data;
    },
    async add(evt) {
      evt.preventDefault();
      window.$("#submitAddArticles").empty();
      window
        .$("#submitAddArticles")
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      const fd = new FormData();
      if (this.coverImage != "") {
        fd.append("coverImage", this.coverImage);
      }
      fd.append("title", this.addArticle.title);
      fd.append("content", this.addArticle.content);
      this.addArticle.tags.forEach((tag) => {
        fd.append("tags", tag);
      });
      fd.append("topic", this.addArticle.topic);
      fd.append("category", this.addArticle.category);
      fd.append("subcategory", this.addArticle.subcategory);
      fd.append("videoURL", this.addArticle.videoURL);
      fd.append("author", this.user.UUID);

      // console.log(fd);
      // for (const value of fd.values()) {
      //     console.log(value);
      // }
      evt.preventDefault();

      let response = await ArticleService.addArticle(fd);
      console.log(response);
      window.$("#submitAddArticles").empty();
      window.$("#submitAddArticles").append("Guardar");

      if (response.status == 201) {
        this.title = null;
        // window.$('#modalAddArticles').modal('hide');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });

        this.$router.go();
      }
    },

    async applyFilters(key = null) {
      this.loading = true;

      if (key == "topic") {
        this.categories = [];
        this.subcategories = [];

        await this.getCategories({
          topic: this.addArticle.topic,
        });
      } else if (key == "category") {
        this.subcategories = [];
        await this.getSubCategories({
          topic: this.addArticle.topic,
          category: this.addArticle.category,
        });
      }
      this.loading = false;
    },
  },
  created() {
    this.getCurrentUser();
    this.start();
  },
};
</script>
