<template>
  <button
    class="btn btn-sm btn-dark"
    data-bs-toggle="modal"
    data-bs-target="#modalAddCourse"
  >
    <i class="fas fa-plus me-2"></i>Añadir
  </button>

  <!-- Modal Add Course -->
  <div
    class="modal fade"
    id="modalAddCourse"
    tabindex="-1"
    aria-labelledby="modalAddCourse"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-lg-down"
    >
      <div class="modal-content">
        <form @submit="add">
          <div class="modal-header">
            <h5 class="modal-title">Añadir curso</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="form-group mb-3">
              <label class="form-label">Título</label>
              <input
                type="text"
                class="form-control"
                v-model="addCourse.title"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Subtítulo</label>
              <input
                type="text"
                class="form-control"
                v-model="addCourse.subtitle"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Sumario</label>
              <QuillEditor
                theme="snow"
                v-model:content="addCourse.summary"
                contentType="html"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Imagen de portada</label>
              <input
                type="file"
                class="form-control"
                @change="uploadCoverImage"
                accept="image/*"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Fecha de lanzamiento</label>
              <input
                type="date"
                class="form-control"
                v-model="addCourse.releaseDate"
              />
            </div>
            <div class="row">
              <div class="col-md-4 form-group mb-3">
                <label class="form-label">Horas totales</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="addCourse.totalHours"
                />
              </div>
              <div class="col-md-4 form-group mb-3">
                <label class="form-label">Sumario</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="addCourse.totalMinutes"
                />
              </div>
              <div class="col-md-4 form-group mb-3">
                <label class="form-label">Vídeos totales</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="addCourse.totalVideos"
                />
              </div>
            </div>
            <h4 class="mt-4">Profesor</h4>
            <div class="form-group mb-3">
              <label class="form-label">Imagen</label>
              <input
                type="file"
                class="form-control"
                @change="uploadTeacherPicture"
                accept="image/*"
              />
            </div>
            <div class="row">
              <div class="col-md-6 form-group mb-3">
                <label class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addCourse.teacherName"
                />
              </div>
              <div class="col-md-6 form-group mb-3">
                <label class="form-label">Apellido</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addCourse.teacherLastName"
                />
              </div>
              <div class="col-md-6 form-group mb-3">
                <label class="form-label">Descripción</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addCourse.teacherDescription"
                />
              </div>
              <div class="col-md-6 form-group mb-3">
                <label class="form-label">Rol</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addCourse.teacherRole"
                />
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="addCourse.isOnline"
                />
                <span class="form-check-label">Es online</span>
              </label>
            </div>
            <div class="row" v-if="addCourse.isOnline == false">
              <div class="col-md-6 form-group mb-3">
                <label class="form-label">Horario</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addCourse.schedule"
                />
              </div>
              <div class="col-md-6 form-group mb-3">
                <label class="form-label">Localización</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addCourse.location"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button id="submitAddCourse" type="submit" class="btn btn-primary">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CourseService from "../../services/course.service";
import UserService from "../../services/user.service";

import Swal from "sweetalert2";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "AddCourse",
  components: {
    QuillEditor,
  },
  data() {
    return {
      course: [],
      addCourse: {
        title: "",
        subtitle: "",
        summary: "",
        coverImage: "",
        releaseDate: "",
        totalHours: 0,
        totalMinutes: 0,
        totalVideos: 0,
        teacherName: "",
        teacherLastName: "",
        teacherPicture: "",
        teacherDescription: "",
        teacherRole: "",
        isOnline: true,
        schedule: null,
        location: null,
      },
      coverImage: "",
      teacherPicture: "",
      user: [],
    };
  },
  methods: {
    async getCurrentUser() {
      this.user = await UserService.getLocalUser();
    },
    uploadCoverImage(event) {
      this.coverImage = event.target.files[0];
    },
    uploadTeacherPicture(event) {
      this.teacherPicture = event.target.files[0];
    },
    async add(evt) {
      evt.preventDefault();
      window.$("#submitAddCourse").empty();
      window
        .$("#submitAddCourse")
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      var d = new Date(this.addCourse.releaseDate),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      var releaseDate = [year, month, day].join("-");

      const fd = new FormData();
      if (this.coverImage != "") {
        fd.append("coverImage", this.coverImage);
      }
      if (this.teacherPicture != "") {
        fd.append("teacherPicture", this.teacherPicture);
      }
      fd.append("title", this.addCourse.title);
      fd.append("subtitle", this.addCourse.subtitle);
      fd.append("summary", this.addCourse.summary);
      fd.append("releaseDate", this.addCourse.releaseDate);
      fd.append("totalHours", this.addCourse.totalHours);
      fd.append("totalMinutes", this.addCourse.totalMinutes);
      fd.append("totalVideos", this.addCourse.totalVideos);
      fd.append("teacherName", this.addCourse.teacherName);
      fd.append("teacherLastName", this.addCourse.teacherLastName);
      fd.append("teacherDescription", this.addCourse.teacherDescription);
      fd.append("teacherRole", this.addCourse.teacherRole);
      fd.append("isOnline", this.addCourse.isOnline);
      fd.append("schedule", this.addCourse.schedule);
      fd.append("location", this.addCourse.location);
      fd.append("enabled", true);
      fd.append("author", this.user.UUID);

      // console.log(fd);
      // for (const value of fd.values()) {
      //     console.log(value);
      // }
      evt.preventDefault();

      let response = await CourseService.addCourse(fd);
      console.log(response);
      window.$("#submitAddCourse").empty();
      window.$("#submitAddCourse").append("Guardar");

      if (response.status == 201) {
        this.title = null;
        // window.$('#modalAddCourse').modal('hide');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });

        this.$router.go();
      }
    },
  },
  created() {
    this.getCurrentUser();
  },
};
</script>
