<template>
	<div
		class="container-fluid d-flex"
		style="
			width: 100vw;
			height: 100vh;
			justify-content: center;
			align-items: center;
		"
	>
		<div class="text-center">
			<i
				class="fa-duotone fa-circle-check mb-4"
				style="font-size: 5em"
			></i>
			<h4 class="mb-4">¡Tu suscripción ha sido confirmada!</h4>
			<router-link
				v-if="this.currentUser"
				to="/membership/subscription"
				class="btn btn-fbo btn-lg my-3"
				>Continuar
			</router-link>
			<a
				v-else
				href="https://fundacionbertinosborne.org/"
				class="btn btn-fbo btn-lg my-3"
				>Continuar</a
			>
		</div>
	</div>
</template>

<script>
export default {
	name: "SuccessPaymentView",
	components: {},
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		currentUser() {
			return this.$store.state.user.data;
		},
	},
	methods: {},
	mounted() {},
};
</script>
