import api from "./api";

class TopicService {
  /**************************************
   *  API FBO
   **************************************/
  async addTopic(params) {
    return await api
      .post("/blog/topics/create/", params)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async updateTopic(id, params) {
    return await api
      .put("/blog/topics/update/" + id + "/", params)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getAllTopics(params) {
    return await api
      .get("/blog/topics/list", { params })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getTopic(id) {
    return await api
      .get("/blog/topics/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async deleteTopic(id) {
    return await api
      .delete("/blog/topics/delete/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }
}

export default new TopicService();
