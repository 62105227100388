<template>
  <td>
    <div>{{ topic.name }}</div>
  </td>
  <td class="text-center">
    <div v-if="topic.enabled">
      <span class="badge badge-success">Activado</span>
    </div>
    <div v-else><span class="badge badge-danger">Desactivado</span></div>
  </td>
  <td class="text-center">
    <!--<router-link class="btn btn-sm btn-outline-primary" :to="{ name: 'topic', params: {topicId:topic.id}}"><i class="far fa-eye"></i></router-link>-->
    <button
      class="btn btn-outline-dark m-1"
      data-bs-toggle="modal"
      :data-bs-target="'#modalEditTopic' + id"
    >
      <i class="fas fa-edit"></i>
    </button>
    <button class="btn btn-outline-danger m-1" @click="deleteTopic()">
      <i class="fas fa-trash"></i>
    </button>
  </td>

  <!-- Modal Edit Topic -->
  <div
    class="modal fade"
    :id="'modalEditTopic' + id"
    tabindex="-1"
    aria-labelledby="modalEditTopic"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down">
      <div class="modal-content">
        <form @submit="update">
          <div class="modal-header">
            <h5 class="modal-title">Editar temática</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="form-group mb-3">
              <label class="form-label">Nombre</label>
              <input
                type="text"
                class="form-control"
                v-model="updateTopic.name"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Descripción</label>
              <input
                type="text"
                class="form-control"
                v-model="updateTopic.description"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Imagen</label>
              <input
                type="file"
                class="form-control"
                @change="uploadImage"
                accept="image/*"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Color</label>
              <input
                type="text"
                class="form-control"
                v-model="updateTopic.color"
              />
            </div>
            <div class="col-12 form-check form-switch ps-5">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                checked
                v-model="updateTopic.enabled"
              />
              <label class="form-check-label" for="flexSwitchCheckChecked"
                >Activar</label
              >
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              :id="'submitBtn' + id"
              type="submit"
              class="btn btn-primary"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import TopicService from "@/services/topic.service";

import Swal from "sweetalert2";

export default {
  name: "TopicRow",
  props: {
    topic: Object,
  },
  data() {
    return {
      id: this.topic.UUID,
      updateTopic: {
        name: this.topic.name,
        description: this.topic.description,
        color: this.topic.color,
        enabled: this.topic.enabled,
      },
      image: "",
    };
  },
  methods: {
    uploadImage(event) {
      this.image = event.target.files[0];
    },
    async update(evt) {
      evt.preventDefault();
      window.$("#submitBtn").empty();
      window
        .$("#submitBtn")
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      const fd = new FormData();
      if (this.image != "") {
        fd.append("image", this.image);
      }
      fd.append("name", this.updateTopic.name);
      fd.append("color", this.updateTopic.color);
      fd.append("description", this.updateTopic.description);
      fd.append("enabled", this.updateTopic.enabled);
      evt.preventDefault();

      let response = await TopicService.updateTopic(this.topic.UUID, fd);

      window.$("#submitBtn").empty();
      window.$("#submitBtn").append("Guardar");
      if (response.status == 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
        this.$router.go();
      }
    },
    async deleteTopic() {
      let response = await TopicService.deleteTopic(this.topic.UUID);
      if (response.status == 200 || response.status == 204) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha borrado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
      }
      // this.$router.go()
    },
  },
};
</script>
