<template>
	<div id="paycomet_iframe">
		<iframe
			v-if="!loading && payment.enabled"
			title="titulo"
			sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms"
			:src="payment.url_iframe"
			width="100%"
			height="100%"
			frameborder="1"
			marginheight="0"
			marginwidth="0"
			scrolling="yes"
			style="border: 0px solid #000000; padding: 0px; margin: 0px"
		></iframe>
		<div
			v-else-if="!loading && !payment.enabled"
			class="container-fluid d-flex"
			style="
				width: 100vw;
				height: 100vh;
				justify-content: center;
				align-items: center;
			"
		>
			<div class="text-center">
				<i
					class="fa-regular fa-link-simple-slash mb-4"
					style="font-size: 5em"
				></i>
				<h4 class="mb-4">No existe este enlace</h4>
			</div>
		</div>
		<div
			v-else
			class="container-fluid d-flex"
			style="
				width: 100vw;
				height: 100vh;
				justify-content: center;
				align-items: center;
			"
		>
			<div class="text-center">
				<div class="spinner-border" role="status">
					<span class="sr-only">Loading...</span>
				</div>
				<h4 class="mt-4">Cargando...</h4>
			</div>
		</div>
	</div>
</template>

<style scoped>
#paycomet_iframe {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 10;
	background: white;
}
</style>

<script>
import MembershipService from "@/services/membership.service.js";

export default {
	name: "PaymentPaycometView",
	data() {
		return {
			paymentID: this.$route.params.paymentID,
			loading: true,
			payment: null,
		};
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getPayment();
			this.loading = false;
		},

		async getPayment() {
			var response = await MembershipService.getPayment(this.paymentID);
			this.payment = response.data;
			console.log(this.payment);
		},
	},
	created() {
		this.start();
	},
};
</script>