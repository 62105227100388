<template>
  <button
    class="btn btn-dark btn-inscripciones"
    @click="getCourseInscriptionsCSV"
  >
    Descargar CSV Inscripciones
  </button>
  <p v-if="loading" class="text-center">
    Generando CSV... <i class="fa fa-spinner fa-spin"></i>
  </p>
</template>

<style>
.btn.btn-inscripciones {
  display: block;
  margin: 20px auto;
}
</style>

<script>
import InscriptionService from "../../services/inscription.service";

export default {
  name: "DownloadInscriptionsCSV",
  props: {
    course: Object,
  },
  data() {
    return {
      inscriptions: [],
      loading: false,
    };
  },
  methods: {
    async getCourseInscriptionsCSV() {
      this.loading = true;
      this.inscriptions = await InscriptionService.getCourseInscriptionsCSV(
        this.course.UUID
      );
      this.loading = false;
    },
  },
};
</script>
