import api from "./api";
import TokenService from "./token.service";
import UserService from "./user.service";

class AuthService {
  async login({ username, password }) {
    return await api
      .post("/auth/token/", { username, password })
      .then(async (response) => {
        // Guardamos el token en variable local "auth"
        return await TokenService.setAuth(response.data).then(async () => {
          // Cargamos información de usuario y la guardamos en variable local "user"
          return await UserService.setCurrentLocalUser().then(async () => {
            return response.data;
          });
        });
      });
  }

  logout() {
    localStorage.clear();
  }

  register({ username, email, password }) {
    return api.post("/auth/signup", {
      username,
      email,
      password,
    });
  }
}

export default new AuthService();
