<template>
	<div id="clipboard" class="d-flex mb-3">
		<input
			ref="clone"
			readonly
			:value="text"
			class="form-control form-control-solid me-3 flex-grow-1"
		/>
		<button class="btn btn-light fw-bold flex-shrink-0" @click="copy">
			<i class="fa-duotone fa-clipboard me-2"></i>Copiar link
		</button>
	</div>
</template>

<style>
#clipboard input:focus {
	background: #1cc88a;
	color: white;
}
</style>

<script>
export default {
	props: {
		text: String,
	},
	data() {
		return {
			text: this.text,
		};
	},
	methods: {
		copy() {
			this.$refs.clone.focus();
			navigator.clipboard.writeText(this.text);
		},
	},
};
</script>
