<template>
  <div>
    <div class="card" id="admin-menu">
      <div class="card-header">
        <div class="card-title mb-0">Menú</div>
      </div>
      <div class="card-body">
        <div class="col-12 item-link-group">
          <button
            class="btn-link-menu nav-link"
            @click="$emit('selectOption', 'categories')"
          >
            <i class="fa fa-edit"></i>
            <div class="d-inline" style="margin-left: 20px">Categorías</div>
          </button>
        </div>
        <div class="col-12 item-link-group">
          <button
            class="btn-link-menu nav-link"
            @click="$emit('selectOption', 'conversations')"
          >
            <i class="fa fa-file"></i>
            <div class="d-inline" style="margin-left: 20px">Conversaciones</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarForums",
  emits: ["selectOption"],
  methods: {
    submit() {
      this.$emit("selectOption");
    },
  },
};
</script>
