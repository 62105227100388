<template>
	<div class="container-fluid px-0" style="min-height: 100vh">
		<div class="text-center">
			<img
				src="../../assets/img/dona2/header-dona2-opt2.png"
				style="
					width: 100%;
					max-width: 500px;
					text-align: center;
					margin: 0 auto;
				"
			/>

			<div class="p-5 text-center text-white bg-fbo">
				<h2 class="mb-3">¡Hazte socio!</h2>
				<p>
					Con tu aportación, podremos seguir acompañando juntos a las
					familias de hijos con discapacidad.
				</p>
			</div>
		</div>
		<div class="container">
			<div v-if="!loading" class="p-md-5 p-3">
				<form @submit.prevent="createSubscription()">
					<div
						class="d-flex flex-column flex-md-row mb-5 price-group-selector"
					>
						<div
							class="p-3 text-center radio-item left d-flex flex-column justify-content-center"
							:class="{
								active: isPriceOption1Active,
							}"
							@click="priceOptionToggle(1)"
						>
							<div class="fs-1 rounded-circle price">
								<span>2€</span>
							</div>
						</div>
						<div
							class="p-3 text-center d-flex flex-column justify-content-center radio-item right"
							:class="{
								active: isPriceOption2Active,
							}"
							@click="priceOptionToggle(2)"
						>
							<div class="fs-3 mb-3">Otra cantidad</div>

							<div
								class="input-group mb-3"
								style="max-width: 250px; margin: 0 auto"
							>
								<input
									type="number"
									min="2"
									class="form-control form-control-lg form-control-solid"
									v-model="other_quanity"
									@change="updateQuantity"
								/>
								<span class="input-group-text px-5">€</span>
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-md-6">
							<label class="form-label">Tipo</label>
							<div>
								<label>
									<input
										type="radio"
										v-model="newMember.is_business"
										value="true"
									/>
									Empresa
								</label>
							</div>
							<div>
								<label>
									<input
										type="radio"
										v-model="newMember.is_particular"
										value="true"
									/>
									Particular
								</label>
							</div>
						</div>
					</div>
					<div v-if="newMember.is_business" class="row mb-3">
						<div class="col-md-6">
							<label class="form-label"
								>Nombre de la Empresa</label
							>
							<input
								type="text"
								class="form-control"
								v-model="newMember.business_name"
								:disabled="!newMember.is_business"
							/>
						</div>
						<div class="col-md-6">
							<label for="" class="form-label">CIF</label>
							<input
								type="text"
								class="form-control"
								v-model="newMember.document_cif"
								:disabled="!newMember.is_business"
							/>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-md-6">
							<label class="form-label">Nombre</label>
							<input
								type="text"
								class="form-control"
								v-model="newMember.first_name"
								required
							/>
						</div>
						<div class="col-md-6">
							<label for="" class="form-label">Apellidos</label>
							<input
								type="text"
								class="form-control"
								v-model="newMember.last_name"
								required
							/>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-md-6">
							<label for="" class="form-label">Email</label>
							<input
								type="email"
								class="form-control"
								v-model="newMember.email"
								required
							/>
						</div>
						<div class="col-md-6">
							<label for="" class="form-label">Teléfono</label>
							<input
								type="text"
								class="form-control"
								v-model="newMember.telephone"
								required
							/>
						</div>
					</div>
					<div class="row mb-3">
						<!-- <div class="col-md-8">
							<label for="" class="form-label">Dirección</label>
							<input
								type="text"
								class="form-control"
								v-model="newMember.address"
								required
							/>
						</div> -->
						<div class="col-md-4">
							<label for="" class="form-label"
								>Código Postal</label
							>
							<input
								type="text"
								class="form-control"
								v-model="newMember.postal_code"
								required
							/>
						</div>
						<div class="col-md-4">
							<label for="" class="form-label">Provincia</label>
							<select
								required
								name="provincia"
								class="form-control"
								v-model="newMember.region"
							>
								<option
									:key="item"
									v-for="item in regions"
									:value="item.id"
								>
									{{ item.name }}
								</option>
							</select>
						</div>
						<div class="col-md-4">
							<label for="" class="form-label">País</label>
							<select
								required
								name="provincia"
								class="form-control"
								v-model="newMember.country"
							>
								<option
									:key="item"
									v-for="item in countries"
									:value="item.id"
								>
									{{ item.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="row mb-5">
						<div v-if="!newMember.is_business" class="col-md-6">
							<label for="" class="form-label"
								>DNI / NIE / PASAPORTE</label
							>
							<input
								type="text"
								class="form-control"
								v-model="newMember.document"
								required
							/>
						</div>

						<div class="col-md-6 mb-5">
							<label for="" class="form-label"
								>Tipo de suscripción</label
							>
							<select
								required
								name="provincia"
								class="form-control"
								v-model="newMember.subscription"
								@change="updateQuantity"
							>
								<option value="monthly">Mensual</option>
								<!-- <option value="annual">Anual</option> -->
							</select>
						</div>
						<div class="col-md-12">
							<div class="form-check" style="padding-left: 3rem">
								<input
									class="form-check-input"
									type="checkbox"
									value=""
									id="flexCheckDefault"
									style="margin-left: -3em"
									required
								/>
								<label
									class="form-check-label"
									for="flexCheckDefault"
								>
									He leído y acepto los
									<router-link
										to="/legal/terms-and-conditions"
										target="_blank"
										>términos y condiciones</router-link
									>
								</label>
							</div>
						</div>
					</div>
					<div class="text-center">
						<p class="muted lead mb-5">
							Has seleccionado una aportación periódica de
							{{ message_total }}
						</p>
						<button
							class="btn btn-fbo"
							type="submit"
							id="submitReferralBtn"
						>
							Continuar con el método de pago
							<i class="fa-duotone fa-arrow-right ms-3"></i>
						</button>
					</div>
				</form>
			</div>

			<div v-else class="text-center p-5">
				<div class="spinner-border" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<div>Cargando información</div>
			</div>
		</div>
	</div>
</template>

<script>
import NavbarMembership from "@/components/Layout/NavbarMembership.vue";

import MembershipService from "@/services/membership.service.js";
import ComunidadService from "@/services/comunidad.service.js";
import RegionService from "@/services/region.service.js";
import CountryService from "@/services/country.service.js";

import Swal from "sweetalert2";

export default {
	name: "MembeReferralView",
	components: {
		NavbarMembership,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	watch: {
		"newMember.is_business": function (newValue) {
			if (newValue) {
				this.newMember.is_particular = false;
			}
		},
		"newMember.is_particular": function (newValue) {
			if (newValue) {
				this.newMember.is_business = false;
			}
		},
	},
	data() {
		return {
			referral_code: this.$route.params.referral_code,
			loading: true,
			comunidades: null,
			regions: null,
			countries: null,
			isPriceOption1Active: true,
			isPriceOption2Active: false,
			newMember: {
				first_name: "",
				last_name: "",
				email: "",
				telephone: "",
				country: 66,
				subscription: "monthly",
				quantity: 2,
				document: "",

				document_cif: "",
				is_particular: true,
				is_business: false,
				business_name: "",

				address: "",
				postal_code: "",
				region: null,
				referral_code: null,
			},
			countries: null,
			regions: null,
			message_total: "2€/mes",
			other_quanity: 5,
		};
	},
	methods: {
		async start() {
			this.loading = true;
			console.log(this.referral_code);
			await this.getComunidades();
			await this.getCountries();
			await this.getRegions();
			this.loading = false;
		},

		async getComunidades() {
			var response = await ComunidadService.getAllComunidades();
			this.comunidades = response.data;
			// console.log(this.comunidades, "Comunidades")
		},

		async getCountries() {
			var response = await CountryService.getAllCountries();
			this.countries = response.data;
			// console.log(this.countries, "Countries")
		},

		async getRegions() {
			var response = await RegionService.getAllRegions();
			this.regions = response.data;
			// console.log(this.regions, "Regions")
		},

		async createSubscription() {
			if (this.newMember.quantity <= 0) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Por favor, introduzca una cantidad válida..",
					confirmButtonColor: "#ff9b04",
				});
				return;
			}

			console.log("Input data :", this.newMember);
			window.$("#submitReferralBtn").empty();
			window.$("#submitReferralBtn").attr("disabled", "true");
			window
				.$("#submitReferralBtn")
				.append(
					'<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
				);

			if (this.referral_code) {
				this.newMember.referral_code = this.referral_code;
			}
			var response = await MembershipService.createPublicSubscription(
				this.newMember
			);

			console.log(response);
			if (response.status == 201) {
				this.$router.push(
					"/payments/paycomet/" + response.data.payment_id
				);
			} else if (response.status == 409) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: response.data.message,
					confirmButtonColor: "#ff9b04",
				});
			} else {
				console.log(response);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error. Por favor, vuelve a intentarlo. Si el error persiste ponte en contacto con socios@fundacionbertinosborne.org",
					confirmButtonColor: "#ff9b04",
				});
			}

			window.$("#submitReferralBtn").empty();
			window
				.$("#submitReferralBtn")
				.append("Continuar con el método de pago");
			window.$("#submitReferralBtn").removeAttr("disabled");
		},

		priceOptionToggle(num) {
			if (num == 2) {
				this.isPriceOption1Active = false;
				this.isPriceOption2Active = true;
			} else {
				this.isPriceOption1Active = true;
				this.isPriceOption2Active = false;
			}
			this.updateQuantity();
		},

		updateQuantity() {
			var price = 2;
			if (this.isPriceOption1Active) {
				price = 2;
			} else if (this.isPriceOption2Active) {
				price = this.other_quanity;
			}

			if (this.newMember.subscription == "monthly") {
				this.message_total = price + "€/mes";
			} else if (this.newMember.subscription == "annual") {
				price = price * 12;
				this.message_total = price + "€/año";
			}
			this.newMember.quantity = price;
		},
	},
	created() {
		this.start();
	},
	mounted() {},
};
</script>
