<template>
	<footer class="bg-white shadow-sm border-top pb-3">
		<div class="col-12 text-center">
			<div class="grid text-center mt-5">
				<router-link to="/legal/legal-warning" class="g-col-4 me-3">
					Aviso legal
				</router-link>
				<router-link
					to="/legal/terms-and-conditions"
					class="g-col-4 me-3"
				>
					Términos y condiciones
				</router-link>
				<router-link to="/legal/privacy-policy" class="g-col-4">
					Privacidad
				</router-link>
			</div>
			<div class="mt-5 border-top pt-3">
				<small
					>Copyright Fundación Bertín Osborne. Design by
					<a href="https://wejyc.com">WEJYC</a></small
				>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "FooterView",
	computed: {
		currentUser() {
			return this.$store.state.user.data;
		},
	},
	data() {
		return {
			user: null,
		};
	},
	methods: {
		async start() {
			this.myEventHandler();
			this.getUser();
		},
	},
	mounted() {},
};
</script>
