import axios from "axios";

var API_URL = "";

if (location.hostname === "localhost") {
    // API_URL =  "https://apitest.fbofamily.app/api/v1";
    API_URL = "http://127.0.0.1:8000/api/v1";
} else {
    API_URL = "https://api.fbofamily.app/api/v1";
}

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

export default instance;
