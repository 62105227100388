<template>
	<div class="container-fluid pt-3 pt-md-5">
		<div class="row">
			<div class="col-lg-3 col-md-4">
				<NavbarMembership />
			</div>
			<div class="col-lg-9 col-md-8 px-0 px-md-3">
				<div v-if="!loading" class="col-12">
					<div v-if="user_role == 'superadmin'" class="row">
						<div class="col-xl-3 col-md-6 mb-4">
							<div
								class="card border-left-warning shadow h-100 py-2"
							>
								<div class="card-body">
									<div
										class="row no-gutters align-items-center"
									>
										<div class="col mr-2">
											<div
												class="text-xs font-weight-bold text-warning text-uppercase mb-1"
											>
												Socios nuevos (Mes Actual)
											</div>
											<div
												class="h5 mb-0 font-weight-bold text-gray-800"
											>
												{{
													numEnabledMembers_current_month
												}}
											</div>
										</div>
										<div class="col-auto">
											<i
												class="fa-duotone fa-user-plus fa-2x text-gray-300"
											></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-6 mb-4">
							<div
								class="card border-left-warning shadow h-100 py-2"
							>
								<div class="card-body">
									<div
										class="row no-gutters align-items-center"
									>
										<div class="col mr-2">
											<div
												class="text-xs font-weight-bold text-warning text-uppercase mb-1"
											>
												Socios nuevos (Año Actual)
											</div>
											<div
												class="h5 mb-0 font-weight-bold text-gray-800"
											>
												{{
													numEnabledMembers_current_year
												}}
											</div>
										</div>
										<div class="col-auto">
											<i
												class="fa-duotone fa-user-plus fa-2x text-gray-300"
											></i>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-xl-3 col-md-6 mb-4">
							<div
								class="card border-left-success shadow h-100 py-2"
							>
								<div class="card-body">
									<div
										class="row no-gutters align-items-center"
									>
										<div class="col mr-2">
											<div
												class="text-xs font-weight-bold text-success text-uppercase mb-1"
											>
												Socios totales (activos)
											</div>
											<div
												class="h5 mb-0 font-weight-bold text-gray-800"
											>
												{{ numEnabledMembers_total }}
											</div>
										</div>
										<div class="col-auto">
											<i
												class="fa-duotone fa-users fa-2x text-gray-300"
											></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-6 mb-4">
							<div
								class="card border-left-danger shadow h-100 py-2"
							>
								<div class="card-body">
									<div
										class="row no-gutters align-items-center"
									>
										<div class="col mr-2">
											<div
												class="text-xs font-weight-bold text-danger text-uppercase mb-1"
											>
												Socios totales (inactivos)
											</div>
											<div
												class="h5 mb-0 font-weight-bold text-gray-800"
											>
												{{ numNotEnabledMembers_total }}
											</div>
										</div>
										<div class="col-auto">
											<i
												class="fa-duotone fa-users-slash fa-2x text-gray-300"
											></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 text-end">
						<button
							type="button"
							class="btn btn-fbo mb-3"
							@click="generateFile()"
						>
							Descargar lista de Socios
						</button>
					</div>
					<div class="card mb-5">
						<div class="card-body">
							<h6 class="font-weight-bold">Socios</h6>
							<div class="col-md-5 ms-auto mb-3 px-0">
								<div class="row">
									<div class="input-group">
										<input
											class="form-control border-end-0 border"
											type="search"
											placeholder="Buscar..."
											id="example-search-input"
											v-model="searchValue"
										/>
										<span class="input-group-append">
											<button
												class="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5"
												type="button"
											>
												<i class="fa fa-search"></i>
											</button>
										</span>
									</div>
								</div>
							</div>
							<div class="table-responsive">
								<EasyDataTable
									v-model:server-options="serverOptions"
									:loading="loadingData"
									:headers="headers"
									:items="items"
									:server-items-length="serverItemsLength"
								>
									<template
										#item-name="{ first_name, last_name }"
									>
										<div class="text-uppercase">
											{{ first_name + " " + last_name }}
										</div>
									</template>
									<template #item-region="{ region }">
										<div v-if="region">
											{{ region.name }}
										</div>
									</template>
									<template #item-enabled="{ enabled }">
										<div class="text-center">
											<span
												v-if="enabled"
												class="badge badge-light-success"
												>Activo</span
											>
											<span
												v-else
												class="badge badge-light-danger"
												>Inactivo</span
											>
										</div>
									</template>
									<template #item-quantity="{ quantity }">
										<div class="text-center">
											<span
												v-if="user_role == 'superadmin'"
												>{{
													formatCurrency(
														quantity / 100
													)
												}}
											</span>
											<span
												v-else
												style="filter: blur(4px)"
												>XXXX</span
											>
										</div>
									</template>
									<template
										#item-subscription="{ subscription }"
									>
										<div>
											<div
												v-if="
													subscription == 'monthly' &&
													user_role == 'superadmin'
												"
											>
												Mensual
											</div>
											<div
												v-else-if="
													subscription == 'annual' &&
													user_role == 'superadmin'
												"
											>
												Anual
											</div>
											<span
												v-else
												style="filter: blur(4px)"
												>XXXX</span
											>
										</div>
									</template>
									<template #item-createdAt="{ createdAt }">
										<div>
											{{ this.formatDate(createdAt) }}
										</div>
									</template>
									<template
										#item-num_members="{ num_members }"
									>
										<div>
											<span>{{ num_members }}</span>
										</div>
									</template>

									<template #item-action="{ UUID }">
										<div class="text-center">
											<router-link
												v-if="user_role == 'superadmin'"
												:to="
													'/membership/members/' +
													UUID
												"
												class="btn btn-sm btn-fbo"
												><i
													class="fa-regular fa-user"
												></i
											></router-link>
										</div>
									</template>
								</EasyDataTable>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="text-center py-5">
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
					<div>Cargando información</div>
				</div>
			</div>
		</div>
	</div>
	<router-view></router-view>
</template>

<script>
// import mixin
import userRoles from "@/mixins/user-roles.js";

import NavbarMembership from "@/components/Layout/NavbarMembership.vue";

import ClipBoard from "@/components/Global/ClipBoard.vue";

import MembershipService from "@/services/membership.service.js";
import ComunidadService from "@/services/comunidad.service.js";
import RegionService from "@/services/region.service.js";
import CountryService from "@/services/country.service.js";
import UserService from "@/services/user.service.js";

import Swal from "sweetalert2";

export default {
	name: "MembersView",
	mixins: [userRoles],
	components: {
		NavbarMembership,
		ClipBoard,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	data() {
		return {
			loading: true,
			loadingData: true,
			user: null,
			members: null,
			newMember: {
				country: 66,
				subscription: "monthly",
				quantity: 2.75,
			},
			headers: [
				{ text: "Nombre", value: "name", sortable: true },
				{ text: "Nº socios", value: "num_members" },
				{ text: "Estado", value: "enabled", sortable: true },
				{ text: "Cantidad", value: "quantity", sortable: true },
				{ text: "Suscripción", value: "subscription", sortable: true },
				{ text: "Fecha de alta", value: "createdAt", sortable: true },
				{ text: "Provincia", value: "region", sortable: true },
				{ text: "", value: "action" },
			],
			items: [],

			searchValue: "",
			serverOptions: {
				page: 1,
				rowsPerPage: 25,
				sortBy: "createdAt",
				sortType: "desc",
			},
			serverItemsLength: 0,
			numEnabledMembers_current_month: 0,
			numEnabledMembers_current_year: 0,
			numEnabledMembers_total: 0,
			numNotEnabledMembers_total: 0,
		};
	},
	watch: {
		serverOptions(value) {
			this.getMyMembers();
		},
		searchValue(value) {
			this.getMyMembers();
		},
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getUser();
			await this.getMyMembers();

			this.loading = false;
		},

		async getUser() {
			this.user = await UserService.getLocalUser(this.teamUUID);
			// console.log(this.user, "User")
		},

		async getMyMembers() {
			this.loadingData = true;

			this.serverOptions.search = this.searchValue;

			var response = await MembershipService.getMyMembers(
				this.serverOptions
			);

			this.members = response.data.items;
			this.serverItemsLength = response.data.serverItemsLength;
			this.numEnabledMembers_current_month =
				response.data.numEnabledMembers_current_month;
			this.numEnabledMembers_current_year =
				response.data.numEnabledMembers_current_year;
			this.numEnabledMembers_total =
				response.data.numEnabledMembers_total;
			this.numNotEnabledMembers_total =
				response.data.numNotEnabledMembers_total;

			console.log("Socios: ", response.data);

			// Table content
			this.items = [];
			for (var i = 0; i < this.members.length; i++) {
				this.items.push({
					first_name: this.members[i].first_name,
					last_name: this.members[i].last_name,
					num_members: this.members[i].num_members,
					enabled: this.members[i].enabled,
					quantity: this.members[i].quantity,
					subscription: this.members[i].subscription,
					region: this.members[i].region,
					createdAt: this.members[i].createdAt,
					UUID: this.members[i].UUID,
				});
			}

			this.loadingData = false;
		},

		async generateFile() {
			this.loading = true;
			try{
				var response = await MembershipService.export_members_list();
				if(response.data){
					console.log('Response data: ', response.data);
					this.xlsx = response.data;
					console.log(this.xlsx, "xlsx");
				} else {
					console.error('Empty response data');
				} 
			}catch (error){
				console.error('Error:', error);
			} finally {
				this.loading = false
			}
		},

		padTo2Digits(num) {
			return num.toString().padStart(2, "0");
		},

		formatDate(d) {
			const date = new Date(d);
			return (
				[
					this.padTo2Digits(date.getDate()),
					this.padTo2Digits(date.getMonth() + 1),
					date.getFullYear(),
				].join("/") +
				" - " +
				[
					this.padTo2Digits(date.getHours()),
					this.padTo2Digits(date.getMinutes()),
				].join(":")
			);
		},

		formatCurrency(num) {
			// Create our number formatter.
			const formatter = new Intl.NumberFormat("es-ES", {
				style: "currency",
				currency: "EUR",

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});
			return formatter.format(num);
		},
	},
	created() {
		this.start();
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>
