<template>
	<div class="container-fluid">
		<div class="pt-5 mb-5">
			<h1
				class="page-heading d-flex flex-column justify-content-center text-dark fs-3 m-0"
			>
				Mi cuenta
			</h1>
		</div>
		<div class="card mb-5 mb-xl-10">
			<div class="card-header bg-white border-0">
				<div class="card-title mb-0">Información personal</div>
			</div>
			<div class="card-body border-top p-9">
				<form v-if="!loading" @submit.prevent.stop="saveInfoProfile()">
					<div class="row mb-6">
						<label
							class="col-lg-4 col-form-label required fw-semibold fs-6"
							>Nombre y Apellidos</label
						>
						<div class="col-lg-8">
							<div class="row">
								<div
									class="col-lg-6 fv-row fv-plugins-icon-container"
								>
									<input
										type="text"
										name="fname"
										class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
										placeholder="First name"
										v-model="user.first_name"
										required
									/>
									<div
										class="fv-plugins-message-container invalid-feedback"
									></div>
								</div>
								<div
									class="col-lg-6 fv-row fv-plugins-icon-container"
								>
									<input
										type="text"
										name="lname"
										class="form-control form-control-lg form-control-solid"
										placeholder="Last name"
										v-model="user.last_name"
										required
									/>
									<div
										class="fv-plugins-message-container invalid-feedback"
									></div>
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-6">
						<label
							class="col-lg-4 col-form-label required fw-semibold fs-6"
							>Teléfono</label
						>
						<div class="col-lg-8">
							<div class="row">
								<div
									class="col-lg-12 fv-row fv-plugins-icon-container"
								>
									<input
										type="text"
										name="lname"
										class="form-control form-control-lg form-control-solid"
										placeholder="Teléfono"
										v-model="user.telephone"
										required
									/>
									<div
										class="fv-plugins-message-container invalid-feedback"
									></div>
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-6">
						<label
							class="col-lg-4 col-form-label required fw-semibold fs-6"
							>País</label
						>
						<div class="col-lg-8">
							<div class="row">
								<div
									class="col-lg-12 fv-row fv-plugins-icon-container"
								>
									<select
										class="form-control form-control-lg form-control-solid"
										placeholder="País"
										v-model="user.country"
										required
									>
										<option
											v-for="item in countries"
											:value="item.id"
										>
											{{ item.name }}
										</option>
									</select>
									<div
										class="fv-plugins-message-container invalid-feedback"
									></div>
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-6">
						<label
							class="col-lg-4 col-form-label required fw-semibold fs-6"
							>Comunicación</label
						>
						<div class="col-lg-8">
							<div class="d-flex align-items-center mt-3">
								<!--begin::Option-->
								<label
									class="form-check form-check-custom form-check-inline form-check-solid me-5"
								>
									<input
										class="form-check-input"
										type="checkbox"
										v-model="user.communication_via_email"
									/>
									<span class="fw-semibold ps-2 fs-6">
										Email
									</span>
								</label>
								<!--end::Option-->

								<!--begin::Option-->
								<label
									class="form-check form-check-custom form-check-inline form-check-solid me-5"
								>
									<input
										class="form-check-input"
										type="checkbox"
										v-model="user.communication_via_phone"
									/>
									<span class="fw-semibold ps-2 fs-6">
										Teléfono
									</span>
								</label>
								<!--end::Option-->
							</div>
						</div>
					</div>

					<div class="row mb-0">
						<!--begin::Label-->
						<label class="col-lg-4 col-form-label fw-semibold fs-6"
							>Permitir Email Marketing</label
						>
						<!--begin::Label-->

						<!--begin::Label-->
						<div class="col-lg-8 d-flex align-items-center">
							<div
								class="form-check form-check-solid form-switch form-check-custom fv-row"
							>
								<input
									class="form-check-input w-45px h-30px"
									type="checkbox"
									id="allowmarketing"
									v-model="user.allow_marketing"
								/>
								<label
									class="form-check-label"
									for="allowmarketing"
								></label>
							</div>
						</div>
						<!--begin::Label-->
					</div>

					<div class="text-end mt-5">
						<button
							id="btnSaveInfoProfile"
							type="submit"
							class="btn btn-fbo"
						>
							Guardar cambios
						</button>
					</div>
				</form>
				<div v-else class="text-center py-5">
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
					<div>Cargando información</div>
				</div>
			</div>
		</div>

		<div class="card mb-5 mb-xl-10">
			<div class="card-header bg-white border-0">
				<div class="card-title mb-0">Inicio de sesión</div>
			</div>
			<div class="card-body border-top p-9">
				<!--begin::Email Address-->
				<div class="d-flex flex-wrap align-items-center">
					<!--begin::Label-->
					<div v-if="!changeEmail" id="kt_signin_email" class="">
						<div class="fs-6 fw-bold mb-1">Email</div>
						<div class="fw-semibold text-gray-600">
							{{ user.email }}
						</div>
					</div>
					<!--end::Label-->

					<!--begin::Edit-->
					<div
						id="kt_signin_email_edit"
						class="flex-row-fluid"
						style="min-width: 300px"
						v-if="changeEmail"
					>
						<!--begin::Form-->
						<form
							id="kt_signin_change_email"
							class="form fv-plugins-bootstrap5 fv-plugins-framework"
							novalidate="novalidate"
							@submit.prevent.stop="updateEmail()"
						>
							<div class="row mb-6 w-100">
								<div class="col-lg-12 mb-4 mb-lg-0">
									<div
										class="fv-row mb-0 fv-plugins-icon-container"
									>
										<label
											for="emailaddress"
											class="form-label fs-6 fw-bold mb-3"
											>Introduce nuevo email</label
										>
										<input
											type="email"
											class="form-control form-control-lg form-control-solid"
											id="emailaddress"
											placeholder="Email"
											name="emailaddress"
											v-model="user.email"
										/>
										<div
											class="fv-plugins-message-container invalid-feedback"
										></div>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<button
									id="btnUpdateEmail"
									type="submit"
									class="btn btn-fbo me-2 px-6"
								>
									Actualizar Email
								</button>
								<button
									id="kt_signin_cancel"
									type="button"
									class="btn btn-color-gray-400 btn-active-light-primary px-6"
									@click="changeEmailEvent(false)"
								>
									Cancelar
								</button>
							</div>
						</form>
						<!--end::Form-->
					</div>
					<!--end::Edit-->

					<!--begin::Action-->
					<div
						v-if="!changeEmail"
						id="kt_signin_email_button"
						class="ms-auto"
					>
						<button
							class="btn btn-light btn-active-light-primary"
							@click="changeEmailEvent(true)"
						>
							Cambiar email
						</button>
					</div>
					<!--end::Action-->
				</div>
				<!--end::Email Address-->

				<!--begin::Separator-->
				<div class="separator separator-dashed my-5"></div>
				<!--end::Separator-->

				<!--begin::Password-->
				<div class="d-flex flex-wrap align-items-center mb-3">
					<!--begin::Label-->
					<div
						v-if="!changePassword"
						id="kt_signin_password"
						class=""
					>
						<div class="fs-6 fw-bold mb-1">Password</div>
						<div class="fw-semibold text-gray-600">
							************
						</div>
					</div>
					<!--end::Label-->

					<!--begin::Edit-->
					<div
						id="kt_signin_password_edit"
						class="flex-row-fluid"
						v-if="changePassword"
					>
						<!--begin::Form-->
						<form
							id="kt_signin_change_password"
							class="form fv-plugins-bootstrap5 fv-plugins-framework"
							novalidate="novalidate"
							@submit.prevent.stop="changeUserPassword()"
						>
							<div class="row mb-1">
								<div class="col-lg-4">
									<div
										class="fv-row mb-0 fv-plugins-icon-container"
									>
										<label
											for="currentpassword"
											class="form-label fs-6 fw-bold mb-3"
											>Contraseña actual</label
										>
										<input
											type="password"
											class="form-control form-control-lg form-control-solid"
											name="currentpassword"
											id="currentpassword"
											v-model="password.old_password"
											required
										/>
										<div
											class="fv-plugins-message-container invalid-feedback"
										></div>
									</div>
								</div>

								<div class="col-lg-4">
									<div
										class="fv-row mb-0 fv-plugins-icon-container"
									>
										<label
											for="newpassword"
											class="form-label fs-6 fw-bold mb-3"
											>Nueva contraseña</label
										>
										<input
											type="password"
											class="form-control form-control-lg form-control-solid"
											name="newpassword"
											id="newpassword"
											v-model="password.password"
											required
										/>
										<div
											class="fv-plugins-message-container invalid-feedback"
										></div>
									</div>
								</div>

								<div class="col-lg-4">
									<div
										class="fv-row mb-0 fv-plugins-icon-container"
									>
										<label
											for="confirmpassword"
											class="form-label fs-6 fw-bold mb-3"
											>Confirmar nueva contarseña</label
										>
										<input
											type="password"
											class="form-control form-control-lg form-control-solid"
											name="confirmpassword"
											id="confirmpassword"
											v-model="password.password2"
											required
										/>
										<div
											class="fv-plugins-message-container invalid-feedback"
										></div>
									</div>
								</div>
							</div>

							<div class="form-text mb-5">
								La contraseña debe tener al menos 8 caracteres.
							</div>

							<div class="d-flex">
								<button
									id="btn_update_password"
									type="submit"
									class="btn btn-fbo me-2 px-6"
								>
									Actualizar contraseña
								</button>
								<button
									id="kt_password_cancel"
									type="button"
									class="btn btn-color-gray-400 btn-active-light-primary px-6"
									@click="changePasswordEvent(false)"
								>
									Cancelar
								</button>
							</div>
						</form>
						<!--end::Form-->
					</div>
					<!--end::Edit-->

					<!--begin::Action-->
					<div
						v-if="!changePassword"
						id="kt_signin_password_button"
						class="ms-auto"
					>
						<button
							class="btn btn-light btn-active-light-primary"
							@click="changePasswordEvent(true)"
						>
							Cambiar contraseña
						</button>
					</div>
					<!--end::Action-->
				</div>
				<!--end::Password-->
			</div>
		</div>
	</div>
</template>

<script>
import userRoles from "@/mixins/user-roles.js";
import UserService from "@/services/user.service";
import CountryService from "@/services/country.service";
import Swal from "sweetalert2";

export default {
	name: "ProfileView",
	mixins: [userRoles],
	data() {
		return {
			user: this.$store.state.user.data,
			countries: null,
			loading: true,
			changeEmail: false,
			changePassword: false,
			password: {
				old_password: null,
				password: null,
				password2: null,
			},
		};
	},
	methods: {
		async start() {
			this.loading = true;
			console.log(this.user, "Countries");
			await this.getCountries();
			this.loading = false;
		},

		async getCountries() {
			var response = await CountryService.getAllCountries();
			this.countries = response.data;
			// console.log(this.countries, "Countries");
		},

		async saveInfoProfile() {
			window.$("#btnSaveInfoProfile").attr("disabled");
			window.$("#btnSaveInfoProfile").empty();
			window
				.$("#btnSaveInfoProfile")
				.append(
					'<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
				);

			var response = await UserService.updateUser(this.user.UUID, {
				first_name: this.user.first_name,
				last_name: this.user.last_name,
				email: this.user.email,
				telephone: this.user.telephone,
				country: this.user.country,
				allow_marketing: this.user.allow_marketing,
				communication_via_email: this.user.communication_via_email,
				communication_via_phone: this.user.communication_via_phone,
			});

			if (response.status == 200) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: "Se ha guardado correctamente.",
					showConfirmButton: false,
					timer: 1500,
					backdrop: false,
					toast: true,
				});

				// Actualizamos el store user
				this.$store.dispatch("user/updateUser");
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error: " + response.data.message,
					confirmButtonColor: "#0091ff",
				});
			}
			window.$("#btnSaveInfoProfile").empty();
			window.$("#btnSaveInfoProfile").append("Guardar cambios");
			window.$("#btnSaveInfoProfile").removeAttr("disabled");
		},

		changeEmailEvent(boolean) {
			this.changeEmail = boolean;
		},

		changePasswordEvent(boolean) {
			this.changePassword = boolean;
		},

		async updateEmail() {
			window.$("#btnUpdateEmail").attr("disabled");
			window.$("#btnUpdateEmail").empty();
			window
				.$("#btnUpdateEmail")
				.append(
					'<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
				);

			var response = await UserService.updateEmail(this.user.UUID, {
				email: this.user.email,
			});

			if (response.status == 200) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: "Se ha guardado correctamente.",
					showConfirmButton: false,
					timer: 1500,
					backdrop: false,
					toast: true,
				});

				// Actualizamos el store user
				this.$store.dispatch("user/updateUser");
				this.changeEmailEvent(false);
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error: " + response.data.email,
					confirmButtonColor: "#0091ff",
				});
			}
			window.$("#btnUpdateEmail").empty();
			window.$("#btnUpdateEmail").append("Actualizar Email");
			window.$("#btnUpdateEmail").removeAttr("disabled");
		},

		async changeUserPassword() {
			window.$("#btn_update_password").attr("disabled");
			window.$("#btn_update_password").empty();
			window
				.$("#btn_update_password")
				.append(
					'<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
				);

			var response = await UserService.updatePassword(this.user.UUID, {
				old_password: this.password.old_password,
				password: this.password.password,
				password2: this.password.password2,
			});

			if (response.status == 200) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: "Se ha guardado correctamente.",
					showConfirmButton: false,
					timer: 1500,
					backdrop: false,
					toast: true,
				});

				this.changePasswordEvent(false);
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text:
						"Ha ocurrido un error: " +
						JSON.stringify(response.data),
					confirmButtonColor: "#0091ff",
				});
			}
			window.$("#btn_update_password").empty();
			window.$("#btn_update_password").append("Actualizar Email");
			window.$("#btn_update_password").removeAttr("disabled");
		},
	},

	created() {
		this.start();
	},
};
</script>
