<template>
  <td>
    <div>{{ course.title }}</div>
  </td>
  <td class="text-center">
    <div v-if="course.isOnline">
      <span class="badge bg-primary">Online</span>
    </div>
    <div v-else><span class="badge bg-success">Presencial</span></div>
  </td>
  <td class="text-center">
    <router-link
      class="btn btn-sm btn-outline-dark m-1"
      :key="course"
      :to="{ name: 'SingleCourseView', params: { courseUUID: course.UUID } }"
      ><i class="fas fa-eye"></i
    ></router-link>
    <button class="btn btn-sm btn-outline-danger m-1" @click="deleteCourse()">
      <i class="fas fa-trash"></i>
    </button>
  </td>

</template>

<script>
import CourseService from "../../services/course.service";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Swal from "sweetalert2";

export default {
  name: "CourseRow",
  components: {
    QuillEditor,
  },
  props: {
    course: Object,
  },
  data() {
    return {
      id: this.course.UUID,
    };
  },
  methods: {
    async deleteCourse() {
      let response = await CourseService.deleteCourse(this.course.UUID);
      if (response.status == 200 || response.status == 204) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha borrado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
      }
      // this.$router.go()
    },
  },
};
</script>
