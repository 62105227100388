<template>
	<!-- Content Wrapper -->
	<div id="content-wrapper" class="d-flex flex-column">
		<!-- Main Content -->
		<div id="content">
			<div>
				<Navbar v-if="this.currentUser" />
				<NavbarPublic v-else />
				<!-- <Sidebar v-if="this.currentUser" /> -->
				<router-view />
			</div>
			<Footer />
		</div>
	</div>
</template>

<script>
import EventBus from "./common/EventBus";
import Navbar from "./components/Layout/Navbar.vue";
import NavbarPublic from "./components/Layout/NavbarPublic.vue";
import Sidebar from "./components/Layout/Sidebar.vue";
import Footer from "./components/Layout/Footer.vue";

export default {
	components: {
		Navbar,
		NavbarPublic,
		Sidebar,
		Footer,
	},
	computed: {
		currentUser() {
			return this.$store.state.user.data;
		},
	},
	methods: {},
	mounted() {
		EventBus.on("logout", () => {
			this.logOut();
		});
	},
	beforeUnmount() {
		EventBus.remove("logout");
	},
};
</script>

<style>
@import "./assets/css/main.css";
</style>
