<template>
	<div
		class="h-100 bg-white"
		style="
			min-height: 100vh;
			background: linear-gradient(270deg, #ff9b04, #ff5f00);
			background-image: url(https://fundacionbertinosborne.org/wp-content/uploads/2022/03/FBO-Home-Slider-OK6.jpg);
			background-position: center;
			min-height: 100vh;
		"
	>
		<div class="container">
			<div
				class="row h-100 align-items-center justify-content-center align-content-center"
				style="min-height: 100vh"
			>
				<div class="card w-100 py-3 col-md-6 bg-white">
					<div class="card-body">
						<div class="mb-5 text-center">
							<h4>Crea una nueva contraseña</h4>
							<p class="text-muted">
								Socios Fundación Bertín Osborne
							</p>
						</div>
						<form @submit.prevent.stop="handleResetPassword">
							<div class="mb-3">
								<label class="form-label"
									>Nueva Contraseña</label
								>
								<input
									type="password"
									class="form-control"
									v-model="password"
									placeholder="Nueva contraseña"
								/>
							</div>

							<div class="mb-3">
								<label class="form-label"
									>Confirma la contraseña</label
								>
								<input
									type="password"
									class="form-control"
									v-model="password_confirm"
									placeholder="Confirma la contraseña"
								/>
							</div>

							<div class="form-group mt-4 text-center">
								<button
									class="btn btn-fbo px-5 py-3"
									:disabled="loading"
								>
									<span
										v-show="loading"
										class="spinner-border spinner-border-sm me-2"
									></span>
									<span>Restablecer contraseña</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ResetPasswordService from "@/services/reset-password.service";
export default {
	name: "ResetPassword",
	data() {
		return {
			password: "",
			password_confirm: "",
			token: null,
		};
	},
	methods: {
		async handleResetPassword() {
			var response = await ResetPasswordService.resetPasswordConfirm({
				password: this.password,
				password_confirm: this.password_confirm,
				token: this.token,
			});

			if (response.status == 200) {
				this.$router.push("/login");
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error: " + response.data.message,
					confirmButtonColor: "#0091ff",
				});
			}
		},
	},
	mounted() {
		this.token = this.$route.query.token;
	},
};
</script>