<template>
	<div class="container-fluid pt-5">
		<div class="row">
			<div class="col-lg-3 col-md-4">
				<NavbarMembership />
			</div>
			<div class="col-lg-9 col-md-8">
				<div v-if="!loading" class="col-12">
					<div class="card mb-5">
						<div class="card-body">
							<h6 class="font-weight-bold">
								Remesa: {{ remittance.description }}
							</h6>
							<div class="table-responsive">
								<table class="table table-hovered">
									<thead>
										<tr>
											<th>
												<small> Referencia</small>
											</th>
											<th>
												<small>Estado</small>
											</th>
											<th>
												<small>Cantidad</small>
											</th>
											<th>
												<small>Fecha del pago</small>
											</th>
											<th>
												<small>Socio</small>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											:key="item"
											v-for="item in payment_orders"
										>
											<td>
												<small v-if="item.Order">
													{{ item.Order }}</small
												>
												<small v-else>
													{{
														"#" + item.payment_id
													}}</small
												>
											</td>
											<td>
												<span
													v-if="item.Response == 'OK'"
													class="badge badge-light-success fs-7"
													>Confirmado</span
												>
												<span
													v-else-if="
														item.Response == 'KO'
													"
													class="badge badge-light-danger fs-7"
													>Error en el pago<br /><small
														>{{
															item.ErrorDescription
														}}</small
													></span
												>
												<span
													v-else
													class="badge badge-light-info fs-7"
													>Error</span
												>
											</td>
											<td>
												<small v-if="item.Amount">{{
													formatCurrency(
														item.Amount / 100
													)
												}}</small>
												<small v-else> - </small>
											</td>
											<td>
												<small
													v-if="item.BankDateTime"
													>{{
														item.BankDateTime.substring(
															6,
															8
														) +
														"/" +
														item.BankDateTime.substring(
															4,
															6
														) +
														"/" +
														item.BankDateTime.substring(
															0,
															4
														) +
														" - " +
														item.BankDateTime.substring(
															8,
															10
														) +
														":" +
														item.BankDateTime.substring(
															10,
															12
														)
													}}</small
												>
												<small
													v-else
													class="text-center"
													>{{
														formatDate(
															item.updatedAt
														)
													}}</small
												>
											</td>
											<td>
												<router-link
													class="btn btn-fbo"
													:to="
														'/membership/members/' +
														item.membership
													"
												>
													Ver socio
												</router-link>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="text-center py-5">
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
					<div>Cargando información</div>
				</div>
			</div>
		</div>
	</div>
	<router-view></router-view>
</template>

<script>
// import mixin
import userRoles from "@/mixins/user-roles.js";

import NavbarMembership from "@/components/Layout/NavbarMembership.vue";

import ClipBoard from "@/components/Global/ClipBoard.vue";

import MembershipService from "@/services/membership.service.js";

import Swal from "sweetalert2";

export default {
	name: "SingleRemittanceView",
	mixins: [userRoles],
	components: {
		NavbarMembership,
		ClipBoard,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	data() {
		return {
			remittanceUUID: this.$route.params.remittanceUUID,
			loading: true,
			remittance: null,
			payment_orders: null,
		};
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getSingleRemittance();
			await this.getRemittancePaymentOrders();
			this.loading = false;
		},

		async getSingleRemittance() {
			var response = await MembershipService.getSingleRemittance(
				this.remittanceUUID
			);
			this.remittance = response.data;
			console.log(this.remittance, "Remesa");
		},

		async getRemittancePaymentOrders() {
			var response = await MembershipService.getRemittancePaymentOrders(
				this.remittanceUUID
			);
			this.payment_orders = response.data;
			console.log(this.payment_orders, "payment_orders");
		},

		formatDate(date) {
			// Create a date object from a date string
			var date = new Date(date);

			// Get year, month, and day part from the date
			var year = date.toLocaleString("default", { year: "numeric" });
			var month = date.toLocaleString("default", { month: "2-digit" });
			var day = date.toLocaleString("default", { day: "2-digit" });

			// Generate yyyy-mm-dd date string
			var formattedDate = year + "-" + month + "-" + day;
			console.log(formattedDate); // Prints: 2022-05-04
			return formattedDate;
		},

		formatCurrency(num) {
			// Create our number formatter.
			const formatter = new Intl.NumberFormat("es-ES", {
				style: "currency",
				currency: "EUR",

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});
			return formatter.format(num);
		},
	},
	created() {
		this.start();
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>
