<template>
  <div>
    <div class="card shadow-sm border-0">
      <div class="card-header bg-white">
        <div class="d-flex justify-content-between align-items-center">
          <h5>Categorías</h5>
          <AddCategory />
        </div>
      </div>
      <div class="card-body">
        <div v-if="!loading" class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="bg-dark text-white">
              <tr class="">
                <th>Título</th>
                <th>Temática</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr class="" :key="item" v-for="item in categories">
                <CategoryRow :category="item"></CategoryRow>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center py-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div>Cargando información</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddCategory from "@/components/Blog/elements/Categories/AddCategory.vue";
import CategoryRow from "@/components/Blog/elements/Categories/CategoryRow.vue";

import CategoryService from "@/services/category.service.js";

export default {
  name: "Categories",
  components: {
    AddCategory: AddCategory,
    CategoryRow: CategoryRow,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      categories: [],
      loading: true,
    };
  },
  methods: {
    async start() {
      this.loading = true;
      await this.getCategories();
      this.loading = false;
    },
    async getCategories() {
      var response = await CategoryService.getAllCategories();
      this.categories = response.data;
      // console.log(this.categories, "Categories")
    },
  },
  created() {
    this.start();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
