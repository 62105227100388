<template>
  <div>
    <div class="card shadow-sm border-0 mb-3">
      <div class="card-header bg-white">
        <div class="d-flex justify-content-between align-items-center">
          <h5>Filtrar artículos</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <label>Temática</label>
              <select
                class="form-control"
                v-model="filters.topic"
                @change="applyFilters('topic')"
              >
                <option value="all">Todos</option>
                <option
                  :key="item.id"
                  v-for="item in topics"
                  :value="item.UUID"
                >
                   {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <label>Categoría</label>
              <select
                class="form-control"
                v-model="filters.category"
                @change="applyFilters('category')"
              >
                <option value="all">Todos</option>
                <option
                  :key="item.id"
                  v-for="item in categories"
                  :value="item.UUID"
                >
                   {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <label>Subcategoría</label>
              <select
                class="form-control"
                v-model="filters.subcategory"
                @change="applyFilters()"
              >
                <option value="all">Todos</option>
                <option
                  :key="item.id"
                  v-for="item in subcategories"
                  :value="item.UUID"
                >
                   {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card shadow-sm border-0">
      <div class="card-header bg-white">
        <div class="d-flex justify-content-between align-items-center">
          <h5>Artículos</h5>
          <AddArticle :tags="tags" :categories="categories" />
        </div>
      </div>
      <div class="card-body">
        <div v-if="!loading" class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="bg-dark text-white">
              <tr class="">
                <th>Título</th>
                <th>Temática</th>
                <th>Categoría</th>
                <th>Subcategoría</th>
                <th>Estado</th>
                <th>Fecha creación</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr class="align-middle" :key="item" v-for="item in articles">
                <ArticleRow
                  :article="item"
                  :tags="tags"
                  :topics="topics"
                ></ArticleRow>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center py-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div>Cargando información</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddArticle from "@/components/Blog/elements/Articles/AddArticle.vue";
import ArticleRow from "@/components/Blog/elements/Articles/ArticleRow.vue";

import ArticleService from "@/services/article.service.js";
import CategoryService from "@/services/category.service";
import SubCategoryService from "@/services/subcategory.service";
import TagService from "@/services/tag.service";
import TopicService from "@/services/topic.service";

export default {
  name: "Articles",
  components: {
    AddArticle: AddArticle,
    ArticleRow: ArticleRow,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      articles: [],
      tags: [],
      categories: [],
      subcategories: [],
      topics: [],
      filters: {
        topic: "all",
        category: "all",
        subcategory: "all",
      },
      loading: true,
    };
  },
  methods: {
    async start() {
      this.loading = true;
      if (localStorage.getItem("filters_topic") != null) {
        this.filters.topic = localStorage.getItem("filters_topic");
      }
      if (localStorage.getItem("filters_category") != null) {
        this.filters.category = localStorage.getItem("filters_category");
      }
      if (localStorage.getItem("filters_subcategory") != null) {
        this.filters.subcategory = localStorage.getItem("filters_subcategory");
      }
      await this.getTopics();
      await this.getTags();
      await this.applyFilters();
      this.loading = false;
    },

    async getArticles(params) {
      var response = await ArticleService.getAllArticles(params);
      this.articles = [];
      this.articles = response.data;
      // console.log(this.articles, "this.articles")
    },
    async getTags() {
      this.tags = await TagService.getAllTags();
      this.tags = this.tags.data;
    },
    async getCategories(params) {
      var response = await CategoryService.getAllCategories(params);
      this.categories = [];
      this.categories = response.data;
    },
    async getSubCategories(params) {
      var response = await SubCategoryService.getAllSubCategories(params);
      this.subcategories = [];
      this.subcategories = response.data;
    },
    async getTopics() {
      var response = await TopicService.getAllTopics();
      this.topics = response.data;
    },

    async applyFilters(key = null) {
      this.loading = true;

      if (key == "topic") {
        this.categories = [];
        this.filters.category = "all";

        this.subcategories = [];
        this.filters.subcategory = "all";
      } else if (key == "category") {
        this.subcategories = [];
        this.filters.subcategory = "all";
      }

      if (
        this.filters.topic != "all" &&
        this.filters.category != "all" &&
        this.filters.subcategory != "all"
      ) {
        await this.getArticles({
          topic: this.filters.topic,
          category: this.filters.category,
          subcategory: this.filters.subcategory,
        });
      } else if (
        this.filters.topic != "all" &&
        this.filters.category != "all"
      ) {
        await this.getCategories({
          topic: this.filters.topic,
        });
        await this.getSubCategories({
          topic: this.filters.topic,
          category: this.filters.category,
        });

        await this.getArticles({
          topic: this.filters.topic,
          category: this.filters.category,
        });
      } else if (this.filters.topic != "all") {
        await this.getCategories({
          topic: this.filters.topic,
        });
        this.subcategories = [];
        this.filters.subcategory = "all";

        await this.getArticles({
          topic: this.filters.topic,
        });
      } else {
        this.topic = [];
        this.filters.topic = "all";

        this.categories = [];
        this.filters.category = "all";

        this.subcategories = [];
        this.filters.subcategory = "all";

        await this.getArticles();
      }

      localStorage.setItem("filters_topic", this.filters.topic);
      localStorage.setItem("filters_category", this.filters.category);
      localStorage.setItem("filters_subcategory", this.filters.subcategory);

      this.loading = false;
    },
  },
  created() {
    this.start();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
