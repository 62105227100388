<template>
  <div class="container py-5 text-black">
    <h1 class="text-uppercase mb-4">
      Términos y condiciones de la aplicación para socios
    </h1>
    <p class="mb-4">
      Las presentes condiciones regulan, por una parte, las condiciones de uso,
      acceso y registro (en adelante, las “Condiciones de Uso”) en el sitio web
      o en la aplicación móvil https://socios.fbofamily.app/ (en adelante, la
      “Plataforma”), por parte de cualquier persona que acceda a las mismas (en
      adelante, el “Usuario” o los “Usuarios”), así como las condiciones de
      suscripción y donación de los servicios que se ofertan en la Plataforma
      por parte de los Usuarios o socios (en adelante, las “Condiciones de
      Donación”). En adelante, podrá denominarse conjuntamente a las Condiciones
      de Uso, a las Condiciones de Donación y a las Condiciones de Contratación
      como las “Condiciones”.
    </p>
    <h2>Previo - IDENTIFICACIÓN</h2>
    <ul>
      <li class="m-2">
        Titular: Fundación Bertín Osborne (en adelante, la “Fundación” o el
        “Responsable”)
      </li>
      <li class="m-2">
        Domicilio social: Carretera de Torreblanca a Mairena del Alcor, Hacienda
        de San José, Alcalá de Guadaira, C.P. 41500 Sevilla.
      </li>
      <li class="m-2">N.I.F.: G-91838888</li>
      <li class="m-2">Registro Público: 1234 SND</li>
      <li class="m-2">E-mail: info@fundacionbertinosborne.org</li>
      <li class="m-2">Teléfono: […]</li>
    </ul>

    <h2>SECCIÓN PRIMERA. CONDICIONES DE USO</h2>
    <p>
      Mediante la aceptación de las presentes Condiciones de Uso, el Usuario
      expresamente admite obligarse en toda su extensión y alcance, sin
      exceptuar cualquiera de sus disposiciones.
    </p>
    <ol class="list-group list-group-flush">
      <li class="list-group-item">
        <h4>CONDICIONES DE ACCESO Y USO DE LA PLATAFORMA</h4>
        <p>
          El Usuario podrá navegar y visualizar el contenido, así como los
          productos ofrecidos en la Plataforma, de manera libre y gratuita. El
          acceso a la Plataforma tiene carácter gratuito para los Usuarios del
          mismo.
        </p>
        <p>
          El Usuario podrá solicitar la adquisición de los productos en la
          Plataforma a través del formulario correspondiente habilitado en la
          Plataforma.
        </p>
        <p>
          Al navegar, utilizar o visualizar los contenidos de la Plataforma, el
          Usuario declara que es mayor de dieciocho (18) años o, en su defecto,
          que cuenta con la debida autorización de sus tutores legales.
        </p>
        <p>
          La Fundación se reserva la facultad de modificar, suspender, cancelar
          o restringir el contenido o los servicios de la Plataforma, sus
          vínculos y la información obtenida a través de él, sin necesidad de
          previo aviso.
        </p>
      </li>
      <li class="list-group-item">
        <h4>DURACIÓN</h4>

        <p>
          Las presentes Condiciones de Uso estarán en vigor mientras estén
          publicadas en la Plataforma y vincularán a cualquier Usuario que
          acceda al mismo durante el tiempo que estuviera navegando por la
          Plataforma.
        </p>
        <p>
          La Fundación, no obstante, está autorizada para dar por terminado o
          suspender el acceso a la Plataforma en cualquier momento. En todo
          caso, la Fundación cumplirá con la entrega de Pedidos confirmados en
          curso.
        </p>
        <p>
          Cuando ello sea razonablemente posible, la Fundación advertirá, con la
          mayor antelación posible, la terminación o suspensión de la prestación
          del servicio de la Plataforma.
        </p>
      </li>
      <li class="list-group-item">
        <h4>LIMITACIÓN DE LA RESPONSABILIDAD</h4>
        <p>
          El Usuario exonera a la Fundación de cualquier responsabilidad que
          pudiera derivarse de las interrupciones de disponibilidad o errores de
          funcionamiento de la Plataforma, por fallos en el mismo.
        </p>
        <p>
          Asimismo, la Fundación no tendrá el deber de controlar la utilización
          de los contenidos o servicios, su identidad o la exactitud o veracidad
          de los datos o informaciones que facilites, más allá de las
          obligaciones legalmente establecidas.
        </p>
        <p>
          La Fundación tiene el derecho a denegar discrecionalmente a cualquier
          Usuario el acceso a esta Plataforma si existen indicios de utilización
          del mismo en contra de las presentes Condiciones de Uso. La limitación
          del acceso se podrá realizar sin necesidad de advertencia previa y sin
          que la Fundación asuma responsabilidad alguna por dicha decisión.
        </p>
        <p>
          La Fundación no será responsable de las opiniones vertidas por los
          Usuarios a través de los foros, chats, u otras herramientas de
          participación.
        </p>
        <p>
          La conexión a Internet necesaria para acceder a la Plataforma no es en
          ningún caso suministrada por la Fundación y es responsabilidad del
          Usuario. Es responsabilidad del Usuario adoptar todas las medidas
          técnicas adecuadas para controlar razonablemente estos riesgos y
          evitar daños en sus equipos, pérdida de datos y robo de información
          confidencial. A estos efectos, debe tener sistemas actualizados de
          detección de software malicioso, tales como virus, troyanos, etc., así
          como tener actualizados los parches de seguridad de los
          correspondientes navegadores que utilice. Para más información puede
          acudir a su Proveedor de Servicios de Acceso a Internet que podrá
          facilitarle más información.
        </p>
        <p>
          La Fundación no se hace responsable y excluye su responsabilidad, con
          toda la extensión permitida por el ordenamiento jurídico, de los
          perjuicios que se pudieran derivar de, con carácter meramente
          enunciativo y no limitativo (i) inferencias, omisiones,
          interrupciones, virus informáticos, averías y/o desconexiones en el
          funcionamiento operativo de este sistema electrónico o en los aparatos
          y equipos informáticos de los Usuarios motivadas por causas ajenas a
          la Fundación que impidan o retrasen la prestación de los Servicios y
          Contenidos o la navegación por la Plataforma; (ii) retrasos o bloqueos
          en el uso causados por deficiencias o sobrecargas de Internet o en
          otros sistemas electrónicos; (iii) que puedan ser causados por
          terceras personas mediante intromisiones ilegítimas fuera del control
          de la Plataforma y que no sean atribuibles a ésta.
        </p>
      </li>
      <li class="list-group-item">
        <h4>REGLAS DE USO DE LA PLATAFORMA</h4>
        <p>
          No está permitido y, por tanto, sus consecuencias serán de la
          exclusiva responsabilidad del Usuario, el acceso o la utilización de
          la Plataforma con fines ilegales o no autorizados, con o sin ánimo de
          lucro. El particular, y sin que el siguiente listado tenga carácter
          limitativo, queda prohibido:
        </p>
        <ul>
          <li>
            Usar la Plataforma en forma alguna que pueda provocar daños,
            interrupciones, ineficiencias o defectos en su funcionamiento o en
            los equipos informáticos de un tercero;
          </li>
          <li>
            Usar la Plataforma para la transmisión, instalación o publicación de
            cualquier virus, código malicioso u otros programas o archivos
            perjudiciales;
          </li>
          <li>
            Usar la Plataforma para recoger datos de carácter personal de otros
            Usuarios;
          </li>
          <li>
            Usar la Plataforma de forma ilegal, en contra de la buena fe, la
            moral y el orden público;
          </li>
          <li>
            Registrarse a través de la Plataforma con una identidad falsa,
            suplantando a terceros o utilizando un perfil o realizando cualquier
            otra acción que pueda confundir a otros Usuarios sobre la identidad
            del Usuario;
          </li>
          <li>
            Acceder sin autorización a cualquier sección de la Plataforma, a
            otros sistemas o redes conectados a las mismas, a los servidores de
            la Fundación o a los servicios ofrecidos a través de la Plataforma
            por medio de pirateo o falsificación, extracción de contraseñas o
            cualquier otro medio ilegítimo;
          </li>
          <li>
            Quebrantar, o intentar quebrantar, las medidas de seguridad o
            autenticación de la Plataforma o de cualquier red conectada a las
            mismas, o a las medidas de seguridad o protección inherentes a los
            contenidos ofrecidos en la Plataforma;
          </li>
          <li>
            Llevar a cabo alguna acción que provoque una saturación
            desproporcionada o innecesaria en la infraestructura de la
            Plataforma, en los sistemas o redes de la Fundación, así como en los
            sistemas y redes conectadas a la Plataforma;
          </li>
          <li>
            Impedir el normal desarrollo de un servicio, promoción o cualquier
            otra actividad disponible a través de la Plataforma o cualesquiera
            de sus funcionalidades, ya sea alterando o tratando de alterar,
            ilegalmente o de cualquier otra forma, el acceso o el funcionamiento
            de aquéllos, o falseando el resultado de éstos y/o utilizando
            métodos de participación fraudulentos, mediante cualquier
            procedimiento y/o a través de cualquier práctica que atente o
            vulnere las presentes Condiciones de Uso.
          </li>
        </ul>
      </li>
      <li class="list-group-item">
        <h4>REGISTRO COMO USUARIO DE LA PLATAFORMA</h4>
        <p>
          Para acceder al área privada de la Plataforma, será necesario que el
          Usuario se registre previamente completando el formulario de registro
          proporcionado por el administrador de la Fundación. Este registro
          requiero todos los datos obligatorios, tales como nombre y apellido,
          D.N.I, domicilio, código postal, teléfono de contacto y correo
          electrónico.
        </p>
        <p>
          Una vez que el Usuario haya proporcionado todos los datos obligatorios
          de manera correcta, se le enviará un enlace que le permitirá acceder a
          la Plataforma. Para ingresar al área privada. El Usuario deberá
          ingresar su usuario y contraseña y presionar el botón “iniciar
          sesión'".
        </p>
        <p>
          Es importante destacar que el acceso al área privada de la aplicación
          será concedido por el administrador y en la medida en que el Usuario
          cumpla con las condiciones establecidas en las presentes Condiciones
          de Uso.
        </p>
        <p>
          El Usuario asume que la contraseña y la cuenta de usuario son
          personales e intransferibles.
        </p>
        <p>
          El Usuario podrá modificar su contraseña en cualquier momento,
          mediante el correo electrónico expuesto a disposición por la Fundación
          donde se podrá modificar y adaptar a la solicitud del Usuario.
        </p>
        <p>
          El Usuario se compromete a hacer un uso diligente de su contraseña y
          mantenerla en secreto, no pudiendo transmitirla a ningún tercero. En
          consecuencia, los Usuarios serán responsables de la adecuada custodia
          y confidencialidad de cualesquiera identificadores y/o contraseñas que
          hayan seleccionado como Usuarios registrados, y se comprometen a no
          ceder su uso a terceros, ya sea temporal o permanente, ni permitir el
          acceso de terceros a su cuenta. El Usuario será responsable de la
          utilización ilícita de su cuenta, respondiendo por cualesquiera daños
          generados por un tercero que acceda a la misma utilizando sus
          credenciales de acceso.
        </p>
        <p>
          En virtud de lo anterior, es obligación del Usuario notificar de forma
          inmediata a la Fundación acerca de cualquier hecho que permita el uso
          indebido de los identificadores y/o contraseñas, tales como el robo,
          extravío o el acceso no autorizado a los mismos, con el fin de
          proceder a su inmediata cancelación. Mientras no se comuniquen tales
          hechos, la Fundación quedará eximido de cualquier responsabilidad que
          pudiera derivarse del uso indebido de los identificadores o
          contraseñas por terceros no autorizados.
        </p>
        <p>
          Una vez dentro del área privada de la Plataforma, el Usuario podrá
          domiciliar el pago de su donación a través de una plataforma segura y
          confiable, denominada Paycomet. Los pagos realizados a través de esta
          plataforma se gestionarán de forma segura y confidencial, garantizando
          la protección de los datos del Usuario y cumpliendo con los estándares
          de seguridad establecidos por la normativa aplicable.
        </p>
        <p>
          Es importante destacar que la Fundación no tendrá acceso a los datos
          de pago del Usuario, los cuales serán gestionados exclusivamente por
          la plataforma de pago Paycomet. La Fundación se reserva el derecho de
          modificar o sustituir la plataforma de pago en cualquier momento,
          previa notificación al Usuario en caso de que esto fuera necesario.
        </p>
        <p>
          El Usuario será el único responsable de la exactitud de los datos
          proporcionados para el domicilio de los pagos y de informar
          oportunamente a la Fundación de cualquier cambio en la información
          proporcionada. La Fundación no será responsable por ningún retraso o
          error en el pago de las donaciones derivado de la falta de información
          o de la exactitud de los datos proporcionados por el Usuario.
        </p>
        <h4>Duración</h4>
        <p>
          El acceso como Usuario registrado comenzará una vez introducidos los
          datos requeridos para efectuar el registro, tras la aceptación de la
          Política de Privacidad y las presentes Condiciones de Uso.
        </p>
        <p>
          El acceso como Usuario permanecerá vigente de manera ilimitada hasta
          que el mismo notifique a la Fundación su baja como Usuario al
          siguiente correo electrónico: socios@fundacionbertinosborne.org
        </p>
      </li>
      <li class="list-group-item">
        <h4>FUNCIONALIDAD DEL ÁREA PRIVADA DE LA PLATAFORMA</h4>
        <p>
          Una vez obtenido el acceso al área privada de la Plataforma, el
          Usuario podrá visualizar y tener acceso a ciertos contenidos ofrecidos
          por la Fundación, que permiten:
        </p>
        <ol>
          <li>
            Visualización del objetivo de la Fundación y mensajes relacionados
            con el mismo;
          </li>
          <li>
            Acceso a información sobre los equipos y su historial de donaciones,
            incluyendo información detallada sobre las donaciones mensuales y
            anuales, así como información sobre los comerciales y socios que
            pertenecen a la Fundación;
          </li>
          <li>
            Visualización del tipo de usuario, tipo de suscripción, fecha de
            alta, estado, provincia y cantidad de donación aportada.
          </li>
          <li>Posibilidad de añadir comerciales o socios a la Fundación;</li>
          <li>Posibilidad de contacto con el equipo de la Fundación.</li>
        </ol>
        <p>
          Cabe destacar que la Fundación se reserva el derecho de modificar,
          actualizar o eliminar cualquier contenido o funcionalidad del área
          privada en cualquier momento y sin previo aviso al Usuario. Asimismo,
          el Usuario se compromete a utilizar la información y funcionalidades
          proporcionadas en el área privada únicamente para los fines previstos
          y de acuerdo con las presentes Condiciones de Donación.
        </p>
      </li>
      <li class="list-group-item">
        <h4>BAJA DE LA PLATAFORMA</h4>
        <p>
          El Usuario podrá darse de baja cuando lo desee, mediante comunicación
          expresa a socios@fundacionbertinosborne.org indicando su nombre de
          Usuario. Una vez solicitada, la Fundación enviará al Usuario una
          confirmación del proceso de baja.
        </p>
        <p>
          En el momento en que la baja del se haga efectiva, todas las
          funcionalidades del mismo dejarán de estar disponibles.
        </p>
        <p>
          El Usuario podrá solicitar un nuevo registro, pudiendo la Fundación
          inadmitir dicho registro en los casos en los que se contravenga lo
          dispuesto en las presentes Condiciones y/o en la Política de
          Privacidad. En todo caso, la Fundación podrá inadmitir el registro en
          caso de existir conflictos o controversias no resueltas, así como
          culpa o negligencia del Usuario.
        </p>
      </li>
    </ol>
    <h2>SECCIÓN SEGUNDA. CONDICIONES DE DONACIÓN</h2>
    <p>
      Las presentes Condiciones de Donación regular la donación por parte de los
      Usuarios de la Fundación a través la Plataforma.
    </p>
    <p>
      Antes de solicitar hacer una donación para la Fundación, el Usuario tiene
      la obligación de leer y aceptar las presentes Condiciones de Donación.
      Mediante dicha aceptación, el Usuario expresamente admite obligarse en
      toda su extensión y alcance, sin exceptuar cualquiera de sus
      disposiciones.
    </p>
    <ol class="list-group list-group-flush">
      <li class="list-group-item">
        <h4>CONDICIÓN DE LA DONACIÓN</h4>
        <p>
          El Usuario podrá acceder a la Plataforma y solicitar hacerse socio de
          la Fundación. A partir de ese momento la suscripción se renovará
          automáticamente cada mes hasta que el Usuario decida darse de baja.
        </p>
        <p>
          Para ello, el Usuario deberá seleccionar si se trata de un particular,
          así como la cantidad que va a donar, su dirección, código, postal,
          correo electrónico y número de teléfono. Además, para verificar que
          quien hace la solicitud es una persona física, la Fundación le pedirá
          una comprobación al respecto.
        </p>
        <p>
          La vigencia temporal de las presentes Condiciones de Donación coincide
          con el tiempo de su exposición, hasta que sea modificado total o
          parcialmente, momento en el cual pasarán a tener vigencia las nuevas
          Condiciones de Donación modificadas.
        </p>
      </li>
      <li class="list-group-item">
        <h4>IMPORTE DE LA DONACIÓN</h4>
        <p>
          El importe mínimo de la donación que puede realizar el Usuario no
          podrá ser inferior a dos (2) euros mensuales, a través de la
          suscripción en la Plataforma. Antes de realizar el pago
          correspondiente, se informará al Usuario sobre el importe de la
          donación, desglosando los diferentes conceptos e impuestos aplicables.
        </p>
        <p>
          En todo caso, al momento de efectuar la suscripción, se mostrará al
          Usuario el importe final de la donación que haya elegido, el cual no
          podrá ser inferior a dos (2) euros mensuales, de acuerdo con las
          presentes Condiciones de Donación. Una vez confirmada la suscripción,
          se entregará al Usuario una copia de dichas Condiciones y se renovarán
          mensualmente si no se lleva a cabo el desistimiento a la suscripción,
          de la manera que se expondrán en las presentes Condiciones.
        </p>
        <p>
          El Usuario se compromete a proporcionar datos verdaderos en el momento
          del registro, siendo responsable de los daños y perjuicios que pudiera
          ocasionar por la introducción de datos falsos o inexactos.
        </p>
        <p>
          Una vez realizado el pago, el Usuario recibirá la factura
          correspondiente la dirección de correo electrónico que haya facilitado
          en el formulario correspondiente.
        </p>
        <p>
          Es importante destacar que el Usuario deberá cumplir con las
          condiciones de suscripción establecidas en las presentes Condiciones
          de Donación para mantener su suscripción mensual en la Plataforma. En
          caso de incumplimiento, la Fundación se reserva el derecho a dar de
          baja la suscripción del Usuario sin previo aviso.
        </p>
      </li>
      <li class="list-group-item">
        <h4>DERECHO DE DESISTIMIENTO DE LA SUSCRIPCIÓN</h4>
        <p>
          El Usuario, de acuerdo con las disposiciones establecidas en virtud de
          la Ley General para la Defensa de los Consumidores y Usuarios,
          dispondrá de un plazo de catorce (14) días hábiles a partir de fecha
          de donación podrá ejercer su derecho de desistimiento de la
          suscripción en la Plataforma sin tener que alegar motivo alguno. La
          Fundación no atenderá solicitudes de desistimiento realizadas fuera de
          este plazo.
        </p>
        <p>
          Para ejercer dicho derecho, el Usuario debe informar a la Fundación de
          la decisión de desistir de la suscripción enviando una solicitud a la
          dirección email socios@fundacionbertinosborne.org rellenando el
          formulario que se indica en el Anexo 1: Formulario de desistimiento de
          las presentes Condiciones. Para cumplir el plazo de desistimiento,
          basta con que la comunicación relativa al ejercicio por su parte de
          este derecho sea enviada antes de que venza el plazo correspondiente.
        </p>
      </li>
    </ol>
    <h2>
      SECCIÓN TERCERA. CONDICIONES APLICABLES A LAS SECCIONES PRIMERA Y SEGUNDA
    </h2>
    <ol class="list-group list-group-flush">
      <li class="list-group-item">
        <h4>MODIFICACIÓN DE LAS CONDICIONES</h4>
        <p>
          La Fundación se reserva el derecho a modificar las presentes
          Condiciones, así como demás políticas o condiciones disponibles en
          cada momento en la Plataforma, en cualquier momento. No obstante, el
          Usuario quedará sometido a las Condiciones en vigor en el momento de
          realizar una compra, salvo que, por imposición legal o por
          requerimiento de las autoridades públicas, estas Condiciones deban
          modificarse, en cuyo caso tales cambios resultarán aplicables a las
          compras en curso.
        </p>
      </li>
      <li class="list-group-item">
        <h4>SUSPENSIÓN Y EXTINCIÓN</h4>
        <p>
          El incumplimiento de cualesquiera de las anteriores obligaciones por
          el Usuario podrá llevar aparejada la adopción por la Fundación de las
          medidas oportunas amparadas en Derecho y en el ejercicio de sus
          derechos u obligaciones, pudiendo llegar a la eliminación o bloqueo de
          la cuenta del Usuario infractor de forma que no pueda adquirir los
          Productos, sin que medie posibilidad de indemnización alguna por los
          daños y perjuicios causados.
        </p>
        <p>
          La Fundación se reserva la posibilidad de alterar, modificar o
          suspender cualquier característica de la Plataforma.
        </p>
      </li>
      <li class="list-group-item">
        <h4>PRIVACIDAD Y PROTECCIÓN DE DATOS</h4>
        <p>
          De conformidad con lo dispuesto por el Reglamento 2016/679 del
          Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a
          la protección de las personas físicas en lo que respecta al
          tratamiento de datos personales y a la libre circulación de estos
          datos (Reglamento General de Protección de Datos) y la Ley Orgánica
          3/2018, de 5 de diciembre, de Protección de Datos Personales y
          garantía de los derechos digitales, todos los datos de carácter
          personal recabados durante la utilización de la Plataforma serán
          tratados de conformidad con lo dispuesto en la Política de Privacidad,
          que todo Usuario debe aceptar expresamente en caso de facilitar datos
          de carácter personal a través del área privada de la Plataforma.
        </p>
      </li>
      <li class="list-group-item">
        <h4>CONTACTO</h4>
        <p>
          La Fundación pone a disposición del Usuario el siguiente correo
          electrónico socios@fundacionbertinosborne.org con la finalidad de
          prestar soporte y ayuda, así como para recibir cualquier comentario o
          preocupación del Usuario, tanto durante los trámites de contratación
          de los servicios como a posteriori.
        </p>
        <p>
          Además, cabe recordar que Usuario dispone de los datos de contacto de
          la Fundación reflejados en el apartado uno (1) de las presentes
          Condiciones.
        </p>
      </li>
      <li class="list-group-item">
        <h4>GENERALES</h4>
        <p>
          Los encabezamientos de las distintas cláusulas son solo informativos y
          no afectarán, calificarán o ampliarán la interpretación de las
          presentes Condiciones.
        </p>
        <p>
          La vigencia temporal de las presentes Condiciones coincide, por tanto,
          con el tiempo de su exposición, hasta que sea modificado total o
          parcialmente, momento en el cual pasarán a tener vigencia las nuevas
          Condiciones modificadas.
        </p>
        <p>
          En el caso de que cualquier disposición de las presentes Condiciones
          fuese declarada nula o inaplicable, en su totalidad o en parte, por
          cualquier Juzgado, Tribunal u órgano administrativo competente, dicha
          nulidad no afectará a las restantes disposiciones de las Condiciones
          de Uso.
        </p>
        <p>
          El no ejercicio o ejecución por parte de la Fundación de cualquier
          derecho o disposición contenido en las presentes Condiciones, no
          constituirá una renuncia al mismo, salvo reconocimiento y acuerdo por
          escrito por su parte.
        </p>
        <p>
          Las presentes Condiciones serán conservadas en formato electrónico por
          la Fundación y quedarán permanentemente a disposición del Usuario.
        </p>
        <p>
          El Usuario y la Fundación formalizarán el contrato en la lengua
          española, siendo éste el idioma de interpretación de las presentes
          Condiciones.
        </p>
        <p>
          Si tras realizar todos los trámites necesarios para navegar y acceder
          a la Plataforma y perfeccionar las presentes Condiciones el Usuario
          identificara algún error a la hora de introducir sus datos, deberá
          ponerse en contacto con la Fundación a través del formulario de
          contacto o de la dirección de correo electrónico. Éste le prestará el
          soporte necesario y corregirá, en su caso, los errores identificados.
        </p>
        <p>
          Salvo disposición en contra en las presentes Condiciones, las
          notificaciones entre el Usuario y la Fundación deberán realizarse por
          escrito mediante un correo electrónico a las correspondientes
          direcciones. A estos efectos, los datos de contacto de la Fundación
          serán los que aparecen en las presentes Condiciones y los datos de
          contacto del Usuario serán los establecidos en la Plataforma.
        </p>
      </li>
      <li class="list-group-item">
        <h4>SISTEMA DE RECLAMACIONES</h4>
        <p>
          Si el Usuario considera que cualquiera de sus derechos ha sido
          vulnerado, la Fundación propone que se lo comunique a través del
          correo electrónico socios@fundacionbertinosborne.org habilitado para
          ello. La Fundación se compromete a responder y atender sus inquietudes
          en relación con la supuesta infracción de derechos.
        </p>
        <p>
          Recibida toda la información, la Fundación podrá tomar las medidas
          necesarias con el objetivo de investigar y resolver la situación.
        </p>
        <p>
          En ningún caso la puesta en práctica de alguna medida significará la
          admisión por parte de la Fundación de responsabilidad alguna. La
          Fundación se reserva el ejercicio de cualquier derecho, acción o
          defensa que le asista.
        </p>
      </li>
      <li class="list-group-item">
        <h4>CESIÓN</h4>
        <p>
          El Usuario no podrá ceder este contrato (en su totalidad o en parte),
          sin el consentimiento previo y por escrito de la Fundación. No
          obstante, la Fundación podrá ceder todo o parte del presente Contrato
          a cualquier entidad que pudiese hacerse cargo en el futuro de la
          prestación de los Servicios incluidos en la Plataforma.
        </p>
      </li>
      <li class="list-group-item">
        <h4>LEY APLICABLE Y JURISDICCIÓN COMPETENTE</h4>
        <p>
          Las presentes Condiciones se regirán e interpretarán de conformidad
          con la ley española (exceptuando sus disposiciones sobre conflicto de
          leyes). En cualquier caso, si el Usuario es un consumidor- a los
          efectos de la LGDCU- y reside en un país de la Unión Europea, también
          contará con la protección que pueda ofrecerle cualquier disposición
          imperativa de la legislación de su país de residencia.
        </p>
        <p>
          En el supuesto de que surja cualquier conflicto o discrepancia en la
          interpretación y/o aplicación del Aviso Legal, los Juzgados
          competentes serán los que disponga la normativa legal aplicable, y en
          caso de permitirse, todo litigio será sometido a los Juzgados y
          Tribunales de la ciudad de Madrid.
        </p>
        <p>
          La Comisión Europea facilita una plataforma de resolución de litigios
          online (Resolución de litigios en línea en materia de consumo conforme
          al Art. 14.1 del Reglamento (UE) 524/2013) que se encuentra disponible
          en el siguiente enlace: http://ec.europa.eu/consumers/odr/.
        </p>
      </li>
    </ol>
  </div>
</template>

<style>
	.list-group-item {
	background: transparent !important;
	}
</style>

<script>
	export default {
		name: "TermsAndConditions",
	};
</script>
