<template>
	<div class="container-fluid pt-5">
		<div class="row">
			<div class="col-lg-3 col-md-4">
				<NavbarMembership />
			</div>
			<div class="col-lg-9 col-md-8">
				<div class="row">
					<div class="col-xl-6 col-md-6 mb-4">
						<div class="card border-left-warning shadow h-100 py-2">
							<div class="card-body">
								<div class="row no-gutters align-items-center">
									<div class="col mr-2">
										<div
											class="text-xs font-weight-bold text-warning text-uppercase mb-1"
										>
											Previsión próxima remesa (EUR)
										</div>
										<div
											class="h5 mb-0 font-weight-bold text-gray-800"
										>
											{{
												formatCurrency(
													next_remittance_amount / 100
												)
											}}
										</div>
									</div>
									<div class="col-auto">
										<i
											class="fa-duotone fa-euro-sign fa-2x text-gray-500"
										></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Button trigger modal -->
				<div class="col-12 text-end">
					<button
						type="button"
						class="btn btn-fbo mb-3"
						data-bs-toggle="modal"
						data-bs-target="#createRemittance"
					>
						Crear remesa
					</button>
				</div>
				<!-- Modal Create Remittance-->
				<div
					class="modal fade"
					id="createRemittance"
					tabindex="-1"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog">
						<div class="modal-content">
							<form @submit.prevent="createRemittance">
								<div class="modal-header">
									<h1
										class="modal-title fs-5"
										id="exampleModalLabel"
									>
										Lanzar remesa
									</h1>
									<button
										type="button"
										class="btn-close"
										data-bs-dismiss="modal"
										aria-label="Close"
									></button>
								</div>
								<div class="modal-body">
									<div class="mb-3">
										<label
											>Descripción remesa (*Concepto que
											aparecerá en el cargo)</label
										>
										<input
											class="form-control"
											type="text"
											v-model="newRemittance.description"
										/>
									</div>
									<div>
										<label
											>Fecha de ejecución de la
											remesa</label
										>
										<input
											class="form-control"
											type="date"
											v-model="newRemittance.date"
										/>
									</div>
								</div>
								<div class="modal-footer">
									<button type="submit" class="btn btn-fbo">
										Programar remesa
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div v-if="!loading" class="col-12">
					<div class="card mb-5">
						<div class="card-body">
							<h6 class="font-weight-bold">Remesas</h6>
							<div class="table-responsive">
								<table class="table table-hovered">
									<thead>
										<tr>
											<td><small>Descripción</small></td>
											<td><small>Fecha</small></td>
											<td>
												<small>Nº de órdenes</small>
											</td>
											<td>
												<small>Completados</small>
											</td>
											<td>
												<small>Fallidos</small>
											</td>
											<td><small>Total</small></td>
											<td><small>Estado</small></td>
											<td></td>
										</tr>
									</thead>
									<tbody>
										<tr
											:key="item"
											v-for="item in remittances"
										>
											<td class="align-middle">
												{{ item.description }}
											</td>
											<td class="align-middle">
												{{ item.date }}
											</td>
											<td class="align-middle">
												{{ item.payment_orders.length }}
											</td>
											<td class="align-middle">
												{{ item.completed_orders }}
											</td>
											<td class="align-middle">
												{{ item.failed_orders }}
											</td>
											<td
												class="text-center align-middle"
											>
												<span
													>{{
														formatCurrency(
															item.total_amount /
																100
														)
													}}
												</span>
											</td>

											<td class="align-middle">
												<div
													v-if="item.removed == true"
												>
													<span
														class="badge badge-light-danger"
														>Cancelado</span
													>
												</div>
												<div v-else>
													<span
														v-if="item.status == 2"
														class="badge badge-light-success"
														>Completado</span
													>
													<span
														v-else-if="
															item.status == 1
														"
														class="badge badge-light-info"
														>Procesando</span
													>
													<span
														v-else
														class="badge badge-light-danger"
														>Pendiente</span
													>
												</div>
											</td>
											<td class="align-middle d-flex">
												<router-link
													type="button"
													class="btn btn-fbo"
													:to="
														'/membership/remittances/' +
														item.UUID
													"
												>
													<i
														class="fa-solid fa-list-timeline"
													></i>
												</router-link>

												<!-- Button trigger modal -->
												<button
													v-if="item.status == 0"
													type="button"
													class="btn btn-fbo ms-2"
													data-bs-toggle="modal"
													data-bs-target="#editRemittance"
												>
													<i
														class="fa-duotone fa-pen-to-square"
													></i>
												</button>
												<!-- Modal Edit Remittance-->
												<div
													v-if="item.status == 0"
													class="modal fade"
													id="editRemittance"
													tabindex="-1"
													aria-labelledby="exampleModalLabel"
													aria-hidden="true"
												>
													<div class="modal-dialog">
														<div
															class="modal-content"
														>
															<form
																@submit.prevent="
																	editRemittance(
																		item
																	)
																"
															>
																<div
																	class="modal-header"
																>
																	<h1
																		class="modal-title fs-5"
																		id="exampleModalLabel"
																	>
																		Editar
																		remesa
																	</h1>
																	<button
																		type="button"
																		class="btn-close"
																		data-bs-dismiss="modal"
																		aria-label="Close"
																	></button>
																</div>
																<div
																	class="modal-body"
																>
																	<div
																		class="mb-3"
																	>
																		<label
																			>Descripción
																			remesa
																			(*Concepto
																			que
																			aparecerá
																			en
																			el
																			cargo)</label
																		>
																		<input
																			class="form-control"
																			type="text"
																			v-model="
																				item.description
																			"
																		/>
																	</div>
																	<div>
																		<label
																			>Fecha
																			de
																			ejecución
																			de
																			la
																			remesa</label
																		>
																		<input
																			class="form-control"
																			type="date"
																			v-model="
																				item.date
																			"
																		/>
																	</div>
																	<div
																		class="form-check mt-5"
																		style="
																			padding-left: 3rem;
																		"
																	>
																		<input
																			class="form-check-input"
																			type="checkbox"
																			value=""
																			id="flexCheckDefault"
																			style="
																				margin-left: -3em;
																			"
																			v-model="
																				item.removed
																			"
																		/>
																		<label
																			class="form-check-label"
																			for="flexCheckDefault"
																		>
																			Marcar
																			esta
																			casilla
																			para
																			cancelar
																			la
																			remesa
																		</label>
																	</div>
																</div>
																<div
																	class="modal-footer"
																>
																	<button
																		type="submit"
																		class="btn btn-fbo"
																	>
																		Editar
																		remesa
																	</button>
																</div>
															</form>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="text-center py-5">
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
					<div>Cargando información</div>
				</div>
			</div>
		</div>
	</div>
	<router-view></router-view>
</template>

<script>
// import mixin
import userRoles from "@/mixins/user-roles.js";

import NavbarMembership from "@/components/Layout/NavbarMembership.vue";

import ClipBoard from "@/components/Global/ClipBoard.vue";

import MembershipService from "@/services/membership.service.js";

import Swal from "sweetalert2";

export default {
	name: "RemittancesView",
	mixins: [userRoles],
	components: {
		NavbarMembership,
		ClipBoard,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	data() {
		return {
			loading: true,
			remittances: null,
			next_remittance_amount: 0,
			newRemittance: {
				description: null,
				date: null,
			},
		};
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getRemittances();
			this.loading = false;
		},

		async getRemittances() {
			var response = await MembershipService.getRemittances();
			this.remittances = response.data.items;
			this.next_remittance_amount = response.data.next_remittance_amount;
			console.log(this.remittances, "Remesas");
			console.log(this.next_remittance_amount, "next_remittance_amount");
		},

		async createRemittance() {
			var response = await MembershipService.createRemittance(
				this.newRemittance
			);

			if (response.status == 201) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: "Se ha crado correctamente.",
					showConfirmButton: false,
					timer: 1500,
					backdrop: false,
					toast: true,
				});

				this.$router.go();
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error: " + response.data.message,
					confirmButtonColor: "#0091ff",
				});
			}
		},

		async editRemittance(item) {
			var response = await MembershipService.updateRemittance(item.UUID, {
				description: item.description,
				date: item.date,
				removed: item.removed,
			});

			if (response.status == 200) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: "Se ha crado correctamente.",
					showConfirmButton: false,
					timer: 1500,
					backdrop: false,
					toast: true,
				});

				this.$router.go();
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error: " + response.data.message,
					confirmButtonColor: "#0091ff",
				});
			}
		},

		formatDate(date) {
			// Create a date object from a date string
			var date = new Date(date);

			// Get year, month, and day part from the date
			var year = date.toLocaleString("default", { year: "numeric" });
			var month = date.toLocaleString("default", { month: "2-digit" });
			var day = date.toLocaleString("default", { day: "2-digit" });

			// Generate yyyy-mm-dd date string
			var formattedDate = year + "-" + month + "-" + day;
			console.log(formattedDate); // Prints: 2022-05-04
			return formattedDate;
		},

		formatCurrency(num) {
			// Create our number formatter.
			const formatter = new Intl.NumberFormat("es-ES", {
				style: "currency",
				currency: "EUR",

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});
			return formatter.format(num);
		},
	},
	created() {
		this.start();
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>
