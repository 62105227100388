import api from "./api";
import axios from "axios";

class MembershipService {
    async getTeams() {
        return await api
            .get("/membership/teams/list")
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getSingleTeam(pk) {
        return await api
            .get("/membership/teams/single/" + pk)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async addTeam(data) {
        return await api
            .post("/membership/teams/create/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async addManager(data) {
        return await api
            .post("/membership/manager/add/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async addCommercial(data) {
        return await api
            .post("/membership/commercial/add/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async addMember(data) {
        return await api
            .post("/membership/members/add/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getAllMember() {
        return await api
            .get("/membership/members/all")
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getMember(memberUUID) {
        return await api
            .get("/membership/members/single/" + memberUUID)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getTeamManagers(teamUUID) {
        return await api
            .get("/membership/teams/managers/" + teamUUID)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getMyMembers(params) {
        return await api
            .get("/membership/members/list", { params: params })
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async export_members_list() {
        return api
            .get("/membership/export_members", {
                responseType: "blob",
            })
            .then((response) => {
                var date = new Date();
                let currentDate = date.getFullYear() + "-" + date.getMonth()+1 + "-" + date.getDate();
                let filename = `${currentDate}-membership_data`;
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: "application/xlsx" })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename + ".xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                console.log('This is the response ', response.data);
                return { status: response.status, data: link.href };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }


    async getCommercialTeams() {
        return await api
            .get("/membership/commercial/teams")
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async confirmPayment(pk) {
        return await api
            .get("membership/payment/confirm/" + pk)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async unsubscribeMember(pk) {
        return await api
            .put("membership/members/unsubscribe/" + pk + "/")
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }
    async getUserSubscription(pk) {
        return await api
            .get("/membership/subscriptions/user/" + pk)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getPayment(pk) {
        var API_URL = "";

        if (location.hostname === "localhost") {
            // API_URL =  "https://apitest.fbofamily.app/api/v1";
            API_URL = "http://127.0.0.1:8000/api/v1";
        } else {
            API_URL = "https://api.fbofamily.app/api/v1";
        }

        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: API_URL + "/payments/single/" + pk,
            headers: {
                Authorization:
                    "Api-Key kXbFyrcH.fwmKXU7npbeIbvyhVnENhn0bty42moGI",
                "Content-Type": "application/json",
            },
        };
        return await axios
            .request(config)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getSubscriptionPayments(pk) {
        return await api
            .get("/membership/subscriptions/payments/list/" + pk)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getRemittances() {
        return await api
            .get("payments/paycomet/remittances/list")
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getSingleRemittance(pk) {
        return await api
            .get("payments/paycomet/remittances/single/" + pk)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getRemittancePaymentOrders(pk) {
        return await api
            .get("payments/paycomet/remittances/payments/" + pk)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async createRemittance(data) {
        return await api
            .post("payments/paycomet/remittances/create/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async updateRemittance(pk, data) {
        return await api
            .put("payments/paycomet/remittances/update/" + pk + "/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async createUserSubscription(data) {
        return await api
            .post("/membership/subscriptions/create/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async createPublicSubscription(data) {
        return await api
            .post("/membership/subscriptions/public/create/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async getBankTransactionsByDates(params) {
        return await api
            .get("bank/transactions/dates", { params: params })
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async send_payment_error_email(data) {
        return await api
            .post("email/payments/error/", data)
            .then((response) => {
                return { status: response.status, data: response.data };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }

    async generate_donation_certicate(data) {
        return api
            .post("membership/donation_certificate/", data, {
                responseType: "blob",
            })
            .then((response) => {
                var date = new Date();
                var filename =
                    "certificate_" +
                    date.getFullYear() +
                    date.getMonth() +
                    date.getDate() +
                    "_" +
                    date.getHours() +
                    date.getMinutes() +
                    date.getSeconds();
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: "application/pdf" })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename + ".pdf"); //or any other extension
                document.body.appendChild(link);
                link.click(function () {
                    window.$("#dialog").dialog();
                });
                return { status: response.status, data: link.href };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            });
    }
}

export default new MembershipService();
