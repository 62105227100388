<template>
	<div class="container-fluid pt-3">
		<div v-if="!loading">
			<!-- Page Heading -->
			<div class="mb-4">
				<router-link to="/membership/members">
					<i class="fa-duotone fa-arrow-left me-2"></i>
					Volver atrás
				</router-link>
			</div>
			<div
				class="d-sm-flex align-items-center justify-content-between mb-4"
			>
				<h1 class="h3 mb-0 text-gray-800 fs-5">
					Socio: {{ member.first_name + " " + member.last_name }}
				</h1>
				<div>
					<button
						v-if="member.enabled"
						class="d-none d-sm-inline-block btn btn-sm btn-danger shadow-sm"
						data-bs-toggle="modal"
						data-bs-target="#modalUnsubscribeMember"
					>
						<i class="fa-solid fa-user-slash me-2"></i>Dar de baja
					</button>
				</div>
			</div>

			<!-- Content Row -->
			<div class="row">
				<!-- Content Column -->
				<div class="col-lg-9 mb-4">
					<div class="card shadow mb-4">
						<div class="card-header py-3">
							<h6 class="m-0 font-weight-bold">
								Información personal
							</h6>
						</div>
						<div class="card-body">
							<div class="d-md-flex justify-content-between">
								<ul class="ps-4">
									<li>Nombre: {{ member.first_name }}</li>
									<li>Apellidos: {{ member.last_name }}</li>
									<li>Email: {{ member.email }}</li>
									<li>Teléfono: {{ member.telephone }}</li>
									<li>Nº documento: {{ member.document }}</li>
									<li>Dirección: {{ member.address }}</li>
									<li>
										Código postal: {{ member.postal_code }}
									</li>
									<li>Provincia: {{ member.region.name }}</li>
									<li>País: {{ member.country.name }}</li>
								</ul>
								<div>
									<div class="text-end mx-5">
										<span
											v-if="member.enabled"
											class="badge badge-light-success"
											style="font-size: 1.5em"
											>Activado</span
										>
										<span
											v-else
											class="badge badge-light-danger"
											style="font-size: 1.5em"
											>No activo</span
										>
										<div v-if="member.subscribeDate">
											<small
												>Fecha alta socio:
												{{
													formatDate(
														member.subscribeDate
													)
												}}</small
											>
										</div>
										<div v-if="member.unsubscribeDate">
											<small
												>Fecha baja socio:
												{{
													formatDate(
														member.unsubscribeDate
													)
												}}</small
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="card border-left-warning shadow py-2 mb-3">
						<div class="card-body">
							<div class="row no-gutters align-items-center">
								<div class="col mr-2">
									<div
										class="text-xs font-weight-bold text-uppercase mb-1"
									>
										Aportación periódica
									</div>
									<div
										class="h5 mb-0 font-weight-bold text-gray-800"
									>
										{{
											formatCurrency(
												member.quantity / 100
											)
										}}
									</div>
								</div>
								<div class="col-auto">
									<i
										class="fas fa-euro-sign fa-2x text-gray-300"
									></i>
								</div>
							</div>
						</div>
					</div>
					<div class="card border-left-warning shadow py-2 mb-3">
						<div class="card-body">
							<div class="row no-gutters align-items-center">
								<div class="col mr-2">
									<div
										class="text-xs font-weight-bold text-uppercase mb-1"
									>
										Tipo de suscripción
									</div>
									<div
										class="h5 mb-0 font-weight-bold text-gray-800"
									>
										<span
											v-if="
												member.subscription == 'annual'
											"
											>Anual</span
										>
										<span v-else>Mensual</span>
									</div>
								</div>
								<div class="col-auto">
									<i
										class="fa-solid fa-repeat fa-2x text-gray-300"
									></i>
								</div>
							</div>
						</div>
					</div>
					<div>
						<button
							class="btn btn-secondary"
							@click="generatePDF()"
							data-bs-toggle="modal"
							data-bs-target="
								#certificateDonation
							"
						>
							<i class="fa-solid fa-download"></i
							><span v-if="showTextBtn" class="ms-2"
								>Descargar</span
							>
						</button>

						<!-- Modal -->
						<div
							class="modal fade"
							id="certificateDonation"
							tabindex="-1"
							aria-labelledby="exampleModalLabel"
							aria-hidden="true"
						>
							<div class="modal-dialog modal-xl">
								<div class="modal-content">
									<div class="modal-header">
										<h5 class="modal-title">
											Certificado donación
										</h5>
										<button
											type="button"
											class="btn-close"
											data-bs-dismiss="modal"
											aria-label="Close"
										></button>
									</div>
									<div class="modal-body">
										<embed
											v-if="!loading"
											:src="pdf"
											frameborder="0"
											width="100%"
											height="550px"
										/>
										<div
											v-else
											id="spinner-search"
											class="col-12 text-center py-5"
										>
											<div
												class="spinner-border text-primary"
												role="status"
												style="
													width: 2rem;
													height: 2rem;
													visibility: visble;
												"
											>
												<span class="visually-hidden"
													>Loading...</span
												>
											</div>
											<div>Cargando información...</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-12 mb-4">
					<div class="card shadow mb-4">
						<div class="card-body">
							<div class="table-responsive">
								<div
									class="d-block d-md-flex justify-content-between mb-3"
								>
									<h6>Historial de pagos</h6>

									<button
										class="btn btn-sm btn-fbo"
										@click="send_payment_error_email()"
									>
										Enviar nuevo enlace pago
									</button>
								</div>
								<table class="table">
									<thead>
										<tr>
											<th>
												<small> Referencia</small>
											</th>
											<th>
												<small>Estado</small>
											</th>
											<th>
												<small>Cantidad</small>
											</th>
											<th>
												<small>Fecha del pago</small>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											:key="item"
											v-for="item in payments"
										>
											<td>
												<small v-if="item.Order">
													{{ item.Order }}</small
												>
												<small v-else>
													{{
														"#" + item.payment_id
													}}</small
												>
											</td>
											<td>
												<span
													v-if="
														item.Response == 'OK' &&
														item.TransactionType ==
															1
													"
													class="badge badge-light-success fs-7"
													>Confirmado</span
												>
												<span
													v-else-if="
														item.Response == 'OK' &&
														item.TransactionType ==
															2
													"
													class="badge badge-light-info fs-7"
													>Devolución</span
												>
												<span
													v-else-if="
														item.Response == 'KO'
													"
													class="badge badge-light-danger fs-7"
													>Error en el pago<br /><small
														>{{
															item.ErrorID +
															": " +
															item.ErrorDescription
														}}</small
													></span
												>
												<span
													v-else-if="
														item.isLinkPayment
													"
													class="badge badge-light-info fs-7"
													>Pendiente</span
												>
												<span
													v-else
													class="badge badge-light-info fs-7"
													>Error</span
												>
											</td>
											<td>
												<small v-if="item.Amount">{{
													formatCurrency(
														item.Amount / 100
													)
												}}</small>
												<small v-else>{{
													formatCurrency(
														member.quantity / 100
													)
												}}</small>
											</td>
											<td>
												<small
													v-if="item.BankDateTime"
													>{{
														item.BankDateTime.substring(
															6,
															8
														) +
														"/" +
														item.BankDateTime.substring(
															4,
															6
														) +
														"/" +
														item.BankDateTime.substring(
															0,
															4
														) +
														" - " +
														item.BankDateTime.substring(
															8,
															10
														) +
														":" +
														item.BankDateTime.substring(
															10,
															12
														)
													}}</small
												>
												<small
													v-else
													class="text-center"
													>{{
														formatDate(
															item.updatedAt
														)
													}}</small
												>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Modal Añadir Socio-->
			<div
				class="modal fade"
				id="modalUnsubscribeMember"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								Baja socio
							</h5>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							<div>
								¿Estás seguro que quieres dar de baja a este
								socio?
							</div>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-link text-dark"
								data-bs-dismiss="modal"
							>
								Cerrar
							</button>
							<button
								type="button"
								class="btn btn-fbo"
								@click.stop.prevent="unsubscribeMember()"
							>
								Confirmar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="text-center py-5">
			<div class="spinner-border" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
			<div>Cargando información</div>
		</div>
	</div>
</template>

<script>
import NavbarMembership from "@/components/Layout/NavbarMembership.vue";

import MembershipService from "@/services/membership.service.js";
import ComunidadService from "@/services/comunidad.service.js";
import RegionService from "@/services/region.service.js";
import CountryService from "@/services/country.service.js";
import UserService from "@/services/user.service.js";

import Swal from "sweetalert2";

export default {
	name: "MemberSingleView",
	components: {
		NavbarMembership,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	data() {
		return {
			memberUUID: this.$route.params.memberUUID,
			loading: true,
			member: null,
			comunidades: null,
			regions: null,
			countries: null,
			newMember: {
				country: 66,
				subscription: "monthly",
				quantity: 2.75,
			},
			payments: null,
			pdf: "",
		};
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getMember();
			await this.getPayments();
			await this.getComunidades();
			await this.getCountries();
			await this.getRegions();
			this.loading = false;
		},

		async generatePDF() {
			this.loading = true;
			var response = await MembershipService.generate_donation_certicate({
				startDate: "2023-01-01",
				endDate: "2023-12-31",
				membership: this.memberUUID,
			});
			console.log(response.data);
			this.pdf = response.data;
			console.log(this.pdf, "PDF");
			this.loading = false;
		},

		async getMember() {
			var response = await MembershipService.getMember(this.memberUUID);
			this.member = response.data;
			console.log(this.member, "Socio");
		},

		async getComunidades() {
			var response = await ComunidadService.getAllComunidades();
			this.comunidades = response.data;
			// console.log(this.comunidades, "Comunidades");
		},

		async getCountries() {
			var response = await CountryService.getAllCountries();
			this.countries = response.data;
			// console.log(this.countries, "Countries");
		},

		async getRegions() {
			var response = await RegionService.getAllRegions();
			this.regions = response.data;
			// console.log(this.regions, "Regions");
		},

		async getPayments() {
			var response = await MembershipService.getSubscriptionPayments(
				this.member.UUID
			);
			this.payments = response.data;
			console.log(this.payments, "Payments Orders");
		},

		async unsubscribeMember() {
			var response = await MembershipService.unsubscribeMember(
				this.memberUUID
			);

			if (response.status == 200) {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: "Se ha añadido correctamente.",
					showConfirmButton: false,
					timer: 1500,
					backdrop: false,
					toast: true,
				});

				this.$router.go();
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error: " + response.data.message,
					confirmButtonColor: "#0091ff",
				});
			}
		},

		async send_payment_error_email() {
			Swal.fire({
				title: "¿Estás seguro que quieres generar y mandar un nuevo enlace de pago al usuario?",
				showDenyButton: false,
				showCancelButton: true,
				confirmButtonText: "Enviar email",
				confirmButtonColor: "#ff9b04",
				cancelButtonText: "Cancelar",
			}).then(async (result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					var response =
						await MembershipService.send_payment_error_email({
							membership: this.memberUUID,
						});
					console.log(response.data, "Email send");
					Swal.fire({
						title: "Enviado!",
						icon: "success",
						confirmButtonColor: "#ff9b04",
					});
					this.start();
				}
			});
		},

		formatCurrency(num) {
			// Create our number formatter.
			const formatter = new Intl.NumberFormat("es-ES", {
				style: "currency",
				currency: "EUR",

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});
			return formatter.format(num);
		},

		padTo2Digits(num) {
			return num.toString().padStart(2, "0");
		},

		formatDate(d) {
			const date = new Date(d);
			return (
				[
					this.padTo2Digits(date.getDate()),
					this.padTo2Digits(date.getMonth() + 1),
					date.getFullYear(),
				].join("/") +
				" - " +
				[
					this.padTo2Digits(date.getHours()),
					this.padTo2Digits(date.getMinutes()),
				].join(":")
			);
		},
	},
	created() {
		this.start();
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>
