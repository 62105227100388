<template>
  <div>
    <button
      class="btn btn-sm btn-dark"
      data-bs-toggle="modal"
      data-bs-target="#modalAddTopic"
    >
      <i class="fas fa-plus me-2"></i>Añadir nuevo
    </button>

    <!-- Modal Add Topic -->
    <div
      class="modal fade"
      id="modalAddTopic"
      tabindex="-1"
      aria-labelledby="modalAddTopic"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down">
        <div class="modal-content">
          <form @submit="add">
            <div class="modal-header">
              <h5 class="modal-title">Añadir temática</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              <div class="form-group mb-3">
                <label class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addTopic.name"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Descripción</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addTopic.description"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Imagen</label>
                <input
                  type="file"
                  class="form-control"
                  @change="uploadImage"
                  accept="image/*"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Color (hexadecimal)</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ejemplo: #FF0000"
                  v-model="addTopic.color"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
              <button id="submitAddTopic" type="submit" class="btn btn-primary">
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopicService from "@/services/topic.service";

import Swal from "sweetalert2";

export default {
  name: "AddTopic",
  data() {
    return {
      topic: [],
      addTopic: {
        name: "",
        description: "",
        color: "",
      },
      image: "",
    };
  },
  methods: {
    uploadImage(event) {
      this.image = event.target.files[0];
    },
    async add(evt) {
      evt.preventDefault();
      window.$("#submitAddTopic").empty();
      window
        .$("#submitAddTopic")
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      const fd = new FormData();
      if (this.image != "") {
        fd.append("image", this.image);
      }
      fd.append("name", this.addTopic.name);
      fd.append("name", this.addTopic.description);
      fd.append("color", this.addTopic.color);

      // console.log(fd);
      // for (const value of fd.values()) {
      //     console.log(value);
      // }
      evt.preventDefault();

      let response = await TopicService.addTopic(fd);
      console.log(response);
      window.$("#submitAddTopic").empty();
      window.$("#submitAddTopic").append("Guardar");

      if (response.status == 201) {
        this.title = null;
        // window.$('#modalAddTopic').modal('hide');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });

        this.$router.go();
      }
    },
  },
};
</script>
