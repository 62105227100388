<template>
	<div class="app-sidebar menu-rounded d-none d-md-block">
		<div class="menu-item">
			<!--begin:Menu content-->
			<div class="menu-content">
				<span class="menu-section fs-5 fw-bolder ps-1 py-1"
					>Portal socios</span
				>
			</div>
			<!--end:Menu content-->
		</div>
		<div data-kt-menu-trigger="click" class="menu-item">
			<!--begin:Menu link-->
			<router-link to="/membership/subscription" class="menu-link">
				<span class="menu-icon position-absolute"
					><i class="fa-duotone fa-heart"></i></span
				><span class="menu-title ms-4">Mi suscripción</span>
			</router-link>
		</div>
		<div
			data-kt-menu-trigger="click"
			class="menu-item"
			v-if="user_role == 'superadmin' || user_role == 'admin'"
		>
			<!--begin:Menu link-->
			<router-link to="/membership/teams" class="menu-link">
				<span class="menu-icon position-absolute"
					><i class="fa-duotone fa-objects-column"></i></span
				><span class="menu-title ms-4">Equipos</span>
			</router-link>
		</div>
		<div
			data-kt-menu-trigger="click"
			class="menu-item"
			v-if="user_role == 'teamManager' && !loading"
		>
			<!--begin:Menu link-->
			<router-link
				:to="'/membership/teams/' + teams[0].UUID"
				class="menu-link"
				v-if="teams.length > 0"
			>
				<span class="menu-icon position-absolute"
					><i class="fa-duotone fa-objects-column"></i></span
				><span class="menu-title ms-4"
					>Equipo: <span>{{ teams[0].name }}</span></span
				>
			</router-link>
		</div>
		<div data-kt-menu-trigger="click" class="menu-item">
			<!--begin:Menu link-->
			<router-link to="/membership/members" class="menu-link">
				<span class="menu-icon position-absolute"
					><i class="fa-duotone fa-users"></i></span
				><span class="menu-title ms-4">
					<span
						v-if="user_role == 'superadmin' || user_role == 'admin'"
						>Socios</span
					>
					<span v-else>Mis socios</span>
				</span>
			</router-link>
		</div>
		<div
			data-kt-menu-trigger="click"
			class="menu-item"
			v-if="user_role == 'superadmin'"
		>
			<!--begin:Menu link-->
			<router-link to="/membership/remittances" class="menu-link">
				<span class="menu-icon position-absolute"
					><i class="fa-duotone fa-money-bill-transfer"></i></span
				><span class="menu-title ms-4">Remesas</span>
			</router-link>
		</div>

		<div
			data-kt-menu-trigger="click"
			class="menu-item"
			v-if="user_role == 'superadmin'"
		>
			<!--begin:Menu link-->
			<router-link to="/membership/bank" class="menu-link">
				<span class="menu-icon position-absolute"
					><i class="fa-duotone fa-wallet"></i></span
				><span class="menu-title ms-4">Tesorería</span>
			</router-link>
		</div>

		<button
			v-if="user.referrals.length > 0"
			class="d-sm-inline-block btn btn-fbo shadow-sm mt-3 mt-md-3 mb-3"
			data-bs-toggle="modal"
			data-bs-target="#modalNavAddMember2"
		>
			<i class="fa-regular fa-plus"></i> Añadir socio
		</button>

		<!-- Modal Añadir Member-->
		<div
			v-if="user.referrals.length > 0"
			class="modal fade"
			id="modalNavAddMember2"
			tabindex="-1"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header border-0">
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body px-md-5 pb-md-5 p-2 pt-0">
						<div class="text-center">
							<h3 class="mb-5">Invitar socios</h3>
						</div>
						<h6 class="text-gray-800">
							Comparte tu
							<i>referral link</i> con tus amigos
						</h6>
						<ClipBoard
							:text="
								'https://socios.fbofamily.app/member/add/referral/' +
								user.referrals[0].referral
							"
							class="mb-5"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
// import mixin
import userRoles from "@/mixins/user-roles.js";
import ClipBoard from "@/components/Global/ClipBoard.vue";

import MembershipService from "@/services/membership.service";

export default {
	name: "NavbarMembership",
	mixins: [userRoles],

	emits: ["selectOption"],
	components: {
		ClipBoard,
	},
	data() {
		return {
			user: this.$store.state.user.data,
			teams: null,
			loading: true,
		};
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getTeams();
			this.loading = false;
		},

		async getTeams() {
			var response = await MembershipService.getTeams();
			this.teams = response.data;
			// console.log(this.teams, "Teams");
		},
	},
	mounted() {
		this.start();
	},
};
</script>
