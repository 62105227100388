<template>
  <div>
    <div class="card border-0 shadow-sm" id="blog-menu">
      <div class="card-header bg-white border-0">
        <div class="card-title mb-0"><h5>Menú</h5></div>
      </div>
      <div class="card-body p-2">
        <div class="item-link-group">
          <button
            id="id-articles"
            class="btn-link-menu nav-link"
            @click="submit('articles')"
          >
            <i class="fa fa-file"></i>
            <div class="d-inline">Artículos</div>
          </button>
        </div>
        <div class="item-link-group">
          <button
            id="id-tags"
            class="btn-link-menu nav-link"
            @click="submit('tags')"
          >
            <i class="fa fa-tag"></i>
            <div class="d-inline">Etiquetas</div>
          </button>
        </div>
        <div class="item-link-group">
          <button
            id="id-categories"
            class="btn-link-menu nav-link"
            @click="submit('categories')"
          >
            <i class="fa fa-edit"></i>
            <div class="d-inline">Categorías</div>
          </button>
        </div>
        <div class="item-link-group">
          <button
            id="id-subcategories"
            class="btn-link-menu nav-link"
            @click="submit('subcategories')"
          >
            <i class="fa fa-edit"></i>
            <div class="d-inline">Subcategorías</div>
          </button>
        </div>
        <div class="item-link-group">
          <button
            id="id-topics"
            class="btn-link-menu nav-link"
            @click="submit('topics')"
          >
            <i class="fa fa-pen"></i>
            <div class="d-inline">Temáticas</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#blog-menu .nav-link {
  color: #000;
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 5px;
}
#blog-menu .nav-link:hover {
  background: rgba(255, 155, 4, 0.9);
  color: white;
}

#blog-menu .nav-link.active {
  background: rgb(255, 155, 4);
  color: white;
}
</style>

<script>
export default {
  name: "NavbarBlog",
  emits: ["selectOption"],
  methods: {
    start() {
      if (localStorage.getItem("menu-blog") != null) {
        var option = localStorage.getItem("menu-blog");
        this.submit(option);
      } else {
        this.submit("articles");
      }
    },

    resetOptions() {
      window.$("#id-articles").removeClass("active");
      window.$("#id-tags").removeClass("active");
      window.$("#id-categories").removeClass("active");
      window.$("#id-subcategories").removeClass("active");
      window.$("#id-topics").removeClass("active");
    },

    submit(option) {
      this.resetOptions();
      localStorage.setItem("menu-blog", option);
      window.$("#id-" + option).addClass("active");
      this.$emit("selectOption", option);
    },
  },
  mounted() {
    this.start();
  },
};
</script>
