import api from "./api";

class CountryService {
  async getAllCountries() {
    return await api
      .get("/countries/list")
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }
}

export default new CountryService();
