<template>
  <button
    class="btn btn-dark"
    data-bs-toggle="modal"
    data-bs-target="#modalAddCategory"
  >
    <i class="fas fa-plus me-2"></i>Añadir
  </button>

  <!-- Modal Add Category -->
  <div
    class="modal fade"
    id="modalAddCategory"
    tabindex="-1"
    aria-labelledby="modalAddCategory"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down">
      <div class="modal-content">
        <form @submit="add">
          <div class="modal-header">
            <h5 class="modal-title">Añadir categoría</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="form-group mb-3">
              <label class="form-label">Nombre</label>
              <input
                type="text"
                class="form-control"
                v-model="addCategory.name"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Temática</label>
              <select
                class="form-select form-control"
                v-model="addCategory.topic"
              >
                <option :key="t" v-for="t in topics" :value="t.UUID">
                  {{ t.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              id="submitAddCategory"
              type="submit"
              class="btn btn-primary"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryService from "@/services/category.service";
import TopicService from "@/services/topic.service";

import Swal from "sweetalert2";

export default {
  name: "AddCategory",
  data() {
    return {
      category: [],
      addCategory: {
        name: "",
        topic: "",
      },
      topics: [],
    };
  },
  methods: {
    async getTopics() {
      this.topics = await TopicService.getAllTopics();
      this.topics = this.topics.data;
    },
    async add(evt) {
      evt.preventDefault();
      window.$("#submitAddCategory").empty();
      window
        .$("#submitAddCategory")
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      let response = await CategoryService.addCategory({
        name: this.addCategory.name,
        topic: this.addCategory.topic,
      });

      window.$("#submitAddCategory").empty();
      window.$("#submitAddCategory").append("Guardar");

      if (response.status == 201) {
        this.name = null;
        // window.$('#modalAddCategory').modal('hide');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.go();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "¡Algo ha ido mal! Por favor, inténtalo de nuevo. Si el problema persiste contacta con el equipo de Soporte Técnico",
        });
      }
    },
  },
  created() {
    this.getTopics();
  },
};
</script>
