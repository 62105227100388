<template>
  <td>
    <div>{{ tag.name }}</div>
  </td>
  <td>
    <button
      class="btn btn-sm btn-edit"
      data-bs-toggle="modal"
      :data-bs-target="'#modalEditTag' + id"
    >
      <i class="fas fa-edit"></i>
    </button>
    <button class="btn btn-sm btn-delete" @click="deleteTag()">
      <i class="fas fa-trash"></i>
    </button>
  </td>

  <!-- Modal Edit Tag -->
  <div
    class="modal fade"
    :id="'modalEditTag' + id"
    tabindex="-1"
    aria-labelledby="modalEditTag"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down">
      <div class="modal-content">
        <form @submit="update">
          <div class="modal-header">
            <h5 class="modal-title">Editar etiqueta</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="form-group mb-3">
              <label class="form-label">Nombre</label>
              <input
                type="text"
                class="form-control"
                v-model="updateTag.name"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              :id="'submitBtn' + id"
              type="submit"
              class="btn btn-primary"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import TagService from "@/services/tag.service";

// import Swal from 'sweetalert2'

export default {
  name: "TagRow",
  props: {
    tag: Object,
  },
  data() {
    return {
      id: this.tag.id,
      updateTag: {
        name: this.tag.name,
      },
    };
  },
  methods: {
    async update(evt) {
      evt.preventDefault();
      window.$("#submitBtn" + this.id).empty();
      window
        .$("#submitBtn" + this.id)
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );
      let response = await TagService.updateTag(this.tag.id, {
        name: this.updateTag.name,
      });
      window.$("#submitBtn" + this.id).empty();
      window.$("#submitBtn" + this.id).append("Guardar");
      if (response.status == 200) {
        (this.name = this.updateTag.name),
          window.$("#modalEditTag" + this.id).modal("hide");
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
      }
    },
    async deleteTag() {
      let response = await TagService.deleteTag(this.tag.UUID);
      if (response.status == 200 || response.status == 204) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha borrado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
      }
      this.$router.go();
    },
  },
};
</script>
