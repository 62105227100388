<template>
  <div class="container py-5 text-black">
    <h1 class="text-uppercase mb-4">Política de privacidad</h1>
    <p class="mb-4">
      A continuación, le informamos sobre la Política Privacidad que se aplicará
      en aquellos casos en los que el interesado a través de la navegación por
      el sitio web o de la aplicación móvil (en adelante, la “Plataforma”) nos
      facilite sus datos de carácter personal. Queremos informarle de nuestra
      Política de Privacidad actualizada y del destino ofrecido a los datos
      personales que nos facilita, incluyendo aquellos que recabamos por el
      hecho de navegar en nuestras Plataformas.
    </p>
    <ol class="list-group list-group-flush">
      <li class="list-group-item">
        <h4>IDENTIFICACIÓN DEL RESPONSABLE</h4>
        <ul>
          <li class="m-2">
            Titular: Fundación Bertín Osborne (en adelante, la “Fundación” o el
            “Responsable”)
          </li>
          <li class="m-2">
            Domicilio social: Carretera de Torreblanca a Mairena del Alcor,
            Hacienda de San José, Alcalá de Guadaira, C.P. 41500 Sevilla.
          </li>
          <li class="m-2">N.I.F.: G-91838888</li>
          <li class="m-2">Registro Público: 1234 SND</li>
          <li class="m-2">E-mail: info@fundacionbertinosborne.org</li>
          <li class="m-2">Teléfono: […]</li>
        </ul>
      </li>
      <li class="list-group-item">
        <h4>INFORMACIÓN Y CONSENTIMIENTO</h4>
        <p>
          De conformidad con lo dispuesto en el Reglamento (UE) 679/2016 del
          Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la
          protección de las personas físicas en lo que respecta al tratamiento
          de datos personales y a la libre circulación de estos datos (en
          adelante, “RGPD”) y en la Ley Orgánica 3/2018, de 5 de diciembre, de
          Protección de Datos y Garantía de los Derechos Digitales (en adelante,
          “LOPDGDD”), mediante la aceptación de la presente Política de
          Privacidad el interesado web (en adelante, el “Interesado”) PRESTA SU
          CONSENTIMIENTO informado, expreso, libre e inequívoco para tratar los
          datos personales recabados a través de la Plataforma, ya sea a través
          de formularios habilitados al efecto o bien a través de la información
          recabada por las cookies.
        </p>
        <p>
          Asimismo, el Interesado consiente que estos datos sean tratados por el
          Responsable para prestar sus servicios aplicando las medidas
          organizativas y de seguridad requeridas por el RGPD.
        </p>
        <p>
          Todos los datos personales solicitados al Interesado en la Plataforma
          son obligatorios, no siendo posible prestar el servicio sin el
          correspondiente tratamiento de datos por parte del Responsable. Ello,
          no obstante, la navegación en la Plataforma es gratuita.
        </p>
        <p>
          El Interesado garantiza que los datos personales facilitados al
          Responsable son ciertos y exactos, con lo que el Responsable no se
          hará responsable de ninguna incidencia derivada de la inexactitud o
          falsedad de la información proporcionada por los Interesados, más allá
          de la posibilidad que tiene el Interesado de ejercitar su derecho de
          rectificación.
        </p>
        <p>
          En caso de que el Interesado nos facilite datos de terceras personas,
          como responsable o encargado del tratamiento, será responsable de
          cumplir con toda la normativa de protección de datos aplicable.
          Además, el Interesado garantiza que ha obtenido todos los
          consentimientos, autorizaciones y/o aprobaciones legalmente necesarias
          antes de incluir datos personales de terceros en la Plataforma.
        </p>
      </li>
      <li class="list-group-item">
        <h4>PROCEDENCIA, FINALIDAD Y BASE JURÍDICA</h4>

        <p>
          Los datos personales del Interesado, o de terceros, que sean cedidos
          por el Interesado, serán tratados de forma confidencial y quedarán
          incorporados a la correspondiente actividad de tratamiento del
          Responsable. Las actividades del tratamiento llevadas a cabo por el
          Responsable son la siguientes:
        </p>
        <!-- TABLA 1 -->
        <div class="container py-4">
          <div class="row">
            <div class="col-md-3 py-4"><strong>Actividad</strong></div>
            <div class="col-md-9 py-4">
              <strong>Registro en las plataformas</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Procedencia</div>
            <div class="col-md-9">
              Del propio Interesado a través del formulario de registro
              disponible en la Plataforma.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Base jurídica</div>
            <div class="col-md-9">
              Reglamento General de Protección de Datos, en particular: Art.
              6.1.a: consentimiento del interesado para uno o varios fines
              específicos. Art 6.1 b: petición de medidas precontractuales.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Fines tratamiento</div>
            <div class="col-md-9">
              Identificación del Interesado para obtener el acceso a la
              Plataforma y gestionar el alta y baja de la misma.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Colectivo</div>
            <div class="col-md-9">
              Personas físicas, incluidas las representantes de personas
              jurídicas, Interesados de la Plataforma.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Categoría de datos</div>
            <div class="col-md-9">
              Nombre, apellidos, fecha de nacimiento, dirección de correo
              electrónico, dirección postal y teléfono.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Destinatarios</div>
            <div class="col-md-9">
              Está prevista la cesión de datos a terceros con el consentimiento
              inequívoco e informado del Interesado.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Transferencia internacional</div>
            <div class="col-md-9">
              No está prevista la transferencia internacional de datos.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Plazos de conservación</div>
            <div class="col-md-9">
              Los datos serán conservados hasta que el Interesado retire su
              consentimiento o haya acabado la finalidad para la que fueron
              recabados, salvo que los datos deban mantenerse por una obligación
              legal.
            </div>
          </div>
        </div>
        <!-- TABLA 2 -->
        <div class="container py-4">
          <div class="row">
            <div class="col-md-3 py-4"><strong>Actividad</strong></div>
            <div class="col-md-9 py-4">
              <strong>Contacto vía e-mail</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Procedencia</div>
            <div class="col-md-9">
              Del propio Interesado a través del espacio Contacto mediante el
              envío de correos electrónicos o llamada telefónica a la dirección
              y número de teléfono indicados en la Plataforma.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Base jurídica</div>
            <div class="col-md-9">
              Reglamento General de Protección de Datos, en particular: Art 6.1
              b: petición de medidas precontractuales.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Fines tratamiento</div>
            <div class="col-md-9">
              Atender y responder las consultas solicitadas por el Interesado.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Colectivo</div>
            <div class="col-md-9">
              Personas físicas, incluidas las representantes de personas
              jurídicas, Interesados de la Plataforma.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Categoría de datos</div>
            <div class="col-md-9">
              Nombre y apellidos, correo electrónico y número de teléfono,
              asunto, así como el resto de los datos que el propio Interesado
              indique en el cuerpo del mensaje.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Destinatarios</div>
            <div class="col-md-9">
              Está prevista la cesión de datos a terceros con el consentimiento
              inequívoco e informado del Interesado.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Transferencia internacional</div>
            <div class="col-md-9">
              No está prevista la cesión de datos a terceros, salvo obligación
              legal o previo consentimiento inequívoco e informado del
              Interesado.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Plazos de conservación</div>
            <div class="col-md-9">
              Los datos serán conservados durante el plazo estrictamente
              necesario para cumplir con el fin para el cual fueron recabados.
              En cualquier caso, el plazo máximo de conservación será de 1 año.
            </div>
          </div>
        </div>
        <!-- TABLA 3 -->
        <div class="container py-4">
          <div class="row">
            <div class="col-md-3 py-4"><strong>Actividad</strong></div>
            <div class="col-md-9 py-4">
              <strong>Envío de comunicaciones comerciales propias</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Procedencia</div>
            <div class="col-md-9">
              Del propio Interesado al llevar a cabo la suscripción a través de
              la Plataforma.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Base jurídica</div>
            <div class="col-md-9">
              Reglamento General de Protección de Datos, en particular:- Art.
              6.1.f: el tratamiento es necesario para la satisfacción de
              intereses legítimos perseguidos por el responsable del tratamiento
              o por un tercero, siempre que sobre dichos intereses no
              prevalezcan los intereses o los derechos y libertades
              fundamentales del interesado.- Artículo 21 de la Ley 34/2002, de
              11 de julio, de servicios de la sociedad de la información y de
              comercio electrónico.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Fines tratamiento</div>
            <div class="col-md-9">
              Envío de información relativa a los nuevos eventos, servicios,
              etc.., que ofrezca la Fundación.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Colectivo</div>
            <div class="col-md-9">
              Personas físicas, incluidas las representantes de personas
              jurídicas, Interesados de la Plataforma.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Categoría de datos</div>
            <div class="col-md-9">
              Nombre, apellidos, fecha de nacimiento, dirección de correo
              electrónico, dirección postal, teléfono y preguntas para
              segmentación con fines de mercadotecnia directa.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Destinatarios</div>
            <div class="col-md-9">
              No está prevista la cesión de datos a terceros, salvo obligación
              legal o previo consentimiento inequívoco e informado del
              Interesado.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Transferencia internacional</div>
            <div class="col-md-9">
              No está prevista la transferencia internacional de datos.
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">Plazos de conservación</div>
            <div class="col-md-9">
              Los datos serán conservados hasta que el Interesado retire su
              consentimiento o haya acabado la finalidad para la que fueron
              recabados, salvo que los datos deban mantenerse por una obligación
              legal.
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <h4>DATOS DEL INTERESADO</h4>
        <p>
          El interesado garantiza que los datos aportados son verdaderos,
          exactos, completos y actualizados, siendo este responsable de
          cualquier daño o perjuicio, directo o indirecto, que pudiera
          ocasionarse como consecuencia del incumplimiento de tal obligación,
          más allá de la posibilidad que tiene el Interesado de ejercitar su
          derecho de rectificación.
        </p>
        <p>
          En caso de que los datos aportados pertenecieran a un tercero, el
          Interesado garantiza que ha informado a dicho tercero de los aspectos
          contenidos en este documento y obtenido su autorización para facilitar
          sus datos al responsable para los fines anteriormente señalados.
        </p>
        <p>
          El Responsable no puede garantizar la absoluta invulnerabilidad de los
          sistemas y, por tanto, no asume ninguna responsabilidad por los daños
          y perjuicios derivados de alteraciones que terceros puedan causar en
          los sistemas informáticos, documentos electrónicos o ficheros.
        </p>
      </li>
      <li class="list-group-item">
        <h4>POLÍTICA DE COOKIES</h4>
        <p>
          De conformidad con lo dispuesto en el RGPD y en la Ley 34/2002, de 11
          de julio, de servicios de la sociedad de la información y de comercio
          electrónico, todos los datos de carácter personal obtenidos mediante
          las cookies durante la utilización de la Plataforma serán tratados de
          conformidad con lo dispuesto en la Política de Cookies.
        </p>
      </li>
      <li class="list-group-item">
        <h4>EJERCICIO DE DERECHOS</h4>
        <p>
          De conformidad con lo dispuesto en el RGPD, le informamos que puede
          ejercer sus derechos de acceso, rectificación, supresión, oposición,
          portabilidad de los datos y limitación del tratamiento directamente
          ante el Responsable.
        </p>
        <p>
          Para el ejercicio de dichos derechos, el Interesado podrá enviar un
          mensaje al efecto, indicando nombre, apellidos, e-mail y número de
          D.N.I. o pasaporte a la siguiente dirección de correo electrónico:
          info@fundacionbertinosborne.org.
        </p>
        <p>
          El Interesado también podrá ejercer sus derechos mediante la
          presentación de una reclamación ante la Agencia Española de Protección
          de Datos.
        </p>
      </li>
      <li class="list-group-item">
        <h4>ACEPTACIÓN DE LA POLÍTICA DE PRIVACIDAD</h4>
        <p>
          El Interesado RECONOCE Y ACEPTA HABER LEÍDO Y COMPRENDIDO la presente
          Política de Privacidad cuyo contenido constituye la totalidad del
          acuerdo entre el Interesado y el Responsable con respecto al uso y
          tratamiento de su información personal. El Interesado expresamente
          acepta obligarse en los términos de la presente Política de
          Privacidad, en toda su extensión y alcance, sin exceptuar cualquiera
          de sus disposiciones.
        </p>
        <p>
          Será indispensable la aceptación expresa del Presente cuando el
          Usuario se registre a través de la Plataforma rellenando el
          correspondiente formulario de registro.
        </p>
      </li>
    </ol>
  </div>
</template>

<style>
.list-group-item {
  background: transparent !important;
}
</style>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>
