import UserService from "../services/user.service";

const data = JSON.parse(localStorage.getItem("user"));
const initialState = data
  ? { status: { loggedIn: true }, data }
  : { status: { loggedIn: false }, data: null };

export const user = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, data) {
      return UserService.getCurrentUser(data).then(
        (data) => {
          commit("userLoadingSuccess", data);
          return Promise.resolve(data);
        },
        (error) => {
          commit("userLoadingFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      commit("logout");
    },
    updateUser({ commit }, data) {
      return UserService.updateLocalUser(data).then(
        (data) => {
          commit("userUpdateSuccess", data);
          return Promise.resolve(data);
        },
        (error) => {
          commit("userUpdateFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    userLoadingSuccess(state, data) {
      state.data = data;
    },
    userLoadingFailure(state, data) {
      state.data = null;
    },
    userUpdateSuccess(state, data) {
      state.data = data;
    },
    userUpdateFailure(state, data) {
      state.data = null;
    },
    logout(state) {
      state.data = null;
    },
  },
};
