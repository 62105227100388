<template>
	<div class="container-fluid pt-3 pt-md-5">
		<div class="row">
			<div class="col-lg-3 col-md-4">
				<NavbarMembership />
			</div>
			<div class="col-lg-9 col-md-8 px-0 px-md-2">
				<div v-if="!loading" class="col-12">
					<div class="card mb-5">
						<div class="card-body p-md-5 p-3">
							<h2
								v-if="!newSuscriptionToggle"
								class="fs-2x fw-bold mb-5 text-center"
							>
								Mi suscripción
							</h2>
							<h1
								v-if="newSuscriptionToggle"
								class="fs-2x fw-bold mb-5 text-center"
							>
								Quiero hacerme socio
							</h1>
							<div
								v-if="
									subscription == null ||
									subscription.length == 0 ||
									Object.keys(this.subscription).length == 0
								"
							>
								<div
									class="text-center"
									v-if="!newSuscriptionToggle"
								>
									<p class="text-muted">
										No tienes ninguna suscripción activa
										aún.
									</p>
									<p class="text-muted mb-5">
										Añade una nueva suscripción recurrente
										pulsando en el siguiente botón.
									</p>
									<button
										@click="newSuscriptionBtn(true)"
										class="btn btn-fbo"
									>
										Quiero hacerme socio
									</button>
								</div>
							</div>
							<form
								v-if="newSuscriptionToggle"
								@submit.prevent="createSubscription()"
							>
								<div class="d-flex mb-5 price-group-selector">
									<div
										style="width: 50%"
										class="p-3 text-center radio-item left d-flex flex-column justify-content-center"
										:class="{
											active: isPriceOption1Active,
										}"
										@click="priceOptionToggle(1)"
									>
										<div class="fs-1 rounded-circle price">
											<span>2€</span>
										</div>
									</div>
									<div
										style="width: 50%"
										class="p-3 text-center d-flex flex-column justify-content-center radio-item right"
										:class="{
											active: isPriceOption2Active,
										}"
										@click="priceOptionToggle(2)"
									>
										<div class="fs-3 mb-3">
											Otra cantidad
										</div>

										<div
											class="input-group mb-3"
											style="width: 250px; margin: 0 auto"
										>
											<input
												type="number"
												min="2"
												class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
												v-model="other_quanity"
												@change="updateQuantity"
											/>
											<span class="input-group-text px-5"
												>€</span
											>
										</div>
									</div>
								</div>
								<div class="row mb-3">
									<div class="col-md-6">
										<label class="form-label">Tipo</label>
										<div>
											<label>
												<input
													type="radio"
													v-model="
														newMember.is_business
													"
													value="true"
												/>
												Empresa
											</label>
										</div>
										<div>
											<label>
												<input
													type="radio"
													v-model="
														newMember.is_particular
													"
													value="true"
												/>
												Particular
											</label>
										</div>
									</div>
								</div>
								<div
									v-if="newMember.is_business"
									class="row mb-3"
								>
									<div class="col-md-6">
										<label class="form-label"
											>Nombre de la Empresa</label
										>
										<input
											type="text"
											class="form-control"
											v-model="newMember.business_name"
										/>
									</div>
									<div class="col-md-6">
										<label for="" class="form-label"
											>CIF</label
										>
										<input
											type="text"
											class="form-control"
											v-model="newMember.document_cif"
										/>
									</div>
								</div>
								<div class="row mb-3">
									<div class="col-md-6">
										<label class="form-label">Nombre</label>
										<input
											type="text"
											class="form-control"
											v-model="newMember.first_name"
										/>
									</div>
									<div class="col-md-6">
										<label for="" class="form-label"
											>Apellidos</label
										>
										<input
											type="text"
											class="form-control"
											v-model="newMember.last_name"
										/>
									</div>
								</div>
								<div class="row mb-3">
									<div class="col-md-6">
										<label for="" class="form-label"
											>Email</label
										>
										<input
											type="email"
											class="form-control"
											v-model="newMember.email"
										/>
									</div>
									<div class="col-md-6">
										<label for="" class="form-label"
											>Teléfono</label
										>
										<input
											type="text"
											class="form-control"
											v-model="newMember.telephone"
										/>
									</div>
								</div>
								<div class="row mb-3">
									<!-- <div class="col-md-8">
										<label for="" class="form-label"
											>Dirección</label
										>
										<input
											type="text"
											class="form-control"
											v-model="newMember.address"
											required
										/>
									</div> -->
									<div class="col-md-4">
										<label for="" class="form-label"
											>Código Postal</label
										>
										<input
											type="text"
											class="form-control"
											v-model="newMember.postal_code"
											required
										/>
									</div>
									<div class="col-md-4">
										<label for="" class="form-label"
											>Provincia</label
										>
										<select
											required
											name="provincia"
											class="form-control"
											v-model="newMember.region"
										>
											<option
												:key="item"
												v-for="item in regions"
												:value="item.id"
											>
												{{ item.name }}
											</option>
										</select>
									</div>
									<div class="col-md-4">
										<label for="" class="form-label"
											>País</label
										>
										<select
											required
											name="provincia"
											class="form-control"
											v-model="newMember.country"
										>
											<option
												:key="item"
												v-for="item in countries"
												:value="item.id"
											>
												{{ item.name }}
											</option>
										</select>
									</div>
								</div>
								<div class="row mb-5">
									<div
										v-if="!newMember.is_business"
										class="col-md-6"
									>
										<label for="" class="form-label"
											>DNI / NIE / PASAPORTE</label
										>
										<input
											type="text"
											class="form-control"
											v-model="newMember.document"
										/>
									</div>

									<div class="col-md-6 mb-5">
										<label for="" class="form-label"
											>Tipo de suscripción</label
										>
										<select
											required
											name="provincia"
											class="form-control"
											v-model="newMember.subscription"
											@change="updateQuantity"
										>
											<option value="monthly">
												Mensual
											</option>
											<!-- <option value="annual">
												Anual
											</option> -->
										</select>
									</div>
									<div class="col-md-12">
										<div
											class="form-check"
											style="padding-left: 3rem"
										>
											<input
												class="form-check-input"
												type="checkbox"
												value=""
												id="flexCheckDefault"
												style="margin-left: -3em"
											/>
											<label
												class="form-check-label"
												for="flexCheckDefault"
											>
												He leído y acepto los
												<router-link
													to="/legal/terms-and-conditions"
													target="_blank"
													>términos y
													condiciones</router-link
												>
											</label>
										</div>
									</div>
								</div>
								<div class="text-center">
									<p class="muted lead mb-5">
										Has seleccionado una aportación
										periódica de {{ message_total }}
									</p>
									<button class="btn btn-fbo" type="submit">
										Continuar con el método de pago
										<i
											class="fa-duotone fa-arrow-right ms-3"
										></i>
									</button>
								</div>
							</form>
							<div
								v-if="
									Object.keys(this.subscription).length !=
										0 && !newSuscriptionToggle
								"
							>
								<div class="subscription-info">
									<div class="row mb-5">
										<div class="col-md-6">
											<h6 class="fw-bold">
												{{
													subscription.first_name +
													" " +
													subscription.last_name
												}}
											</h6>
											<div>
												<small>{{
													subscription.email
												}}</small>
											</div>
											<div>
												<small>{{
													subscription.telephone
												}}</small>
											</div>
										</div>
										<div class="col-md-6">
											<h6 class="fw-bold mb-3">
												Detalles suscripción<span
													v-if="subscription.enabled"
													class="badge badge-light-success fs-6 ms-3"
													>Activo</span
												>
												<span
													v-else
													class="badge badge-light-danger fs-6 ms-3"
													>Inactivo</span
												>
											</h6>
											<div
												class="fw-bold"
												v-if="
													subscription.subscription ==
													'monthly'
												"
											>
												{{
													formatCurrency(
														subscription.quantity /
															100
													) + "/mes"
												}}
											</div>
											<div
												class="fw-bold"
												v-if="
													subscription.subscription ==
													'annual'
												"
											>
												{{
													subscription.quantity +
													"/año"
												}}
											</div>
											<div v-if="!subscription.enabled">
												<button
													class="btn btn-fbo btn-sm mt-3"
													@click="
														newSuscriptionBtn(true)
													"
												>
													Activar suscripción
												</button>
											</div>
										</div>
									</div>

									<div class="table-responsive">
										<h6>Historial de pagos</h6>
										<table class="table">
											<thead>
												<tr>
													<th>
														<small>
															Referencia</small
														>
													</th>
													<th>
														<small>Estado</small>
													</th>
													<th>
														<small>Cantidad</small>
													</th>
													<th>
														<small
															>Fecha del
															pago</small
														>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr
													:key="item"
													v-for="item in payments"
												>
													<td>
														<small
															v-if="item.Order"
														>
															{{
																item.Order
															}}</small
														>
														<small v-else>
															{{
																"#" +
																item.payment_id
															}}</small
														>
													</td>
													<td>
														<span
															v-if="
																item.Response ==
																	'OK' &&
																item.TransactionType ==
																	1
															"
															class="badge badge-light-success fs-7"
															>Confirmado</span
														>
														<span
															v-else-if="
																item.Response ==
																	'OK' &&
																item.TransactionType ==
																	2
															"
															class="badge badge-light-info fs-7"
															>Devolución</span
														>
														<span
															v-else-if="
																item.Response ==
																'KO'
															"
															class="badge badge-light-danger fs-7"
															>Error en el
															pago</span
														>
														<span
															v-else
															class="badge badge-light-info fs-7"
															>Error</span
														>
													</td>
													<td>
														<small
															v-if="item.Amount"
															>{{
																formatCurrency(
																	item.Amount /
																		100
																)
															}}</small
														>
														<small v-else>{{
															formatCurrency(
																subscription.quantity /
																	100
															)
														}}</small>
													</td>
													<td>
														<small
															v-if="
																item.BankDateTime
															"
															>{{
																item.BankDateTime.substring(
																	6,
																	8
																) +
																"/" +
																item.BankDateTime.substring(
																	4,
																	6
																) +
																"/" +
																item.BankDateTime.substring(
																	0,
																	4
																) +
																" - " +
																item.BankDateTime.substring(
																	8,
																	10
																) +
																":" +
																item.BankDateTime.substring(
																	10,
																	12
																)
															}}</small
														>
														<small
															v-else
															class="text-center"
															>-</small
														>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="text-center py-5">
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
					<div>Cargando información</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NavbarMembership from "@/components/Layout/NavbarMembership.vue";

import MembershipService from "@/services/membership.service.js";
import CountryService from "@/services/country.service";
import RegionService from "@/services/region.service";
import { boolean } from "yup/lib/locale";

import Swal from "sweetalert2";

export default {
	name: "MySubscriptionView",
	components: {
		NavbarMembership,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	watch: {
		"newMember.is_business": function (newValue) {
			if (newValue) {
				this.newMember.is_particular = false;
			}
		},
		"newMember.is_particular": function (newValue) {
			if (newValue) {
				this.newMember.is_business = false;
			}
		},
	},
	data() {
		return {
			loading: true,
			user: this.$store.state.user.data,
			subscription: null,
			payments: [],
			newSuscriptionToggle: false,
			isPriceOption1Active: true,
			isPriceOption2Active: false,
			newMember: {
				first_name: this.$store.state.user.data.first_name,
				last_name: this.$store.state.user.data.last_name,
				email: this.$store.state.user.data.email,
				telephone: this.$store.state.user.data.telephone,
				country: 66,
				subscription: "monthly",
				quantity: 2,
				document: "",

				document_cif: "",
				is_particular: true,
				is_business: false,
				business_name: "",

				address: "",
				postal_code: "",
				region: null,
			},
			countries: null,
			regions: null,
			message_total: "2€/mes",
			other_quanity: 5,
		};
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getMySuscription();
			if (
				this.subscription != null &&
				this.subscription != "" &&
				Object.keys(this.subscription).length != 0
			) {
				await this.getPayments();
			}
			await this.getCountries();
			await this.getRegions();
			this.loading = false;
		},

		async getMySuscription() {
			var response = await MembershipService.getUserSubscription(
				this.user.UUID
			);
			this.subscription = response.data;
			console.log(this.subscription, "Suscriptions");
		},

		async getPayments() {
			var response = await MembershipService.getSubscriptionPayments(
				this.subscription.UUID
			);
			this.payments = response.data;
			console.log(this.payments, "Payments Orders");
		},

		async getCountries() {
			var response = await CountryService.getAllCountries();
			this.countries = response.data;
			// console.log(this.countries, "Countries");
		},

		async getRegions() {
			var response = await RegionService.getAllRegions();
			this.regions = response.data;
			// console.log(this.regions, "Regions");
		},

		newSuscriptionBtn(boolean) {
			this.newSuscriptionToggle = boolean;
		},

		async createSubscription() {
			console.log("Input data :", this.newMember);

			window.$("#submitReferralBtn").empty();
			window.$("#submitReferralBtn").attr("disabled", "true");
			window
				.$("#submitReferralBtn")
				.append(
					'<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
				);
			var response = await MembershipService.createUserSubscription(
				this.newMember
			);
			console.log(response);

			if (response.status == 201) {
				this.$router.push(
					"/payments/paycomet/" + response.data.payment_id
				);
			} else {
				console.log(response);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ha ocurrido un error. Por favor, vuelve a intentarlo. Si el error persiste ponte en contacto con socios@fundacionbertinosborne.org",
					confirmButtonColor: "#ff9b04",
				});
			}

			window.$("#submitReferralBtn").empty();
			window
				.$("#submitReferralBtn")
				.append("Continuar con el método de pago");
			window.$("#submitReferralBtn").removeAttr("disabled");
		},

		priceOptionToggle(num) {
			if (num == 2) {
				this.isPriceOption1Active = false;
				this.isPriceOption2Active = true;
			} else {
				this.isPriceOption1Active = true;
				this.isPriceOption2Active = false;
			}
			this.updateQuantity();
		},

		updateQuantity() {
			var price = 2;
			if (this.isPriceOption1Active) {
				price = 2;
			} else if (this.isPriceOption2Active) {
				price = this.other_quanity;
			}

			if (this.newMember.subscription == "monthly") {
				this.message_total = price + "€/mes";
			} else if (this.newMember.subscription == "annual") {
				price = price * 12;
				this.message_total = price + "€/año";
			}
			this.newMember.quantity = price;
		},

		formatCurrency(num) {
			// Create our number formatter.
			const formatter = new Intl.NumberFormat("es-ES", {
				style: "currency",
				currency: "EUR",

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});
			return formatter.format(num);
		},
	},
	created() {
		this.start();
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>
