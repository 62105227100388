import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuex from "vuex";

window.$ = window.jQuery = require("jquery");

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import * as bootstrap from "bootstrap";

window.bootstrap = bootstrap;

import "boxicons";

import setupInterceptors from "./services/setupInterceptors";
setupInterceptors(store);

import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import "v-calendar/style.css";

require("./index");

const $ = require("jquery");
window.$ = $;

createApp(App)
    .use(router)
    .use(store)
    .use(Vuex)
    .use(setupCalendar, {})
    .use("DatePicker", DatePicker)
    .use("Calendar", Calendar)
    .component("EasyDataTable", Vue3EasyDataTable)
    .mount("#wrapper");
