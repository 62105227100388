<template>
  <div>
    <div class="container-fluid">
      <div class="card-body p-4">
        <div class="table-responsive w-100 mt-2">
          <h3 style="display: inline; margin-right: 15px">Etiquetas</h3>
          <!--<AddInvoiceCharge v-if="!invoice.isClosed" :invoice="invoice" />-->
          <div class="card-body">
            <table class="table table-bordered settings-table mt-3">
              <thead>
                <tr class="">
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" :key="item" v-for="item in tags">
                  <TagRow :tag="item"></TagRow>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TagRow from "@/components/Blog/elements/Tags/TagRow.vue";

import TagService from "@/services/tag.service.js";

export default {
  name: "Tags",
  components: {
    TagRow: TagRow,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      tags: [],
    };
  },
  methods: {
    async getTags() {
      this.tags = await TagService.getAllTags();
      this.tags = this.tags.data;
      // console.log(this.tags)
    },
  },
  created() {
    this.getTags();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
