<template>
  <td>
    <div>{{ subcategory.name }}</div>
  </td>
  <td>
    <div v-if="subcategory.category != null">
      {{ subcategory.category.name }}
    </div>
  </td>
  <td>
    <div v-if="subcategory.topic != null">{{ subcategory.topic.name }}</div>
  </td>
  <td>
    <div v-if="subcategory.enabled">
      <span class="badge badge-success">Activado</span>
    </div>
    <div v-else><span class="badge badge-danger">Desactivado</span></div>
  </td>
  <td class="text-center">
    <button
      class="btn btn-outline-dark mb-2"
      data-bs-toggle="modal"
      :data-bs-target="'#modalEditSubCategory' + uuid"
    >
      <i class="fas fa-edit"></i>
    </button>
    <button class="btn btn-outline-danger" @click="deleteSubCategory()">
      <i class="fas fa-trash"></i>
    </button>
  </td>

  <!-- Modal Edit SubCategory -->
  <div
    class="modal fade"
    :id="'modalEditSubCategory' + uuid"
    tabindex="-1"
    aria-labelledby="modalEditCategory"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down">
      <div class="modal-content">
        <form @submit="update">
          <div v-if="!loading">
            <div class="modal-header">
              <h5 class="modal-title">Editar usuario</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              <div class="form-group mb-3">
                <label class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updateSubCategory.name"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Temática</label>
                <select
                  class="form-select form-control"
                  v-model="updateSubCategory.topic"
                >
                  <option :key="t" v-for="t in topics" :value="t.UUID">
                    {{ t.name }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Categoría</label>
                <select
                  class="form-select form-control"
                  v-model="updateSubCategory.category"
                >
                  <option :key="t" v-for="t in categories" :value="t.UUID">
                    {{ t.name }}
                  </option>
                </select>
              </div>

              <div class="form-group mb-3">
                <label class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="updateSubCategory.enabled"
                  />
                  <span class="form-check-label">Activado</span>
                </label>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
              <button
                :id="'submitBtn' + uuid"
                type="submit"
                class="btn btn-primary"
              >
                Guardar
              </button>
            </div>
          </div>
          <div v-else class="text-center py-5">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div>Cargando información</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SubCategoryService from "@/services/subcategory.service";
import CategoryService from "@/services/category.service";

import Swal from "sweetalert2";

export default {
  name: "SubCategoryRow",
  props: {
    subcategory: Object,
    topics: Array,
  },
  data() {
    return {
      uuid: this.subcategory.UUID,
      updateSubCategory: {
        name: this.subcategory.name,
        topic: "",
        category: "",
        enabled: this.subcategory.enabled,
      },
      loading: true,
    };
  },
  methods: {
    async start() {
      this.loading = true;

      if (this.subcategory.topic != null) {
        this.updateSubCategory.topic = this.subcategory.topic.UUID;
      }

      if (this.subcategory.category != null) {
        this.updateSubCategory.category = this.subcategory.category.UUID;
      }

      await this.getCategories();

      this.loading = false;
    },

    async getCategories() {
      this.categories = [];
      if (
        this.updateSubCategory.topic != null &&
        this.updateSubCategory.topic != ""
      ) {
        var response = await CategoryService.getAllCategories({
          topic: this.updateSubCategory.topic,
        });
        this.categories = response.data;
      }

      // console.log(this.categories, "Categories")
    },

    async update(evt) {
      evt.preventDefault();
      window.$("#submitBtn" + this.id).empty();
      window
        .$("#submitBtn" + this.id)
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      let response = await SubCategoryService.updateSubCategory(
        this.subcategory.UUID,
        {
          name: this.updateSubCategory.name,
          topic: this.updateSubCategory.topic,
          category: this.updateSubCategory.category,
          enabled: this.updateSubCategory.enabled,
        }
      );

      window.$("#submitBtn" + this.id).empty();
      window.$("#submitBtn" + this.id).append("Guardar");
      if (response.status == 200) {
        // this.name =  this.updateCategory.name,
        // window.$('#modalEditCategory'+this.uuid).modal('hide');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
        this.$router.go();
      }
    },
    async deleteSubCategory() {
      let response = await SubCategoryService.deleteSubCategory(
        this.subcategory.UUID
      );
      if (response.status == 200 || response.status == 204) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha borrado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
      }
      // this.$router.go()
    },
  },
  created() {
    this.start();
  },
};
</script>
