<template>
  <div class="container">
    <div class="card shadow-sm border-0 mt-5 mb-3">
      <div class="card-header bg-white">
        <h5>Usuarios</h5>
      </div>
      <div class="card-body">
        <div v-if="!loading" class="table-responsive">
          <table class="table table-bordered settings-table mt-3">
            <thead>
              <tr class="">
                <th>Nombre</th>
                <th>Apellidos</th>
                <th>Email</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr class="align-middle" :key="item" v-for="item in users">
                <UserRow :user="item"></UserRow>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center py-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div>Cargando información</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserRow from "@/components/Users/UserRow.vue";

import UserService from "@/services/user.service.js";

export default {
  name: "Users",
  components: {
    UserRow: UserRow,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      users: [],
      loading: true,
    };
  },
  methods: {
    async start() {
      this.loading = true;
      await this.getUsers();
      this.loading = false;
    },

    async getUsers() {
      var response = await UserService.getAllUsers();
      this.users = response.data;
      console.log(this.users);
    },
  },
  created() {
    this.start();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
