<template>
	<div class="container-fluid">
		<!-- <div class="row text-center mx-0">
			<div class="col-lg-12 p-0 p-0">
        <img
          src="../assets/img/dona2/DONA2_page-0001.jpg"
          style="width: 100%"
        />
      </div>
      <div class="col-lg-12 p-0">
        <img
          src="../assets/img/dona2/DONA2_page-0002.jpg"
          style="width: 100%"
        />
      </div>
      <div class="col-lg-12 p-0">
        <img
          src="../assets/img/dona2/DONA2_page-0003.jpg"
          style="width: 100%"
        />
      </div>
      <div class="col-lg-12 p-0">
        <img
          src="../assets/img/dona2/DONA2_page-0004.jpg"
          style="width: 100%"
        />
      </div>
      <div class="col-lg-12 p-0">
        <img
          src="../assets/img/dona2/DONA2_page-0005.jpg"
          style="width: 100%"
        />
      </div>
      <div class="col-lg-12 p-0">
        <img
          src="../assets/img/dona2/DONA2_page-0006.jpg"
          style="width: 100%"
        />
      </div>
      <div class="col-lg-12 p-0">
        <img
          src="../assets/img/dona2/DONA2_page-0007.jpg"
          style="width: 100%"
        />
      </div>
      <div class="col-lg-12 p-0">
        <img
          src="../assets/img/dona2/DONA2_page-0008.jpg"
          style="width: 100%"
        />
      </div>
      <div class="col-lg-12 p-0">
        <img
          src="../assets/img/dona2/DONA2_page-0009.jpg"
          style="width: 100%"
        />
      </div>
		</div> -->

		<!-- <div class="row text-center mx-0">
			<div class="col-lg-12 p-0">
				<iframe
					width="100%"
					height="500px"
					src="https://www.youtube.com/embed/jM4LELPteJI"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
			</div>
		</div> -->
    <div class="home-main-container">
      <div class="col-lg-6 home-main-container_text">
        <h1>La Iniciativa</h1>
        <p>Esta campaña es una iniciativa de la Fundación Bertín Osborne, para seguir acompañando a las familias de niños con discapacidad.</p>
        <p>Agárranos fuerte de la mano y colabora para convertir juntos sus dificultades en oportunidades de crecimiento y aprendizaje personal.</p>
        <p>Necesitamos conseguir compañeros de viaje que se sumen y nos ayuden a cubrir los gastos de los servicios que les ofrecemos.</p>
        <p>Con tu aportación, desde 2€ al mes, mejoras el día a día de una familia.</p>
      </div>
      <div class="col-lg-6 p-0 home-main-container_img">
          <img src="../assets/img/home-img.png" alt="smiling woman carrying a kid">
      </div>
    </div>
    
	</div>
  <div class="col-lg-12 home-banner-container">
    <h1>¡Gracias por todo!</h1>
  </div>
</template>

<style></style>

<script>
export default {
	name: "Home",
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>
