<template>
  <td>
    <div>{{ category.name }}</div>
  </td>
  <td>
    <div>{{ category.topic.name }}</div>
  </td>
  <td>
    <div v-if="category.enabled">
      <span class="badge badge-success">Activado</span>
    </div>
    <div v-else><span class="badge badge-danger">Desactivado</span></div>
  </td>
  <td>
    <button
      class="btn btn-sm btn-edit"
      data-bs-toggle="modal"
      :data-bs-target="'#modalEditCategory' + uuid"
    >
      <i class="fas fa-edit"></i>
    </button>
    <button class="btn btn-sm btn-delete" @click="deleteCategory()">
      <i class="fas fa-trash"></i>
    </button>
  </td>

  <!-- Modal Edit Category -->
  <div
    class="modal fade"
    :id="'modalEditCategory' + uuid"
    tabindex="-1"
    aria-labelledby="modalEditCategory"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down">
      <div class="modal-content">
        <form @submit="update">
          <div class="modal-header">
            <h5 class="modal-title">Editar usuario</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="form-group mb-3">
              <label class="form-label">Nombre</label>
              <input
                type="text"
                class="form-control"
                v-model="updateCategory.name"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Temática</label>
              <select
                class="form-select form-control"
                v-model="updateCategory.topic"
              >
                <option :key="t" v-for="t in topics" :value="t.UUID">
                  {{ t.name }}
                </option>
              </select>
            </div>
            <div class="form-group mb-3">
              <label class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="updateCategory.enabled"
                />
                <span class="form-check-label">Activado</span>
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              :id="'submitBtn' + uuid"
              type="submit"
              class="btn btn-primary"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryService from "@/services/category.service";
import TopicService from "@/services/topic.service";

import Swal from "sweetalert2";

export default {
  name: "CategoryRow",
  props: {
    category: Object,
  },
  data() {
    return {
      uuid: this.category.UUID,
      updateCategory: {
        name: this.category.name,
        topic: this.category.topic.UUID,
        enabled: this.category.enabled,
      },
      topics: [],
    };
  },
  methods: {
    async getTopics() {
      this.topics = await TopicService.getAllTopics();
      this.topics = this.topics.data;
      // console.log(this.topics)
    },
    async update(evt) {
      evt.preventDefault();
      window.$("#submitBtn" + this.id).empty();
      window
        .$("#submitBtn" + this.id)
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      let response = await CategoryService.updateCategory(this.category.UUID, {
        name: this.updateCategory.name,
        topic: this.updateCategory.topic,
        enabled: this.updateCategory.enabled,
      });

      window.$("#submitBtn" + this.id).empty();
      window.$("#submitBtn" + this.id).append("Guardar");
      if (response.status == 200) {
        // this.name =  this.updateCategory.name,
        // window.$('#modalEditCategory'+this.uuid).modal('hide');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
        this.$router.go();
      }
    },
    async deleteCategory() {
      let response = await CategoryService.deleteCategory(this.category.UUID);
      if (response.status == 200 || response.status == 204) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha borrado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
      }
      // this.$router.go()
    },
  },
  created() {
    this.getTopics();
  },
};
</script>
