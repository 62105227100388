<template>
  <div class="card-body p-4">
    <div class="row">
      <div class="col-md-4">
        <NavbarForums @selectOption="selectOption" />
      </div>
      <div class="col-md-8">
        <div v-if="option == 'categories'">
          <ForumCategories />
        </div>
        <div v-else-if="option == 'conversations'">
          <ForumConversations />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarForums from "@/components/Layout/NavbarForums.vue";
import ForumCategories from "@/components/Forums/views/Categories.vue";
import ForumConversations from "@/components/Forums/views/Conversations.vue";

import UserService from "@/services/user.service.js";

export default {
  name: "Forums",
  components: {
    NavbarForums: NavbarForums,
    ForumCategories: ForumCategories,
    ForumConversations: ForumConversations,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      users: [],
      option: "",
    };
  },
  methods: {
    async getUsers() {
      this.users = await UserService.getAllUsers();
      this.users = this.users.data;
    },
    async selectOption(option) {
      this.option = option;
    },
  },
  created() {
    this.getUsers();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
