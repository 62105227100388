<template>
  <button
    class="btn btn-dark btn-inscripciones"
    @click="getCourseInscriptionsPDF"
  >
    Descargar PDF Inscripciones
  </button>
  <p v-if="loading" class="text-center">
    Generando PDF... <i class="fa fa-spinner fa-spin"></i>
  </p>
</template>

<style>
.btn.btn-inscripciones {
  display: block;
  margin: 20px auto;
}
</style>

<script>
import InscriptionService from "@/services/inscription.service";

export default {
  name: "DownloadInscriptions",
  props: {
    course: Object,
  },
  data() {
    return {
      inscriptions: [],
      loading: false,
    };
  },
  methods: {
    async getCourseInscriptionsPDF() {
      this.loading = true;
      console.log(this.course.UUID)
      this.inscriptions = await InscriptionService.getCourseInscriptionsPDF(
        this.course.UUID
      );
      this.loading = false;
    },
  },
};
</script>
