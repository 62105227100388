import api from "./api";

class TagService {
  /**************************************
   *  API FBO
   **************************************/
  async addTag(params) {
    return await api
      .post("/tags/create/", params)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async updateTag(id, params) {
    return await api
      .put("/tags/" + id + "/", params)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getAllTags(params) {
    return await api
      .get("/tags/list", { params })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getTag(id) {
    return await api
      .get("/tags/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async deleteTag(id) {
    return await api
      .delete("/tags/delete/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }
}

export default new TagService();
