class TokenService {
  getLocalRefreshToken() {
    const auth = JSON.parse(localStorage.getItem("auth"));
    return auth?.refresh;
  }

  getLocalAccessToken() {
    const auth = JSON.parse(localStorage.getItem("auth"));
    return auth?.access;
  }

  updateLocalAccessToken(token) {
    let auth = JSON.parse(localStorage.getItem("auth"));
    auth.access = token;
    localStorage.setItem("auth", JSON.stringify(auth));
  }

  getAuth() {
    return JSON.parse(localStorage.getItem("auth"));
  }

  async setAuth(auth) {
    await localStorage.setItem("auth", JSON.stringify(auth));
  }

  removeAuth() {
    localStorage.removeItem("auth");
  }
}

export default new TokenService();
