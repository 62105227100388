import api from "./api";

class ArticleService {
  /**************************************
   *  API FBO
   **************************************/
  async addArticle(params) {
    return await api
      .post("/blog/articles/create/", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async updateArticle(id, params) {
    return await api
      .put("/blog/articles/update/" + id + "/", params)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getAllArticles(params) {
    return await api
      .get("/blog/articles/list", { params })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getArticle(id) {
    return await api
      .get("/blog/articles/" + id + "/")
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async deleteArticle(id) {
    return await api
      .delete("/blog/articles/delete/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }
}

export default new ArticleService();
