<template>
	<div class="container-fluid pt-5">
		<div class="row">
			<div class="col-lg-3 col-md-4">
				<NavbarMembership />
			</div>
			<div class="col-lg-9 col-md-8 mt-4 mt-md-0">
				<div class="card">
					<div class="card-body">
						<h6 class="font-weight-bold mb-4 mb-md-3">
							Caja diaria
						</h6>

						<div class="text-end mb-3">
							<DatePicker
								v-model.range="range"
								:masks="masks"
								:columns="columns"
								:popover="{ visibility: 'focus' }"
							>
								<template
									#default="{ togglePopover, inputValue }"
								>
									<button
										class="btn p-2 text-sm bg-white font-semibold rounded-md shadow-sm"
										@click="togglePopover"
										style="
											padding: 10px;
											border: 1px solid #eeeef1;
											border-radius: 4px;
											width: 270px;
										"
									>
										<i
											class="fa-regular fa-calendar-days me-2 text-primary"
										></i>
										<span>{{ inputValue.start }}</span>
										<i
											class="fa-regular fa-arrow-right mx-2"
										></i>
										<span>{{ inputValue.end }}</span>
									</button>
								</template>
							</DatePicker>
						</div>

						<div v-if="!loading" class="table-responsive">
							<table
								id="bankTable"
								class="table bg-white table-bordered"
							>
								<thead>
									<tr>
										<th></th>
										<th
											v-for="day in items.days"
											class="fw-bold text-dark text-end"
										>
											<small>{{ day }}</small>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td><small>Cobros</small></td>
										<td
											v-for="item in items.data"
											class="text-end"
										>
											<small>
												{{
													formatCurrency(
														item.total_amount_charged /
															100
													)
												}}
											</small>
										</td>
									</tr>
									<tr class="fs-10">
										<td><small>Devoluciones</small></td>
										<td
											v-for="item in items.data"
											class="text-end"
										>
											<small>
												<span
													v-if="
														item.total_amount_refunded >
														0
													"
													>-</span
												>{{
													formatCurrency(
														item.total_amount_refunded /
															100
													)
												}}
											</small>
										</td>
									</tr>
									<tr class="fw-bold text-dark">
										<td>Caja</td>
										<td
											v-for="item in items.data"
											class="text-end"
										>
											{{
												formatCurrency(
													item.total_amount / 100
												)
											}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div v-else class="col-lg-12">
							<div class="text-center py-5">
								<div class="spinner-border" role="status">
									<span class="visually-hidden"
										>Loading...</span
									>
								</div>
								<div>Cargando información</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<router-view></router-view>
</template>

<style scoped>
#bankTable.table th,
#bankTable.table td {
	min-width: 120px;
}
</style>

<script>
// import mixin
import userRoles from "@/mixins/user-roles.js";

import NavbarMembership from "@/components/Layout/NavbarMembership.vue";
import MembershipService from "@/services/membership.service.js";

import Swal from "sweetalert2";

import { useScreens } from "vue-screen-utils";

import { DatePicker } from "v-calendar";

export default {
	name: "BankView",
	mixins: [userRoles],
	components: {
		NavbarMembership,
		DatePicker,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	setup() {
		const { mapCurrent } = useScreens({
			xs: "0px",
			sm: "640px",
			md: "768px",
			lg: "1024px",
		});
		const columns = mapCurrent({ lg: 2 }, 1);

		return {
			columns,
		};
	},
	data() {
		return {
			loading: true,
			remittances: null,
			newRemittance: {
				description: null,
				date: null,
			},
			items: null,
			range: {
				start: new Date(new Date().setDate(new Date().getDate() - 7)),
				end: new Date(new Date().setDate(new Date().getDate())),
			},
			masks: {
				input: "YYYY-MM-DD",
			},
		};
	},
	watch: {
		range: {
			handler: function () {
				this.start();
			},
			deep: true,
		},
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getData();
			this.loading = false;
		},

		async getData() {
			var response = await MembershipService.getBankTransactionsByDates({
				startDate: this.formatInputDate(this.range.start),
				endDate: this.formatInputDate(this.range.end),
			});

			this.items = response.data;

			console.log(this.items, "Items");
		},

		formatDate(date) {
			// Create a date object from a date string
			var date = new Date(date);

			// Get year, month, and day part from the date
			var year = date.toLocaleString("default", { year: "numeric" });
			var month = date.toLocaleString("default", { month: "2-digit" });
			var day = date.toLocaleString("default", { day: "2-digit" });

			// Generate yyyy-mm-dd date string
			var formattedDate = year + "-" + month + "-" + day;
			console.log(formattedDate); // Prints: 2022-05-04
			return formattedDate;
		},

		formatCurrency(num) {
			// Create our number formatter.
			const formatter = new Intl.NumberFormat("es-ES", {
				style: "currency",
				currency: "EUR",

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});
			return formatter.format(num);
		},

		formatInputDate(date) {
			var d = new Date(date),
				month = "" + (d.getMonth() + 1),
				day = "" + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;

			return [year, month, day].join("-");
		},
	},
	created() {
		this.start();
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>
