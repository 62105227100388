<template>
  <td class="text-uppercase">
    <div v-for="item in inscription.assistants">{{ item.firstName }}</div>
  </td>
  <td class="text-uppercase">
    <div v-for="item in inscription.disabilityPersons">
      {{ item.firstName }}
    </div>
  </td>
  <td>
    <div>{{ inscription.createdAt }}</div>
  </td>
  <td class="center">
    <!-- <button class="btn btn-sm btn-outline-dark m-1" data-bs-toggle="modal" :data-bs-target="'#modalEditLesson'+id"><i class="fas fa-edit"></i></button> -->
    <button
      class="btn btn-sm btn-outline-danger m-1"
      @click="deleteInscription()"
    >
      <i class="fas fa-trash"></i>
    </button>
  </td>
</template>

<script>
import InscriptionService from "../../../services/inscription.service";

import Swal from "sweetalert2";

export default {
  name: "InscriptionRow",
  components: {},
  props: {
    inscription: Object,
  },
  data() {
    return {};
  },
  methods: {
    async deleteInscription() {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Una vez eliminada la inscripción no podrás recuperar los datos",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "¡Sí, eliminar!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await InscriptionService.deleteInscription(
            this.inscription.UUID
          );
          if (response.status == 200 || response.status == 204) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Se ha borrado correctamente.",
              showConfirmButton: false,
              timer: 1500,
              backdrop: false,
              toast: true,
            });
          }
          this.$router.go();
        }
      });
    },
  },
};
</script>
