<template>
  <!-- Sidebar -->
  <ul class="navbar-nav sidebar sidebar-light accordion" id="accordionSidebar">
    <li class="nav-item">
      <RouterLink class="navbar-item nav-link" to="/">
        <i class="bx bxs-home icon"></i>
        <span>Inicio</span>
      </RouterLink>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider" />

    <!-- Heading -->
    <div v-if="user_role == 'admin'" class="sidebar-heading">APP</div>
    <li v-if="user_role == 'admin'" class="nav-item">
      <RouterLink class="navbar-item nav-link" to="/blog">
        <i class="bx bxs-user-account icon"></i>
        <span class="text nav-text">Blog</span>
      </RouterLink>
    </li>
    <li v-if="user_role == 'admin'" class="nav-item">
      <RouterLink class="navbar-item nav-link" to="/courses">
        <i class="bx bx-calendar icon"></i>
        <span class="text nav-text">Cursos</span>
      </RouterLink>
    </li>
    <li v-if="user_role == 'admin'" class="nav-item">
      <RouterLink class="navbar-item nav-link" to="/forums">
        <i class="bx bxs-group icon"></i>
        <span class="text nav-text">Foros</span>
      </RouterLink>
    </li>
    <!-- Divider -->
    <hr v-if="user_role == 'admin'" class="sidebar-divider" />

    <!-- Heading -->
    <div v-if="user_role == 'admin'" class="sidebar-heading">Usuarios</div>
    <li v-if="user_role == 'admin'" class="nav-item">
      <RouterLink class="navbar-item nav-link" to="/users">
        <i class="bx bxs-user-account icon"></i>
        <span class="text nav-text">Usuarios</span>
      </RouterLink>
    </li>

    <!-- Divider -->
    <hr v-if="user_role == 'admin'" class="sidebar-divider" />

    <!-- Heading -->
    <div class="sidebar-heading">PORTAL SOCIOS</div>
    <li
      v-if="user_role == 'admin' || user_role == 'teamManager'"
      class="nav-item"
    >
      <RouterLink class="navbar-item nav-link" to="/membership/teams">
        <i class="bx bxs-user-account icon"></i>
        <span class="text nav-text">Mis equipos</span>
      </RouterLink>
    </li>
    <li class="nav-item">
      <RouterLink class="navbar-item nav-link" to="/membership/members">
        <i class="bx bxs-user-account icon"></i>
        <span class="text nav-text">Mis socios</span>
      </RouterLink>
    </li>

    <li v-if="user_role == 'admin'" class="nav-item">
      <RouterLink class="navbar-item nav-link" to="/admin/membership/teams">
        <i class="bx bxs-user-account icon"></i>
        <span class="text nav-text">Equipos</span>
      </RouterLink>
    </li>

    <li v-if="user_role == 'admin'" class="nav-item">
      <RouterLink class="navbar-item nav-link" to="/admin/membership/members">
        <i class="bx bxs-user-account icon"></i>
        <span class="text nav-text">Socios</span>
      </RouterLink>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider" />

    <!-- Heading -->
    <div v-if="user_role == 'admin'" class="sidebar-heading">Ajustes</div>
    <li v-if="user_role == 'admin'" class="nav-item">
      <RouterLink class="navbar-item nav-link" to="/settings">
        <i class="bx bx-cog icon"></i>
        <span class="text nav-text">Ajustes</span>
      </RouterLink>
    </li>
    <!-- Divider -->
    <hr v-if="user_role == 'admin'" class="sidebar-divider d-none d-md-block" />

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button
        class="rounded-circle border-0"
        id="sidebarToggle"
        @click="sidebarToggleTop"
      >
        <i id="toggle-left" class="fa-solid fa-chevron-left"></i>
        <i
          id="toggle-right"
          class="fa-solid fa-chevron-right"
          style="display: none"
        ></i>
      </button>
    </div>
  </ul>
  <!-- End of Sidebar -->
</template>

<style></style>

<script>
// import mixin
import userRoles from "@/mixins/user-roles.js";

export default {
  name: "Sidebar",
  mixins: [userRoles],
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    start() {},

    // Toggle the side navigation
    sidebarToggleTop() {
      window.$("body").toggleClass("sidebar-toggled");
      window.$(".sidebar").toggleClass("toggled");
      window.$("#toggle-left").css("display", "inline");
      window.$("#toggle-right").css("display", "none");
      if (window.$(".sidebar").hasClass("toggled")) {
        window.$("#toggle-left").css("display", "none");
        window.$("#toggle-right").css("display", "inline");
        // window.$('.sidebar .collapse').collapse('hide');
      }
    },
  },
  mounted() {
    this.start();
  },
};
</script>
