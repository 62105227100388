<template>
  <button
    class="btn btn-sm btn-dark"
    data-bs-toggle="modal"
    data-bs-target="#modalAddLesson"
  >
    <i class="fas fa-plus me-2"></i>Añadir
  </button>

  <!-- Modal Add Lesson -->
  <div
    class="modal fade"
    id="modalAddLesson"
    tabindex="-1"
    aria-labelledby="modalAddLesson"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-lg-down"
    >
      <div class="modal-content">
        <form @submit="add">
          <div class="modal-header">
            <h5 class="modal-title">Añadir lección</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="form-group mb-3">
              <label class="form-label">Título</label>
              <input
                type="text"
                class="form-control"
                v-model="addLesson.title"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Subtítulo</label>
              <input
                type="text"
                class="form-control"
                v-model="addLesson.subtitle"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Descripción</label>
              <QuillEditor
                theme="snow"
                v-model:content="addLesson.description"
                contentType="html"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Archivo</label>
              <input type="file" class="form-control" @change="uploadFile" />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Enlace de video</label>
              <input
                type="text"
                class="form-control"
                v-model="addLesson.videoURL"
              />
            </div>
            <div class="form-group mb-3">
              <label class="form-label">Prioridad</label>
              <input
                type="number"
                class="form-control"
                v-model="addLesson.priority"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button id="submitAddLesson" type="submit" class="btn btn-primary">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CourseService from "../../services/course.service";
import LessonService from "../../services/lesson.service";
import UserService from "../../services/user.service";

import Swal from "sweetalert2";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "AddLesson",
  components: {
    QuillEditor,
  },
  props: {
    course: Object,
  },
  data() {
    return {
      courseUUID: this.$route.params.courseUUID, 
      course: [],
      lesson: [],
      addLesson: {
        title: "",
        subtitle: "",
        description: "",
        file: "",
        videoURL: "",
        priority: "",
      },
      file: "",
      
    };
  },
  methods: {
    async getCurrentUser() {
      this.user = await UserService.getLocalUser();
    },

    async getCurentCourse(){
      var response = await CourseService.getCourse(this.courseUUID);
      this.course = response.data
    },

    uploadFile(event) {
      this.file = event.target.files[0];
    },
    async add(evt) {
      evt.preventDefault();
      window.$("#submitAddLesson").empty();
      window
        .$("#submitAddLesson")
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );

      const fd = new FormData();
      if (this.file != "") {
        fd.append("file", this.file);
      }
      fd.append("title", this.addLesson.title);
      fd.append("subtitle", this.addLesson.subtitle);
      fd.append("description", this.addLesson.description);
      fd.append("videoURL", this.addLesson.videoURL);
      fd.append("priority", this.addLesson.priority);
      fd.append("enabled", true);
      fd.append("course", this.courseUUID);
      fd.append("author", this.user.UUID);

      evt.preventDefault();

      let response = await LessonService.addLesson(fd);
      console.log(response);

      window.$("#submitAddLesson").empty();
      window.$("#submitAddLesson").append("Guardar");

      if (response.status == 201) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });

        this.$router.go();
      }
    },
  },
  created() {
    this.getCurrentUser();
    this.getCurentCourse();
  },
};
</script>
