<template>
  <div class="card-body p-4">
    <div class="row">
      <div class="col-md-5 col-lg-3">
        <NavbarBlog @selectOption="selectOption" />
      </div>
      <div class="col-md-7 col-lg-9">
        <div v-if="option == 'articles'">
          <BlogArticles />
        </div>
        <div v-else-if="option == 'tags'">
          <BlogTags />
        </div>
        <div v-else-if="option == 'categories'">
          <BlogCategories />
        </div>
        <div v-else-if="option == 'subcategories'">
          <BlogSubCategories />
        </div>
        <div v-else-if="option == 'topics'">
          <BlogTopics />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarBlog from "@/components/Layout/NavbarBlog.vue";
import BlogCategories from "@/components/Blog/views/Categories.vue";
import BlogSubCategories from "@/components/Blog/views/SubCategories.vue";
import BlogTags from "@/components/Blog/views/Tags.vue";
import BlogArticles from "@/components/Blog/views/Articles.vue";
import BlogTopics from "@/components/Blog/views/Topics.vue";

import UserService from "@/services/user.service.js";

export default {
  name: "BlogView",
  components: {
    NavbarBlog: NavbarBlog,
    BlogCategories: BlogCategories,
    BlogSubCategories: BlogSubCategories,
    BlogTags: BlogTags,
    BlogArticles: BlogArticles,
    BlogTopics: BlogTopics,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      users: [],
      option: "articles",
    };
  },
  methods: {
    async start() {
      if (localStorage.getItem("menu-blog") != null) {
        this.option = localStorage.getItem("menu-blog");
      }
    },

    async getUsers() {
      this.users = await UserService.getAllUsers();
      this.users = this.users.data;
    },

    async selectOption(option) {
      this.option = option;
    },
  },
  created() {
    this.start();
    this.getUsers();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
