import api from "./api";

class LessonService {
  /**************************************
   *  API FBO
   **************************************/
  async addLesson(params) {
    return await api
      .post("/courses/lessons/create/", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async updateLesson(id, params) {
    return await api
      .put("/courses/lessons/update/" + id + "/", params)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getAllLessons(params) {
    return await api
      .get("/courses/lessons/list", { params })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async getLesson(id) {
    return await api
      .get("/courses/lessons/single/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }

  async deleteLesson(id) {
    return await api
      .delete("/courses/lessons/delete/" + id)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch(function (error) {
        return { status: error.response.status, data: error.response.data };
      });
  }
}

export default new LessonService();
