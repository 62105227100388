<template>
	<div class="container-fluid pt-5">
		<div class="row">
			<div class="col-lg-3 col-md-4">
				<NavbarMembership />
			</div>
			<div class="col-lg-9 col-md-8">
				<div
					v-if="!loading"
					class="col-md-6 col-lg-12"
					v-for="item in teams"
					:key="item"
				>
					<div class="card mb-3" style="width: 100%">
						<img
							class="card-img-top"
							src="/bg_team.jpg"
							alt="Card image cap"
							style="height: 300px; object-fit: cover"
						/>
						<div class="card-body">
							<h5 class="card-title">{{ item.name }}</h5>
							<div class="text-start">
								<router-link
									:to="'/membership/teams/' + item.UUID"
									class="btn btn-fbo"
									>Ver equipo
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="text-center py-5">
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
					<div>Cargando información</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NavbarMembership from "@/components/Layout/NavbarMembership.vue";

import MembershipService from "@/services/membership.service.js";

export default {
	name: "TeamsView",
	components: {
		NavbarMembership,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	data() {
		return {
			loading: true,
			teams: null,
		};
	},
	methods: {
		async start() {
			this.loading = true;
			await this.getTeams();
			this.loading = false;
		},

		async getTeams() {
			var response = await MembershipService.getTeams();
			this.teams = response.data;
			// console.log(this.teams, "Teams");
		},
	},
	created() {
		this.start();
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>
