<template>
  <div class="card-body p-4">
    <div class="row">
      <div class="col-md-4">
        <NavbarSettings @selectOption="selectOption" />
      </div>
      <div class="col-md-8">
        <div v-if="option == 'comunidades'">
          <SettingsComunidades />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarSettings from "@/components/Layout/NavbarSettings.vue";
import SettingsComunidades from "@/components/Settings/SettingsComunidades.vue";
import UserService from "@/services/user.service.js";

export default {
  name: "Settings",
  components: {
    NavbarSettings,
    SettingsComunidades,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    return {
      users: [],
      option: "comunidades",
    };
  },
  methods: {
    async getUsers() {
      this.users = await UserService.getAllUsers();
      this.users = this.users.data;
      // console.log(this.users)
    },
    async selectOption(option) {
      this.option = option;
    },
  },
  created() {
    this.getUsers();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
