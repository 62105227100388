<template>
  <td>
    <div>{{ comunidad.name }}</div>
  </td>
  <td>
    <button
      class="btn btn-sm btn-edit"
      data-bs-toggle="modal"
      :data-bs-target="'#modalEditComunidad' + id"
    >
      <i class="fas fa-edit"></i>
    </button>
  </td>

  <!-- Modal Edit User -->
  <div
    class="modal fade"
    :id="'modalEditComunidad' + id"
    tabindex="-1"
    aria-labelledby="modalEditComunidad"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-fullscreen-lg-down">
      <div class="modal-content">
        <form @submit="update">
          <div class="modal-header">
            <h5 class="modal-title">Editar usuario</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="form-group mb-3">
              <label class="form-label">Nombre</label>
              <input
                type="text"
                class="form-control"
                v-model="updateComunidad.name"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              :id="'submitBtn' + id"
              type="submit"
              class="btn btn-primary"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../services/comunidad.service";

// import Swal from 'sweetalert2'

export default {
  name: "UserRow",
  props: {
    comunidad: Object,
  },
  data() {
    return {
      id: this.comunidad.id,
      updateComunidad: {
        name: this.comunidad.name,
      },
    };
  },
  methods: {
    async update(evt) {
      evt.preventDefault();
      window.$("#submitBtn" + this.id).empty();
      window
        .$("#submitBtn" + this.id)
        .append(
          '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
        );
      let response = await UserService.updateComunidad(this.comunidad.id, {
        name: this.updateComunidad.name,
      });
      window.$("#submitBtn" + this.id).empty();
      window.$("#submitBtn" + this.id).append("Guardar");
      if (response.status == 200) {
        (this.name = this.updateComunidad.name),
          window.$("#modalEditComunidad" + this.id).modal("hide");
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha guardado correctamente.",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          toast: true,
        });
      }
    },
  },
};
</script>
