<template>
	<div class="container">
		<div class="col-md-12 mt-5">
			<div class="card shadow-sm border-0">
				<div class="card-header bg-white">
					<div
						class="d-flex align-items-center justify-content-between"
					>
						<h5>Cursos</h5>
						<AddCourse />
					</div>
				</div>
				<div class="card-body">
					<div class="table-responsive">
						<table
							class="table table-bordered table-striped table-hover"
						>
							<thead>
								<tr>
									<th>Título</th>
									<th>Modalidad</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								<tr
									class="align-middle"
									:key="item"
									v-for="item in courses"
								>
									<CourseRow :course="item"></CourseRow>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<router-view></router-view>
</template>

<script>
import AddCourse from "@/components/Courses/AddCourse.vue";
import CourseRow from "@/components/Courses/CourseRow.vue";
import CourseService from "@/services/course.service.js";

export default {
	name: "Courses",
	components: {
		AddCourse: AddCourse,
		CourseRow: CourseRow,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	data() {
		return {
			courses: [],
		};
	},
	methods: {
		async getCourses() {
			this.courses = await CourseService.getAllCourses();
			this.courses = this.courses.data;
			// console.log(this.courses)
		},
	},
	created() {
		this.getCourses();
	},
	mounted() {
		if (!this.currentUser) {
			this.$router.push("/login");
		}
	},
};
</script>
